import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17, default as _styled18, default as _styled19, default as _styled20, default as _styled21, default as _styled22, default as _styled23, default as _styled24, default as _styled25, default as _styled26, default as _styled27 } from "styled-components";
import React, { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Linking, View } from "react-native";
import CpLinkButton from "components/common/CpLinkButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { I18nContext } from "contexts/CxI18n";
import useAnalytics from "hooks/useAnalytics";
import useAppRegion from "hooks/useAppRegion";
import useAppTheme from "hooks/useAppTheme";
import { useHideRules } from "hooks/useHideRules";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { AnalyticsEventName } from "services/Analytics";
import { backendSupportedLocaleNames, backendSupportedLocales } from "services/Intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  // z-index ensures that anything that overflows (like popup menus) covers the footer
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    z-index: -1;
  `,
  customFooter: css`
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    z-index: -1;
  `,
  linkText: css`
    color: ${({
    theme
  }) => theme.textColors.footer};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: 400;
    text-align: center;
    text-decoration-color: ${({
    theme
  }) => theme.textColors.footer};
  `,
  linksContainer: css`
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  underline: css`
    text-decoration: underline;
  `
};
/**
 * The page footer that provides various boilerplate links and the ability to change the users locale
 */
const CpAppFooter = ({
  style
}) => {
  const {
    currentRegionTranslation
  } = useAppRegion();
  const {
    locale,
    setI18nContext
  } = useContext(I18nContext);
  const {
    appConfig
  } = useImdSession();
  const {
    additionalFooterStyles,
    textColors
  } = useAppTheme();
  const {
    trackEvent
  } = useAnalytics();
  const {
    setFooterHeight
  } = useScreenLayout();

  // Link Callbacks
  const handleFacebookPress = useCallback(() => {
    const targetUrl = "https://www.facebook.com/imdhealth/";
    Linking.openURL(targetUrl);
    trackEvent({
      data: {
        targetUrl
      },
      eventType: AnalyticsEventName.nav_external
    });
  }, [trackEvent]);
  const handleLinkedInPress = useCallback(() => {
    const targetUrl = "https://www.linkedin.com/company/imd-health-canada/";
    Linking.openURL(targetUrl);
    trackEvent({
      data: {
        targetUrl
      },
      eventType: AnalyticsEventName.nav_external
    });
  }, [trackEvent]);
  const handleTwitterPress = useCallback(() => {
    const targetUrl = "https://twitter.com/IMD_Health_";
    Linking.openURL(targetUrl);
    trackEvent({
      data: {
        targetUrl
      },
      eventType: AnalyticsEventName.nav_external
    });
  }, [trackEvent]);
  const handleYouTubePress = useCallback(() => {
    const targetUrl = "https://www.youtube.com/user/iMDHealthCanada";
    Linking.openURL(targetUrl);
    trackEvent({
      data: {
        targetUrl
      },
      eventType: AnalyticsEventName.nav_external
    });
  }, [trackEvent]);
  const handleLocalePress = useCallback(selectedLocale => {
    setI18nContext({
      locale: selectedLocale
    });
    trackEvent({
      data: {
        languageSelected: selectedLocale
      },
      eventType: AnalyticsEventName.language_change
    });
  }, [setI18nContext, trackEvent]);
  const handleFooterLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setFooterHeight(layout.height);
  }, [setFooterHeight]);
  const shouldHideCustomFooter = useHideRules({
    rules: appConfig?.appFooter?.hideRules
  });
  if (shouldHideCustomFooter) {
    return null;
  } else {
    return <React.Fragment>
        {appConfig?.appFooter?.disclaimer ? <_StyledView testID="CpAppFooterCustomFooter" $_css={[localStyles.customFooter, additionalFooterStyles]}>
            <PrText children={appConfig.appFooter.disclaimer} />
          </_StyledView> : null}
        <_StyledView2 onLayout={handleFooterLayout} style={style} testID="CpAppFooter" $_css2={localStyles.container}>
          {/* Site links */}
          <_StyledView3 $_css3={localStyles.linksContainer}>
            <_StyledCpLinkButton route={Routes.support} $_css4={styles.paddingDouble}>
              <_StyledPrText children={translations.labels.supportLink} $_css5={localStyles.linkText} />
            </_StyledCpLinkButton>
            <_StyledCpLinkButton2 url="https://www.imdhealth.com" $_css6={styles.paddingDouble}>
              <_StyledPrText2 $_css7={localStyles.linkText}>
                <FormattedMessage id="LEuP9n" defaultMessage="Corporate" />
              </_StyledPrText2>
            </_StyledCpLinkButton2>
            <_StyledCpLinkButton3 url="https://www.imdhealth.com/news" $_css8={styles.paddingDouble}>
              <_StyledPrText3 $_css9={localStyles.linkText}>
                <FormattedMessage id="W33Rkk" defaultMessage="Blog" />
              </_StyledPrText3>
            </_StyledCpLinkButton3>
            <_StyledCpLinkButton4 route={Routes.policies} $_css10={styles.paddingDouble}>
              <_StyledPrText4 children={translations.screens.policies} $_css11={localStyles.linkText} />
            </_StyledCpLinkButton4>
            <_StyledCpLinkButton5 route={Routes.terms_of_use} $_css12={styles.paddingDouble}>
              <_StyledPrText5 children={translations.screens.terms_of_use} $_css13={localStyles.linkText} />
            </_StyledCpLinkButton5>
          </_StyledView3>

          {/* Social Media Icon Button Links */}
          <_StyledView4 $_css14={styles.rowAndCenter}>
            <_StyledPrPressable hoveredBackgroundColor="text" onPress={handleFacebookPress} $_css15={styles.paddingDouble}>
              <PrIcon fill={textColors.footer} icon={IconName.facebook} />
            </_StyledPrPressable>
            <_StyledPrPressable2 hoveredBackgroundColor="text" onPress={handleLinkedInPress} $_css16={styles.paddingDouble}>
              <PrIcon fill={textColors.footer} icon={IconName.linkedIn} />
            </_StyledPrPressable2>
            <_StyledPrPressable3 hoveredBackgroundColor="text" onPress={handleTwitterPress} $_css17={styles.paddingDouble}>
              <PrIcon fill={textColors.footer} icon={IconName.twitter} />
            </_StyledPrPressable3>
            <_StyledPrPressable4 hoveredBackgroundColor="text" onPress={handleYouTubePress} $_css18={styles.paddingDouble}>
              <PrIcon fill={textColors.footer} icon={IconName.youTube} />
            </_StyledPrPressable4>
          </_StyledView4>

          {/* Inquiries, CloudMD/Stock info */}
          <_StyledPrText6 $_css19={[localStyles.linkText, styles.paddingDouble, styles.paddingVerticalSingle]}>
            <FormattedMessage id="Xlc9h8" defaultMessage="For all other inquiries, contact us at {email}" values={{
            email: <CpLinkButton url="mailto:info@imdhealth.com">
                    <_StyledPrText7 $_css20={localStyles.linkText}>info@imdhealth.com</_StyledPrText7>
                  </CpLinkButton>
          }} />
          </_StyledPrText6>
          <_StyledPrText8 $_css21={[localStyles.linkText, styles.paddingVerticalDouble]}>
            <FormattedMessage id="JdZZCz" defaultMessage="IMD Health is a {cloudmdLink} company (TSX-V:DOC)" values={{
            cloudmdLink: <CpLinkButton url="https://cloudmd.ca">
                    <_StyledPrText9 $_css22={localStyles.linkText}>CloudMD</_StyledPrText9>
                  </CpLinkButton>
          }} />
          </_StyledPrText8>

          {/* Current Region */}
          <_StyledCpLinkButton6 route={Routes.change_region} $_css23={styles.paddingDouble}>
            <_StyledPrText10 $_css24={localStyles.linkText}>
              <FormattedMessage id="NMO6b9" defaultMessage="Region/Country ({currentRegion})" values={{
              currentRegion: <PrText children={currentRegionTranslation} />
            }} />
            </_StyledPrText10>
          </_StyledCpLinkButton6>

          {/* Change Language */}
          <_StyledView5 $_css25={styles.rowAndCenter}>
            {backendSupportedLocales.map(supportedLocale => <_StyledPrPressable5 hoveredBackgroundColor="text" key={supportedLocale} onPress={() => handleLocalePress(supportedLocale)} $_css26={styles.paddingDouble}>
                <_StyledPrText11 children={backendSupportedLocaleNames[supportedLocale]} $_css27={[localStyles.linkText, locale === supportedLocale && localStyles.underline]} />
              </_StyledPrPressable5>)}
          </_StyledView5>
        </_StyledView2>
      </React.Fragment>;
  }
};
export default CpAppFooter;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledView3 = _styled3(View)`${p => p.$_css3}`;
var _StyledCpLinkButton = _styled4(CpLinkButton)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledCpLinkButton2 = _styled6(CpLinkButton)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledCpLinkButton3 = _styled8(CpLinkButton)`${p => p.$_css8}`;
var _StyledPrText3 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledCpLinkButton4 = _styled10(CpLinkButton)`${p => p.$_css10}`;
var _StyledPrText4 = _styled11(PrText)`${p => p.$_css11}`;
var _StyledCpLinkButton5 = _styled12(CpLinkButton)`${p => p.$_css12}`;
var _StyledPrText5 = _styled13(PrText)`${p => p.$_css13}`;
var _StyledView4 = _styled14(View)`${p => p.$_css14}`;
var _StyledPrPressable = _styled15(PrPressable)`${p => p.$_css15}`;
var _StyledPrPressable2 = _styled16(PrPressable)`${p => p.$_css16}`;
var _StyledPrPressable3 = _styled17(PrPressable)`${p => p.$_css17}`;
var _StyledPrPressable4 = _styled18(PrPressable)`${p => p.$_css18}`;
var _StyledPrText6 = _styled19(PrText)`${p => p.$_css19}`;
var _StyledPrText7 = _styled20(PrText)`${p => p.$_css20}`;
var _StyledPrText8 = _styled21(PrText)`${p => p.$_css21}`;
var _StyledPrText9 = _styled22(PrText)`${p => p.$_css22}`;
var _StyledCpLinkButton6 = _styled23(CpLinkButton)`${p => p.$_css23}`;
var _StyledPrText10 = _styled24(PrText)`${p => p.$_css24}`;
var _StyledView5 = _styled25(View)`${p => p.$_css25}`;
var _StyledPrPressable5 = _styled26(PrPressable)`${p => p.$_css26}`;
var _StyledPrText11 = _styled27(PrText).withConfig({
  displayName: "CpAppFooter___StyledPrText11",
  componentId: "sc-gsyevj-0"
})(["", ""], p => p.$_css27);