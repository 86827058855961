import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17, default as _styled18, default as _styled19, default as _styled20, default as _styled21, default as _styled22, default as _styled23, default as _styled24, default as _styled25, default as _styled26, default as _styled27, default as _styled28, default as _styled29, default as _styled30, default as _styled31, default as _styled32 } from "styled-components";
var _CpFormResourceMutation;
import { Formik, useField } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Pressable, TextInput, View } from "react-native";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import * as yup from "yup";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import CpError from "components/common/CpError";
import PrButton from "components/primitives/PrButton";
import PrIcon from "components/primitives/PrIcon";
import PrSelectSingle from "components/primitives/PrSelectSingle";
import PrText from "components/primitives/PrText";
import { resourceFragment } from "components/topic/CpResourceCommon";
import { I18nContext } from "contexts/CxI18n";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
const localStyles = {
  centeredColumn: css`
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
  `,
  errorTextStyle: css`
    text-align: start;
  `,
  fieldContainer: css`
    border-color: white;
    border-style: solid;
    border-width: thick;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `,
  fields: {
    radioInput: css`
      border-color: ${({
      theme
    }) => theme.brandColors.primary};
      border-radius: ${({
      theme
    }) => theme.borderRadii.sharp}px;
      border-width: 0;
      min-height: 48px;
      outline-width: 0;
      padding: ${({
      theme
    }) => theme.spacing.double}px;
      background-color: ${({
      theme
    }) => theme.formColors.fieldBackground};
    `,
    textInput: css`
      border-color: ${({
      theme
    }) => theme.brandColors.primary};
      border-radius: ${({
      theme
    }) => theme.borderRadii.sharp}px;
      border-width: 0;
      min-height: 48px;
      outline-width: 0;
      padding: ${({
      theme
    }) => theme.spacing.double}px;
      background-color: ${({
      theme
    }) => theme.formColors.fieldBackground};
    `
  },
  htmlField: css`
    background-color: transparent;
    padding-bottom: 0px;
    padding-top: 0px;
  `,
  label: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    ${styles.uppercase};
    font-weight: bold;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  margin5Bottom0: css`
    margin: 5px 5px 0px 5px;
  `,
  optional: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.default};
    text-transform: lowercase;
    font-weight: normal;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    font-style: italic;
  `
};
const formResourceMutation = _CpFormResourceMutation !== void 0 ? _CpFormResourceMutation : _CpFormResourceMutation = require("./__generated__/CpFormResourceMutation.graphql");

/**
 * Renders a FORM resource
 */

const CpFormResource = ({
  resource,
  resourceNodeRevisionId
}) => {
  const data = useFragment(resourceFragment, resource);
  const resourceId = data._id;
  const {
    organization
  } = useImdSession();
  const {
    locale
  } = useContext(I18nContext);
  const {
    isNarrowScreen
  } = useScreenLayout();
  const [didSubmit, setDidSubmit] = useState(false);
  const [commit, isInFlight] = useMutation(formResourceMutation);
  const userFormStructure = data.userFormStructure;
  const userFormLocalization = data.userFormLocalization;
  const fieldsLocalization = userFormLocalization.fields;
  const {
    initialValues
  } = useMemo(() => {
    const fieldsStructure = userFormStructure.fields;
    // const _fieldsLocalization = userFormLocalization.fields
    const _initialValues = {};
    fieldsStructure.forEach(oneFieldStructure => {
      if (oneFieldStructure.status !== "disabled") {
        _initialValues[oneFieldStructure.name] = "";
      }
    });
    return {
      initialValues: _initialValues
    };
  }, [userFormStructure.fields]);
  const Form = useCallback(() => {
    const handleSubmit = values => {
      const _commitVariables = {
        input: {
          locale: locale,
          organizationId: organization?._id,
          resourceId: resourceId,
          resourceNodeRevisionId: resourceNodeRevisionId,
          userData: {
            ...values
          }
        }
      };
      if (!isInFlight) {
        commit({
          onCompleted(_responseData) {
            setDidSubmit(true);
          },
          variables: _commitVariables
        });
      }
    };
    return <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false} validateOnChange={false}>
        {({
        errors,
        values,
        ...rest
      }) => {
        const hasErrors = Object.keys(errors).length > 0;
        const _Errors = [];
        for (const [key, value] of Object.entries(errors)) {
          _Errors.push(<CpError error={`* ${value}`} key={key} />);
        }
        return <>
              {userFormStructure.fields.map((oneFieldStructure, index) => {
            if (oneFieldStructure.status === "hidden") {
              return undefined;
            }
            switch (oneFieldStructure.type) {
              case "checkbox":
                {
                  return <CheckboxField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "date":
                {
                  return <DateField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "email":
              case "tel":
              case "text":
              case "textarea":
              case "url":
                {
                  return <TextField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "html":
                {
                  return <HtmlField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "radio":
                {
                  return <RadioField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} orientation="column" structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "radioHorizontal":
                {
                  return <RadioField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} orientation="row" structure={oneFieldStructure} values={values} {...rest} />;
                }
              case "select":
                {
                  return <SelectField errors={errors} key={oneFieldStructure.name} localization={fieldsLocalization[index]} structure={oneFieldStructure} values={values} {...rest} />;
                }
            }
          })}
              {(() => {
            if (hasErrors) {
              return <_StyledView $_css={localStyles.errorTextStyle}>{_Errors.map(e => e)}</_StyledView>;
            } else if (didSubmit) {
              return <PrButton color="secondary" disabled={true} type="raised">
                      <PrText>{userFormLocalization.submit_success || "Form Submitted"}</PrText>
                    </PrButton>;
            } else if (isInFlight) {
              return <PrText>Please Wait</PrText>;
            } else {
              return <PrButton color="primary" onPress={rest.submitForm} testID="CpFormResource" type="raised">
                      <PrText>{userFormLocalization.submit || "Submit"}</PrText>
                    </PrButton>;
            }
          })()}
            </>;
      }}
      </Formik>;
  }, [commit, didSubmit, fieldsLocalization, initialValues, isInFlight, locale, organization?._id, resourceId, resourceNodeRevisionId, userFormStructure.fields, userFormLocalization]);
  return <>
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      <_StyledView2 $_css2={[isNarrowScreen ? styles.marginHorizontalSingle : styles.limitedLargeScreenWidth, styles.paddingDouble]}>
        {Form()}
      </_StyledView2>
    </>;
};
const CheckboxField = ({
  localization,
  structure,
  validateField
}) => {
  const [field, meta, helpers] = useField({
    name: structure.name,
    type: structure.type,
    validate: value => {
      if (structure.required && !value) {
        return "Confirmation is required!";
      } else {
        return undefined;
      }
    }
  });
  return <_StyledView3 $_css3={localStyles.fieldContainer}>
      <_StyledPressable onPress={() => {
      helpers.setValue(!field.value);
      !meta.touched && helpers.setTouched(true);
      setTimeout(() => {
        // nextTick, otherwise it will validate with the previous value
        validateField(field.name);
      });
    }} $_css4={[localStyles.fields.radioInput, css`
            ${styles.rowAndCenter}
          `]}>
        <PrIcon fill="#000000" height={36} icon={!field.value ? "checkboxUnchecked" : "checkboxChecked"} width={36} />
        <_StyledPrText $_css5={localStyles.label}>&nbsp;&nbsp;</_StyledPrText>
        <_StyledPrText2 $_css6={localStyles.label}>{localization.label}</_StyledPrText2>
      </_StyledPressable>
      {meta.error ? <CpError error={meta.error} textStyle={localStyles.errorTextStyle} /> : null}
    </_StyledView3>;
};
const TextField = ({
  localization,
  structure,
  validateField
}) => {
  const type = structure.type;
  const isRequired = structure.required;
  const [field, meta, helpers] = useField({
    name: structure.name,
    type: type,
    validate: value => {
      if (value === "" && isRequired) {
        return `${localization.label} Error: field is required, please complete`;
      } else if (value !== "") {
        switch (type) {
          case "email":
            {
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                return "Invalid Email";
              } else {
                return undefined;
              }
            }
          case "tel":
            {
              if (!/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/i.test(value)) {
                return "Invalid Telephone number";
              } else {
                return undefined;
              }
            }
          case "url":
            {
              if (!/[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi.test(value)) {
                return "Invalid Url";
              } else {
                return undefined;
              }
            }
        }
      }
      return undefined;
    }
  });
  return <_StyledView4 $_css7={localStyles.fieldContainer}>
      <_StyledPrText3 $_css8={localStyles.label}>
        {localization.label}{" "}
        <_StyledPrText4 $_css9={localStyles.optional}>
          {structure.required ? "  [*required]" : " [optional]"}
        </_StyledPrText4>{" "}
      </_StyledPrText3>
      <_StyledTextInput multiline={type === "textarea"} numberOfLines={4} onBlur={_e => {
      helpers.setTouched(true);
      validateField(field.name);
    }} onChangeText={v => {
      helpers.setValue(v, false);
      meta.touched && validateField(field.name);
    }} placeholder={localization.placeholder} value={field.value} $_css10={localStyles.fields.textInput} />
      {meta.error ? <CpError error={meta.error} textStyle={localStyles.errorTextStyle} /> : null}
    </_StyledView4>;
};
const HtmlField = ({
  localization
}) => {
  const {
    fontFamilies
  } = useAppTheme();
  const styledHtml = `
    <div style="font-family: ${fontFamilies.default};">
      ${localization.html}
    </div>
  `;
  return <_StyledView5 $_css11={[localStyles.fieldContainer, localStyles.htmlField]}>
      <CpDivHtmlWeb content={styledHtml} />
    </_StyledView5>;
};
const SelectField = ({
  localization,
  structure,
  validateField
}) => {
  const [field, meta, helpers] = useField({
    name: structure.name,
    type: "text",
    validate: value => {
      if (structure.required && value === "") {
        return `${localization.label} is required!`;
      } else {
        return undefined;
      }
    }
  });
  const optionsArray = useMemo(() => {
    const splitValues = structure.options.values.split(",").map(val => val.trim());
    return splitValues.map((value, index) => ({
      name: localization.options[index],
      value
    }));
  }, [localization.options, structure.options.values]);
  useEffect(() => {
    if (meta.touched) {
      validateField(field.name);
    }
  }, [field.name, field.value, meta.touched, validateField]);
  const handleChangeOption = useCallback(itemValue => {
    helpers.setValue(itemValue);
    helpers.setTouched(true);
  }, [helpers]);
  return <_StyledView6 $_css12={localStyles.fieldContainer}>
      <_StyledPrText5 $_css13={localStyles.label}>
        {localization.label}{" "}
        <_StyledPrText6 $_css14={localStyles.optional}>
          {structure.required ? "  [*required]" : " [optional]"}
        </_StyledPrText6>{" "}
      </_StyledPrText5>
      <PrSelectSingle onChangeOption={handleChangeOption} placeholder={localization.placeholder} selectOptions={optionsArray} value={field.value} />
      {meta.error ? <CpError error={meta.error} textStyle={localStyles.errorTextStyle} /> : null}
    </_StyledView6>;
};
const DateField = ({
  localization,
  structure,
  validateField
}) => {
  const fieldName = structure.name;
  const [localDate, setLocalDate] = useState({
    day: {
      isTouched: false,
      isValid: false,
      value: ""
    },
    month: {
      isTouched: false,
      isValid: false,
      value: ""
    },
    year: {
      isTouched: false,
      isValid: false,
      value: ""
    }
  });
  const portionValidator = {
    day: yup.number().min(1).max(31),
    month: yup.number().min(1).max(12),
    year: yup.number().min(1).max(3000)
  };
  const yupDate = yup.date();
  const [, meta, helpers] = useField({
    name: structure.name,
    type: "text",
    validate: async value => {
      if (structure.required && value === "") {
        return `${localization.label} is required!`;
      }
      if (!structure.required && value.replace(/-/g, "") === "") {
        return undefined;
      }
      if (localDate.day.isValid && localDate.year.isValid && localDate.month.isValid) {
        const _tempDate = new Date(Number(localDate.year.value.padStart(4, "0")), Number(localDate.month.value) - 1, Number(localDate.day.value));
        return yupDate.isValid(_tempDate).then(v => {
          if (v && `${_tempDate.getDate()}` === localDate.day.value) {
            return undefined;
          } else {
            return "Invalid Date";
          }
        }).catch(_e => {
          return "Invalid Date";
        });
      } else {
        return "Invalid Date";
      }
    }
  });
  const handleSubFieldChange = (datePortion, value) => {
    if (value === "") {
      const _nextDateState = {
        ...localDate,
        [datePortion]: {
          ...localDate[datePortion],
          isValid: false,
          value: value
        }
      };
      setLocalDate(_nextDateState);
      helpers.setValue(`${_nextDateState.year.value}-${_nextDateState.month.value}-${_nextDateState.day.value}`, false);
      setTimeout(() => {
        meta.touched && validateField(fieldName);
      });
    } else {
      portionValidator[datePortion].isValid(value).then(res => {
        if (res) {
          const _nextDateState = {
            ...localDate,
            [datePortion]: {
              ...localDate[datePortion],
              isValid: true,
              value: value
            }
          };
          setLocalDate(_nextDateState);
          helpers.setValue(`${_nextDateState.year.value}-${_nextDateState.month.value}-${_nextDateState.day.value}`, false);
          setTimeout(() => {
            // nextTick, otherwise validate will be called with previous value
            meta.touched && validateField(fieldName);
          });
        }
      }).catch(e => e);
    }
  };

  /**
   * Touch Fields One By One
   * @param datePortion
   */
  const handleSubFieldBlur = datePortion => {
    const _newLocalDate = {
      ...localDate,
      [datePortion]: {
        ...localDate[datePortion],
        isTouched: true
      }
    };
    setLocalDate(_newLocalDate);
    if (_newLocalDate.year.isTouched && _newLocalDate.month.isTouched && _newLocalDate.day.isTouched) {
      helpers.setTouched(true, false);
      validateField(fieldName);
    }
  };
  return <_StyledView7 $_css15={localStyles.fieldContainer}>
      <_StyledPrText7 $_css16={localStyles.label}>
        {localization.label}{" "}
        <_StyledPrText8 $_css17={localStyles.optional}>
          {structure.required ? "  [*required]" : " [optional]"}
        </_StyledPrText8>{" "}
      </_StyledPrText7>
      <_StyledView8 $_css18={styles.rowAndCenter}>
        <_StyledView9 $_css19={localStyles.centeredColumn}>
          <_StyledTextInput2 multiline={false} onBlur={() => {
          handleSubFieldBlur("year");
        }} onChangeText={v => {
          handleSubFieldChange("year", v);
        }} value={localDate.year.value} $_css20={[localStyles.fields.textInput, css`
                max-width: 100px;
                text-align: center;
              `]} />
          <_StyledPrText9 $_css21={localStyles.margin5Bottom0}>YYYY</_StyledPrText9>
        </_StyledView9>
        <_StyledView10 $_css22={localStyles.centeredColumn}>
          <_StyledTextInput3 multiline={false} onBlur={() => {
          handleSubFieldBlur("month");
        }} onChangeText={v => {
          handleSubFieldChange("month", v);
        }} value={localDate.month.value} $_css23={[localStyles.fields.textInput, css`
                max-width: 50px;
                text-align: center;
              `]} />
          <_StyledPrText10 $_css24={localStyles.margin5Bottom0}>MM</_StyledPrText10>
        </_StyledView10>
        <_StyledView11 $_css25={localStyles.centeredColumn}>
          <_StyledTextInput4 multiline={false} onBlur={() => {
          handleSubFieldBlur("day");
        }} onChangeText={v => {
          handleSubFieldChange("day", v);
        }} value={localDate.day.value} $_css26={[localStyles.fields.textInput, css`
                max-width: 50px;
                text-align: center;
              `]} />
          <_StyledPrText11 $_css27={localStyles.margin5Bottom0}>DD</_StyledPrText11>
        </_StyledView11>
      </_StyledView8>
      {meta.error ? <CpError error={meta.error} textStyle={localStyles.errorTextStyle} /> : null}
    </_StyledView7>;
};
const RadioField = ({
  localization,
  orientation,
  structure,
  validateField
}) => {
  const [field, meta, helpers] = useField({
    name: structure.name,
    type: "text",
    // don't use radio!!! it uses a different setValue
    validate: value => {
      if (structure.required && value === "") {
        return `${localization.label} is required!`;
      } else {
        return undefined;
      }
    }
  });
  const valuesArray = useMemo(() => {
    return structure.options.values.split(",").map(val => val.trim());
  }, [structure.options]);
  if (valuesArray.includes(field.value) && !meta.touched) {
    helpers.setTouched(true);
  }
  return <_StyledView12 $_css28={localStyles.fieldContainer}>
      <_StyledPrText12 $_css29={localStyles.label}>
        {localization.label}{" "}
        <_StyledPrText13 $_css30={localStyles.optional}>
          {structure.required ? "  [*required]" : " [optional]"}
        </_StyledPrText13>{" "}
      </_StyledPrText12>
      <RadioGroup labels={localization.options} layout={orientation} onValueChange={itemValue => {
      helpers.setValue(itemValue);
      !meta.touched && helpers.setTouched(true);
      setTimeout(() => {
        // nextTick, otherwise it will validate with the previous value
        validateField(field.name);
      });
    }} options={valuesArray} selectedValue={field.value} />
      {meta.error ? <CpError error={meta.error} textStyle={localStyles.errorTextStyle} /> : null}
    </_StyledView12>;
};
const RadioGroup = ({
  labels,
  layout,
  onValueChange,
  options,
  selectedValue
}) => {
  return <_StyledView13 $_css31={[css`
          flex-direction: ${layout};
          justify-content: space-around;
          flex-wrap: wrap;
        `, localStyles.fields.radioInput]}>
      {options.map((entry, index) => <RadioButton checked={selectedValue === options[index]} handlePress={onValueChange} key={entry} label={labels[index]} value={entry} />)}
    </_StyledView13>;
};
const RadioButton = ({
  checked,
  handlePress,
  label,
  value
}) => {
  return <_StyledPressable2 onPress={() => {
    handlePress(value);
  }} $_css32={[localStyles.fields.radioInput, css`
          ${styles.rowAndCenter}
        `]}>
      <PrIcon fill="#000000" height={36} icon={checked ? "radioChecked" : "radioUnchecked"} width={36} />
      <PrText>{label}</PrText>
    </_StyledPressable2>;
};
export default CpFormResource;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledView3 = _styled3(View)`${p => p.$_css3}`;
var _StyledPressable = _styled4(Pressable)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrText2 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledView4 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrText3 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledPrText4 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledTextInput = _styled10(TextInput)`${p => p.$_css10}`;
var _StyledView5 = _styled11(View)`${p => p.$_css11}`;
var _StyledView6 = _styled12(View)`${p => p.$_css12}`;
var _StyledPrText5 = _styled13(PrText)`${p => p.$_css13}`;
var _StyledPrText6 = _styled14(PrText)`${p => p.$_css14}`;
var _StyledView7 = _styled15(View)`${p => p.$_css15}`;
var _StyledPrText7 = _styled16(PrText)`${p => p.$_css16}`;
var _StyledPrText8 = _styled17(PrText)`${p => p.$_css17}`;
var _StyledView8 = _styled18(View)`
          ${p => p.$_css18}
        `;
var _StyledView9 = _styled19(View)`${p => p.$_css19}`;
var _StyledTextInput2 = _styled20(TextInput)`${p => p.$_css20}`;
var _StyledPrText9 = _styled21(PrText)`${p => p.$_css21}`;
var _StyledView10 = _styled22(View)`${p => p.$_css22}`;
var _StyledTextInput3 = _styled23(TextInput)`${p => p.$_css23}`;
var _StyledPrText10 = _styled24(PrText)`${p => p.$_css24}`;
var _StyledView11 = _styled25(View)`${p => p.$_css25}`;
var _StyledTextInput4 = _styled26(TextInput)`${p => p.$_css26}`;
var _StyledPrText11 = _styled27(PrText)`${p => p.$_css27}`;
var _StyledView12 = _styled28(View)`${p => p.$_css28}`;
var _StyledPrText12 = _styled29(PrText)`${p => p.$_css29}`;
var _StyledPrText13 = _styled30(PrText)`${p => p.$_css30}`;
var _StyledView13 = _styled31(View)`${p => p.$_css31}`;
var _StyledPressable2 = _styled32(Pressable).withConfig({
  displayName: "CpFormResource___StyledPressable2",
  componentId: "sc-vd7vcc-0"
})(["", ""], p => p.$_css32);