import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpGrid from "components/common/CpGrid";
import CpLinkButton from "components/common/CpLinkButton";
import CpGridItem from "components/home/tabs/CpGridItem";
import { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
const localStyles = {
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  emailButton: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    justify-content: center;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  info: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  linkText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  postscript: css`
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.triple}px;
  `
};

// Not all resources have the proper icon assigned to them for rendering - manually assigning here
// TODO: When React Admin is a thing, update all the icons to ensure we have parity
const iconMap = {
  // How to update your account settings on IMD
  "014594e3-bba7-45a3-934b-c6701c05bfde": IconName.cogs,
  // How to create a shortcut on IMD
  "024a1d93-dcee-41e0-8b12-194748d574b9": IconName.starOutline,
  // How to reset your password on IMD -- this is changed in admin, but may not map correctly
  "05d37143-9116-4aa5-87d9-50080d5d5f99": IconName.eyeOpen,
  // How to educate patients using IMD
  "133d107f-ec2f-498b-85f3-e7eb6a67d184": IconName.iconPatientSession,
  // How to find a medical topic on IMD
  "5c236b46-8d7d-4268-86ff-ff1419fbb19e": IconName.iconTopic,
  // How to change your language on IMD
  "5dea8d21-4c5b-4d8c-9f53-d8068355a09e": IconName.language,
  // How to use the drawing tool on IMD
  "8531e97f-87b8-4390-a71a-f7699807096a": IconName.brush,
  // How to add IMD to your desktop
  "ae4235dc-8008-4b52-a2e5-9fd06c4dc567": IconName.iconLaptop,
  // How to add IMD as a shortcut on your iPad
  "def8b015-ac3b-4554-826b-9644d4b1d0f0": IconName.iconTablet,
  // These do not have an icon available outside of the React apps
  // How to add IMD as a bookmark
  "9fe14042-97d9-45f5-95b5-4abdedfed5d3": IconName.bookmark,
  // How to sign in to your IMD account
  "d1f4d0c2-f20b-426e-a902-d03a0e1a424a": IconName.signIn,
  // How to create a patient information package on IMD
  "ea8f9a94-a9ca-407a-9474-816c22d15ba9": IconName.iconPackageClosed
};
const CpHelp = ({
  nodes,
  style
}) => {
  const navigation = useNavigation();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const handlePress = useCallback(revisionId => {
    navigation.navigate(Routes.topic, {
      // RevisionId for the How-To resource tree
      rootRevisionId: "bbf0365d-5649-4c78-b404-601017462fcf",
      selectedRevisionId: revisionId
    });
  }, [navigation]);
  return <View style={style}>
      <_StyledCpGrid $_css={localStyles.container}>
        <_StyledPrText $_css2={localStyles.info}>
          <FormattedMessage id="xRWbIf" defaultMessage="Questions about the IMD Health Platform? We've provided you with some handy How-To documents to ensure you get the most out of your experience." />
        </_StyledPrText>
        <_StyledView $_css3={isNarrowScreen ? styles.fullWidth : styles.rowAndWrap}>
          {nodes?.map(node => {
          if (!node) return;
          const {
            icon,
            revisionId
          } = node;
          const label = getNameVariant(node);
          const localIcon = iconMap[revisionId] ? iconMap[revisionId] : icon;
          return <CpGridItem
          // Since longer labels may be truncated we'll ensure the accessibility label has the full label available to it
          accessibilityLabel={label} color="primary" icon={localIcon} key={revisionId} link={{
            link_type: "topic",
            value: ""
          }} onPress={() => handlePress(revisionId)} testID={`CpHelp-${revisionId}`} title={label} />;
        })}
        </_StyledView>

        <_StyledPrText2 $_css4={localStyles.postscript}>
          <FormattedMessage id="fvYfJy" defaultMessage="Couldn't find the answers you're looking for? Are you experiencing an issue you need assistance with? Contact IMD Health for further support." />
        </_StyledPrText2>
        <_StyledCpLinkButton url="mailto:support@imdhealth.com" $_css5={localStyles.emailButton}>
          <_StyledPrText3 $_css6={localStyles.linkText}>support@imdhealth.com</_StyledPrText3>
        </_StyledCpLinkButton>
      </_StyledCpGrid>
    </View>;
};
export default CpHelp;
var _StyledCpGrid = _styled(CpGrid)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledCpLinkButton = _styled5(CpLinkButton)`${p => p.$_css5}`;
var _StyledPrText3 = _styled6(PrText).withConfig({
  displayName: "CpHelp___StyledPrText3",
  componentId: "sc-1whar92-0"
})(["", ""], p => p.$_css6);