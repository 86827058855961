import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5 } from "styled-components";
import React from "react";
import { View } from "react-native";
import PrProgressTracker from "components/primitives/PrProgressTracker";
import PrText from "components/primitives/PrText";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  progressContainer: css`
    align-self: center;
    max-width: 450px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100%;
  `,
  progressText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  progressTextContainer: css`
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `
};
/**
 * Renders a progress bar for a Patient/Package session with "review", "send" and optional "start" labels. Expects totalSteps to be 2 (or 3 if 'showStart' is enabled).
 */
const CpSessionProgress = ({
  accessibilityLabel,
  accessibilityValue,
  completedSteps,
  showStart = false,
  totalSteps
}) => {
  return <_StyledView $_css={localStyles.progressContainer}>
      <PrProgressTracker accessibilityLabel={accessibilityLabel} accessibilityValue={accessibilityValue} completedSteps={completedSteps} totalSteps={totalSteps} />
      <_StyledView2 $_css2={localStyles.progressTextContainer}>
        {showStart && <_StyledPrText children={translations.labels.start} $_css3={[styles.boldText, localStyles.progressText]} />}
        <_StyledPrText2 children={translations.labels.review} $_css4={localStyles.progressText} />
        <_StyledPrText3 children={translations.labels.send} $_css5={localStyles.progressText} />
      </_StyledView2>
    </_StyledView>;
};
export default CpSessionProgress;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText).withConfig({
  displayName: "CpSessionProgress___StyledPrText3",
  componentId: "sc-uev5rv-0"
})(["", ""], p => p.$_css5);