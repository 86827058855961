import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9 } from "styled-components";
import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpProfileDetailsForm from "components/account/CpProfileDetailsForm";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpFabButton from "components/common/CpFabButton";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  background: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  editButton: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  icon: css`
    padding-right: ${({
    theme
  }) => theme.spacing.quarter}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  profileText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  profileTextContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  profileWizardContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * The screen for viewing a users profile details with the option to edit.
 */
const ScProfileDetails = ({
  route
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    buttonColors
  } = useAppTheme();
  const titleRef = useRef(null);
  const handleScrollToTop = useCallback(() => {
    titleRef.current?.scrollTo();
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const handleEdit = useCallback(() => {
    setIsEditing(true);
    handleScrollToTop();
  }, [handleScrollToTop]);
  const handleCancelOrSubmit = useCallback(() => {
    setIsEditing(false);
    handleScrollToTop();
  }, [handleScrollToTop]);
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} ref={titleRef} $_css2={localStyles.background}>
        <CpBackgroundHeader header={translations.screens[route.name]}>
          <_StyledView $_css3={[localStyles.profileWizardContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
            <_StyledView2 $_css4={localStyles.profileTextContainer}>
              <_StyledPrText $_css5={localStyles.profileText}>
                <FormattedMessage id="h9SSP0" defaultMessage="Manage your Personal and Practitioner profile information here." />
              </_StyledPrText>
              {!isEditing && !isNarrowScreen ? <_StyledPrButton onPress={handleEdit} testID="ScProfileDetailsEditButton" $_css6={[styles.marginHorizontalDouble, localStyles.editButton]}>
                  <_StyledPrIcon fill={buttonColors.text} icon={IconName.editPencil} size={iconSizes.xsmall} $_css7={localStyles.icon} />
                  <_StyledPrText2 children={formatMessage(translations.buttons.edit)} $_css8={localStyles.buttonText} />
                </_StyledPrButton> : null}
            </_StyledView2>
            <CpProfileDetailsForm isEditing={isEditing} onSubmit={handleCancelOrSubmit} />
          </_StyledView>
        </CpBackgroundHeader>
      </_StyledScrollView>
      {isNarrowScreen && !isEditing ? <_StyledCpFabButton icon={IconName.editPencil} label={translations.buttons.edit} onPress={handleEdit} testID="ScProfileDetailsEditButton" $_css9={styles.fab} /> : null}
    </_StyledSafeAreaView>;
};
export default ScProfileDetails;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledScrollView = _styled2(ScrollView)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrButton = _styled6(PrButton)`${p => p.$_css6}`;
var _StyledPrIcon = _styled7(PrIcon)`${p => p.$_css7}`;
var _StyledPrText2 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledCpFabButton = _styled9(CpFabButton).withConfig({
  displayName: "ScProfileDetails___StyledCpFabButton",
  componentId: "sc-zsy6gb-0"
})(["", ""], p => p.$_css9);