import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13 } from "styled-components";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpLinkButton from "components/common/CpLinkButton";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import Config from "services/Config";
import CrashReporting from "services/CrashReporting";
import { css, rnStyles, styles } from "services/Theme";
import { getBuildNumber, getCodepushBundle, getVersion } from "services/Version";
import translations from "translations";
const localStyles = {
  appDetailText: css`
    color: ${({
    theme
  }) => theme.textColors.footer};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  container: css`
    ${styles.paddingDouble};
    align-self: center;
  `,
  emailButton: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    justify-content: center;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  linkText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  pageHeading: css`
    ${styles.pageHeading};
    padding: 0px;
  `
};
const version = getVersion();
const buildNumber = getBuildNumber();
const codepushBundle = getCodepushBundle();
const buildIdentifier = codepushBundle ? `${buildNumber}-${codepushBundle}` : buildNumber;
/**
 * The support page when unauthenticated.
 */
const ScSupport = ({
  navigation,
  route
}) => {
  const {
    contentMinHeight,
    isNarrowScreen
  } = useScreenLayout();
  const navigateToHome = useCallback(() => navigation.navigate(Routes.home), [navigation]);
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader header={<FormattedMessage id="T24kkO" defaultMessage="Support" />}>
          <_StyledView $_css2={[localStyles.container, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined, css`
                min-height: ${contentMinHeight}px;
              `]}>
            <_StyledView2 $_css3={styles.marginVerticalDouble}>
              <PrText>
                <FormattedMessage id="ttI71h" defaultMessage="Questions about the app? Feedback? Technical issues?" />
              </PrText>
              <PrText>
                <FormattedMessage id="ewpRpZ" defaultMessage="Contact the Support team for assistance." />
              </PrText>
            </_StyledView2>

            <_StyledCpLinkButton url="mailto:support@imdhealth.com" $_css4={localStyles.emailButton}>
              <_StyledPrText $_css5={localStyles.linkText}>support@imdhealth.com</_StyledPrText>
            </_StyledCpLinkButton>

            <_StyledView3>
              <_StyledPrText2 $_css6={[localStyles.appDetailText, styles.boldText]}>
                <FormattedMessage id="TuUX++" defaultMessage="IMD Health {appName} App" values={{
                appName: <FormattedMessage {...translations.enums.appNames[Config.APP_NAME]} />
              }} />
              </_StyledPrText2>
              <_StyledPrText3 $_css7={localStyles.appDetailText}>
                <FormattedMessage id="z0C8mC" defaultMessage="Version: {version}" values={{
                version
              }} />
              </_StyledPrText3>
              <_StyledPrText4 $_css8={localStyles.appDetailText}>
                <FormattedMessage id="jTu5QZ" defaultMessage="Build: {buildIdentifier}" values={{
                buildIdentifier
              }} />
              </_StyledPrText4>
            </_StyledView3>

            {!Config.PROD && <_StyledView4 $_css9={styles.errorBox}>
                <PrText>This isn't a production build, so let's do some Sentry testing...</PrText>
                <_StyledPrButton color="destructive" onPress={CrashReporting.triggerJavascriptCrash} $_css10={styles.marginVerticalDouble}>
                  Crash JS
                </_StyledPrButton>
              </_StyledView4>}

            <_StyledPrButton2 onPress={navigateToHome} type="outline" $_css11={styles.marginVerticalDouble}>
              <_StyledPrText5 children={translations.buttons.home} $_css12={localStyles.linkText} />
            </_StyledPrButton2>
          </_StyledView>
          <CpAppFooter />
        </CpBackgroundHeader>
      </ScrollView>
    </_StyledSafeAreaView>;
};
export default ScSupport;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledCpLinkButton = _styled4(CpLinkButton)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledView3 = _styled6(View)`
                border: 1px solid ${({
  theme
}) => theme.brandColors.separator};
                border-radius: ${({
  theme
}) => theme.borderRadii.sharp}px;
                background: ${({
  theme
}) => theme.brandColors.background};
                padding: ${({
  theme
}) => theme.spacing.single}px;
              `;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css6}`;
var _StyledPrText3 = _styled8(PrText)`${p => p.$_css7}`;
var _StyledPrText4 = _styled9(PrText)`${p => p.$_css8}`;
var _StyledView4 = _styled10(View)`${p => p.$_css9}`;
var _StyledPrButton = _styled11(PrButton)`${p => p.$_css10}`;
var _StyledPrButton2 = _styled12(PrButton)`${p => p.$_css11}`;
var _StyledPrText5 = _styled13(PrText).withConfig({
  displayName: "ScSupport___StyledPrText5",
  componentId: "sc-aj4pz4-0"
})(["", ""], p => p.$_css12);