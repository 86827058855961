import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7 } from "styled-components";
import React from "react";
import { View } from "react-native";
import CpError from "components/common/CpError";
import CpLinkButton from "components/common/CpLinkButton";
import FmDisclaimer from "components/form/FmDisclaimer";
import PrText from "components/primitives/PrText";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  disclaimer: css`
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  termsAndPrivacyContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Common component used to render the disclaimer and form error during patient and package session
 */
const CpSessionDisclaimerAndError = ({
  formError
}) => {
  return <>
      <_StyledFmDisclaimer name="disclaimer" testID="CpSessionDisclaimerAndError-disclaimer" $_css={localStyles.disclaimer} />

      <_StyledCpError error={formError} $_css2={styles.paddingVerticalSingle} />

      {/* Terms of Use and Privacy Policy Buttons */}
      <_StyledView $_css3={localStyles.termsAndPrivacyContainer}>
        {/* TODO: These buttons should probably open in a new tab */}
        <_StyledCpLinkButton route={Routes.terms_of_use} $_css4={[styles.marginHorizontalDouble, styles.marginBottomDouble]}>
          <_StyledPrText children={translations.labels.termsOfUse} $_css5={styles.termsLinkText} />
        </_StyledCpLinkButton>
        <_StyledCpLinkButton2 route={Routes.policies} $_css6={[styles.marginHorizontalDouble, styles.marginBottomDouble]}>
          <_StyledPrText2 children={translations.labels.privacyPolicy} $_css7={styles.termsLinkText} />
        </_StyledCpLinkButton2>
      </_StyledView>
    </>;
};
export default CpSessionDisclaimerAndError;
var _StyledFmDisclaimer = _styled(FmDisclaimer)`${p => p.$_css}`;
var _StyledCpError = _styled2(CpError)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledCpLinkButton = _styled4(CpLinkButton)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledCpLinkButton2 = _styled6(CpLinkButton)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText).withConfig({
  displayName: "CpSessionDisclaimerAndError___StyledPrText2",
  componentId: "sc-1rgep5n-0"
})(["", ""], p => p.$_css7);