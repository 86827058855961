import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17, default as _styled18, default as _styled19, default as _styled20, default as _styled21, default as _styled22, default as _styled23, default as _styled24 } from "styled-components";
var _CtVerifyEmailRerequestMutation, _CtVerifyEmailMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpModal from "components/common/CpModal";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useNavigationRedirect from "hooks/useNavigationRedirect";
import usePersistentStorage from "hooks/usePersistentStorage";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { isGraphqlError } from "services/Error";
import Log from "services/Log";
import SecureStorage from "services/SecureStorage";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const rerequestVerificationMutation = _CtVerifyEmailRerequestMutation !== void 0 ? _CtVerifyEmailRerequestMutation : _CtVerifyEmailRerequestMutation = require("./__generated__/CtVerifyEmailRerequestMutation.graphql");
const verifyEmailMutation = _CtVerifyEmailMutation !== void 0 ? _CtVerifyEmailMutation : _CtVerifyEmailMutation = require("./__generated__/CtVerifyEmailMutation.graphql");
const localStyles = {
  button: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  heading: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  rightMargin: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  text: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  unconfirmedEmail: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `
};
/**
 * Container that manages the layout and confirms an users account based on the provided email confirmation token param
 */
const CtVerifyEmail = ({
  confirmEmailToken
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_signinEmail, setSigninEmail] = usePersistentStorage("lastSigninEmail");
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    user
  } = useImdSession();

  // Navigation
  const navigation = useNavigation();
  const navigateToHome = useCallback(() => navigation.navigate(Routes.home), [navigation]);

  // VerifyEmail state and mutation
  const [hasStarted, setHasStarted] = useState(false); // Track effect state to prevent multiple renders
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [submitToken, isSubmittingToken] = useMutation(verifyEmailMutation);
  const {
    setPendingUrl
  } = useNavigationRedirect();

  // RerequestVerification state, callbacks and mutation
  const [canRequestNewToken, setCanRequestNewToken] = useState(false);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState();
  const [submitRerequestError, setSubmitRerequestError] = useState();
  const [submitRerequest, isSubmittingRerequest] = useMutation(rerequestVerificationMutation);
  const onContinueRerequest = useCallback(() => {
    setUnconfirmedEmail(undefined);
  }, []);

  // Set the new access token so the user can gain access the app
  // if we don't wait for this to resolve the app will continue loading and throw an error before the user can be validated
  const setToken = useCallback(async newToken => SecureStorage.setAccessToken({
    ...newToken,
    source: "login"
  }), []);

  // Verify Email submit
  const handleSubmitVerification = useCallback(() => {
    Log.info("CtVerifyEmail - Submitting token to confirm new user email... ");
    setHasStarted(true);
    submitToken({
      onCompleted: ({
        verifyEmail
      }) => {
        if (!verifyEmail) {
          // Guard - this should actually hit onError() before getting here
          Log.error("CtVerifyEmail - Verifying email confirmation token failed, no access token returned");
          setSubmitError(translations.errors.default);
        } else {
          const userLoggedIn = Boolean(user);
          const email = verifyEmail.user.email || "";
          const status = verifyEmail.user.status;
          const accessToken = {
            access_token: verifyEmail.accessToken,
            created_at: verifyEmail.createdAt,
            expires_in: verifyEmail.expiresIn,
            token_type: verifyEmail.tokenType,
            user_id: verifyEmail.userId
          };
          Log.info(`CtVerifyEmail - Successfully confirmed account for: "${email} now has status ${status}"`);
          setIsSubmitted(true);
          // This should populate their email for when they sign in next time
          setSigninEmail(email);
          if (!userLoggedIn) {
            // Once successfully set, user should be logged in and redirected to home
            setToken(accessToken);
            setPendingUrl(Routes.home);
          } else {
            navigateToHome();
          }
        }
      },
      onError: error => {
        setIsSubmitted(true);
        if (isGraphqlError(error)) {
          // Graphql will log its own error if it gets to this point
          const result = error.result?.[0];
          const isInvalid = result.extensions?.code === "invalid";
          setCanRequestNewToken(isInvalid);
          setSubmitError(isInvalid ? translations.errors.invalidConfirmationCode : error);
        } else {
          Log.error("CtVerifyEmail - Error occurred", error);
          setSubmitError(error);
        }
      },
      variables: {
        input: {
          confirmEmailToken
        }
      }
    });
  }, [confirmEmailToken, submitToken, user, setSigninEmail, setToken, setPendingUrl, navigateToHome]);

  // Request new confirmation token submit
  const handleRerequestToken = useCallback(() => {
    Log.info("CtVerifyEmail - Requesting new verification email...");
    submitRerequest({
      onCompleted: ({
        rerequestVerificationEmail
      }) => {
        const email = rerequestVerificationEmail?.unconfirmedEmail;
        if (!email) {
          // Guard - this shouldn't occur
          Log.error("CtVerifyEmail - Rerequesting a confirmation token did not return an unconfirmed email.");
          setSubmitRerequestError(translations.errors.default);
        } else {
          Log.info(`CtCreateAccount - Successfully rerequested confirmation token for "${email}"`);
          setUnconfirmedEmail(email);
        }
      },
      onError: setSubmitError,
      variables: {
        input: {
          expiredEmailToken: confirmEmailToken
        }
      }
    });
  }, [confirmEmailToken, submitRerequest]);

  // Submit the token for verification
  useEffect(() => {
    if (confirmEmailToken && !hasStarted) {
      handleSubmitVerification();
    }
  }, [confirmEmailToken, handleSubmitVerification, hasStarted]);
  return <ScrollView contentContainerStyle={rnStyles.flexSingle}>
      <CpBackgroundHeader childStyle={styles.flexSingle} header={<FormattedMessage id="PUVa8Z" defaultMessage="Verify Email" />}>
        <_StyledView $_css={[styles.paddingDouble, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
          {isSubmittingToken && <View>
              <_StyledPrText $_css2={localStyles.heading}>
                <FormattedMessage id="SO0xGv" defaultMessage="Submitting Token" />
              </_StyledPrText>
              <_StyledPrText2 $_css3={localStyles.text}>
                <FormattedMessage id="izbL/y" defaultMessage="Please wait while we verify your email." />
              </_StyledPrText2>
              <_StyledPrText3 $_css4={localStyles.text}>
                <FormattedMessage id="tQ6hwV" defaultMessage="Once successfully verified, you should automatically be redirected to IMD Health where you can begin browsing." />
              </_StyledPrText3>
              <_StyledPrActivityIndicator size={iconSizes.large} $_css5={styles.marginTopDouble} />
            </View>}

          {isSubmitted && submitError && <View>
              <_StyledPrText4 $_css6={localStyles.heading}>
                <FormattedMessage id="7aQ0mZ" defaultMessage="Sorry" />
              </_StyledPrText4>
              <_StyledPrText5 $_css7={localStyles.text}>
                <FormattedMessage id="3eKpff" defaultMessage="We were unable to verify your email confirmation token." />
              </_StyledPrText5>
              <_StyledCpError error={submitError} $_css8={styles.paddingTopDouble} />
              {/* New token can only be requested if the current token has expired */}
              {canRequestNewToken && <React.Fragment>
                  <_StyledPrButton busy={isSubmittingRerequest} onPress={handleRerequestToken} type="raised" $_css9={localStyles.button}>
                    <_StyledPrText6 $_css10={localStyles.buttonText}>
                      <FormattedMessage id="KFa359" defaultMessage="Request New Token" />
                    </_StyledPrText6>
                  </_StyledPrButton>
                  {submitRerequestError && <_StyledCpError2 error={submitRerequestError} $_css11={styles.paddingVerticalSingle} />}
                </React.Fragment>}
            </View>}

          {isSubmitted && !submitError && <View>
              <_StyledPrText7 children={translations.labels.success} $_css12={localStyles.heading} />
              <_StyledPrText8 $_css13={localStyles.text}>
                <FormattedMessage id="+qAaOo" defaultMessage="Your email verification is complete! If you do not get redirected to the IMD Health home page automatically, you can do so now by clicking the button below." />
              </_StyledPrText8>
              <_StyledPrButton2 onPress={navigateToHome} type="raised" $_css14={localStyles.button}>
                <_StyledPrText9 children={translations.buttons.continue} $_css15={localStyles.buttonText} />
              </_StyledPrButton2>
            </View>}
        </_StyledView>
      </CpBackgroundHeader>

      {/* Successful Rerequest Modal */}
      {unconfirmedEmail && <_StyledCpModal onBackgroundPress={() => null} visible={!!unconfirmedEmail} $_css16={styles.marginHorizontalSingle}>
          <_StyledView2 $_css17={styles.modalContainer}>
            <_StyledPrText10 $_css18={styles.modalHeaderText}>
              <FormattedMessage id="EqC12F" defaultMessage="Verification Email Sent" />
            </_StyledPrText10>
            <_StyledView3 $_css19={[styles.rowAndCenter, styles.paddingVerticalSingle]}>
              <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} $_css20={[css`
                    min-width: ${iconSizes.large}px;
                  `, localStyles.rightMargin]} />
              <PrText>
                <FormattedMessage id="nzOSFm" defaultMessage="We have sent an email to {unconfirmedEmail} with a new link to verify your account." values={{
              unconfirmedEmail: <_StyledPrText11 children={unconfirmedEmail} $_css21={localStyles.unconfirmedEmail} />
            }} />
              </PrText>
            </_StyledView3>
            <_StyledPrText12 $_css22={styles.paddingVerticalSingle}>
              <FormattedMessage id="uv5PQ9" defaultMessage="Your new link will only be valid for 48 hours, so please check your email and verify your account before it expires." />
            </_StyledPrText12>
            <_StyledPrButton3 onPress={onContinueRerequest} type="outline" $_css23={styles.marginTopDouble}>
              <_StyledPrText13 children={translations.buttons.continue} $_css24={localStyles.buttonText} />
            </_StyledPrButton3>
          </_StyledView2>
        </_StyledCpModal>}
    </ScrollView>;
};
export default CtVerifyEmail;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledPrText2 = _styled3(PrText)`${p => p.$_css3}`;
var _StyledPrText3 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrActivityIndicator = _styled5(PrActivityIndicator)`${p => p.$_css5}`;
var _StyledPrText4 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledPrText5 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledCpError = _styled8(CpError)`${p => p.$_css8}`;
var _StyledPrButton = _styled9(PrButton)`${p => p.$_css9}`;
var _StyledPrText6 = _styled10(PrText)`${p => p.$_css10}`;
var _StyledCpError2 = _styled11(CpError)`${p => p.$_css11}`;
var _StyledPrText7 = _styled12(PrText)`${p => p.$_css12}`;
var _StyledPrText8 = _styled13(PrText)`${p => p.$_css13}`;
var _StyledPrButton2 = _styled14(PrButton)`${p => p.$_css14}`;
var _StyledPrText9 = _styled15(PrText)`${p => p.$_css15}`;
var _StyledCpModal = _styled16(CpModal)`${p => p.$_css16}`;
var _StyledView2 = _styled17(View)`${p => p.$_css17}`;
var _StyledPrText10 = _styled18(PrText)`${p => p.$_css18}`;
var _StyledView3 = _styled19(View)`${p => p.$_css19}`;
var _StyledPrIcon = _styled20(PrIcon)`${p => p.$_css20}`;
var _StyledPrText11 = _styled21(PrText)`${p => p.$_css21}`;
var _StyledPrText12 = _styled22(PrText)`${p => p.$_css22}`;
var _StyledPrButton3 = _styled23(PrButton)`${p => p.$_css23}`;
var _StyledPrText13 = _styled24(PrText).withConfig({
  displayName: "CtVerifyEmail___StyledPrText13",
  componentId: "sc-1abm3gw-0"
})(["", ""], p => p.$_css24);