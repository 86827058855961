import _styled, { default as _styled2 } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Pressable, View } from "react-native";
// eslint-disable-next-line no-restricted-imports

import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles } from "services/Theme";
const localStyles = {
  background: css`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: #88888888;
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-width: 1px;
  `
};
/**
 * Extends Modal to place children at a specific location on the screen, anchored to another component.
 * The layout is used to express where the popup should appear on the screen.
 */
const CpPopup = ({
  children,
  layout,
  onBackgroundPress,
  popupStyle,
  style,
  ...props
}) => {
  const {
    width
  } = useScreenLayout();
  const [originalWidth, setOriginalWidth] = useState(0);

  // resets original width to be 0 on layout changes, so it recalculates it on the next useEffect
  useEffect(() => {
    setOriginalWidth(0);
  }, [layout]);
  // This takes the original window width on first render, in case we resize the window it uses this value
  // to calculate the difference between the first render window width and the current width and add it to the popup x axis.
  useEffect(() => {
    if (originalWidth === 0) {
      setOriginalWidth(width);
    }
  }, [originalWidth, width]);
  const calculatedOffsetWidth = useMemo(() => {
    return width - originalWidth;
  }, [originalWidth, width]);
  if (!layout) return null;
  return <Modal style={style} transparent={true} {...props}>
      <_StyledPressable onPress={onBackgroundPress} testID="CpPopupBackground" $_css={localStyles.background}>
        <_StyledView style={rnStyles.menuShadow} $_css2={[css`
              ${localStyles.container};
              left: ${layout.x + calculatedOffsetWidth}px;
              top: ${layout.y}px;
              width: ${layout.width}px;
            `, layout?.height && css`
                height: ${layout.height}px;
              `, layout?.maxHeight && css`
                max-height: ${layout.maxHeight};
              `, popupStyle]}>
          {children}
        </_StyledView>
      </_StyledPressable>
    </Modal>;
};
export default CpPopup;
var _StyledPressable = _styled(Pressable)`${p => p.$_css}`;
var _StyledView = _styled2(View).withConfig({
  displayName: "CpPopup___StyledView",
  componentId: "sc-1z08ao3-0"
})(["", ""], p => p.$_css2);