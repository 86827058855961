import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12 } from "styled-components";
var _CpProfileDetailsFormPractitionerMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpLicenseInformation from "components/account/CpLicenseInformation";
import CpPersonalProfileForm from "components/account/CpPersonalProfileForm";
import CpPractitionerProfileForm from "components/account/CpPractitionerProfileForm";
import CpError from "components/common/CpError";
import CpForm from "components/form/CpForm";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAppRegion from "hooks/useAppRegion";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { useMapOptionsTranslations } from "services/Intl";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const updateProfileMutation = _CpProfileDetailsFormPractitionerMutation !== void 0 ? _CpProfileDetailsFormPractitionerMutation : _CpProfileDetailsFormPractitionerMutation = require("./__generated__/CpProfileDetailsFormPractitionerMutation.graphql");
const localStyles = {
  backButtonContainer: css`
    flex-direction: row;
    justify-content: flex-end;
  `,
  button: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  form: css`
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  headingText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * The profile details page form
 */
const CpProfileDetailsForm = ({
  isEditing,
  onSubmit
}) => {
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    currentRegion
  } = useAppRegion();
  const navigation = useNavigation();
  const prefixes = useMapOptionsTranslations("prefixes", {
    valueAsTranslation: true
  });

  // Checks if the prefix is other, if it is, sets it to other so can have the text input
  const defaultPrefixValue = useMemo(() => {
    if (!user?.personalProfile?.prefix) {
      return undefined;
    }
    return prefixes.some(prefix => prefix.value === user?.personalProfile?.prefix) ? user?.personalProfile?.prefix || undefined : formatMessage(translations.prefixes.other);
  }, [formatMessage, prefixes, user?.personalProfile?.prefix]);
  const [submitProfileUpdate, isSubmittingProfileUpdate] = useMutation(updateProfileMutation);
  const [formError, setFormError] = useState();
  const formDefaultValues = useMemo(() => ({
    externalId: user?.practitionerProfile?.externalId || undefined,
    firstName: user?.personalProfile?.firstName || undefined,
    lastName: user?.personalProfile?.lastName || undefined,
    licenseSubdivisionCode: user?.practitionerProfile?.licenseSubdivisionCode || undefined,
    otherPrefix: defaultPrefixValue === formatMessage(translations.prefixes.other) ? user?.personalProfile?.prefix || undefined : undefined,
    preferredLocale: user?.personalProfile?.preferredLocale || "en",
    prefix: defaultPrefixValue,
    signupOrganizationName: user?.practitionerProfile?.signupOrganizationName || undefined,
    specialties: user?.practitionerProfile?.specialties || [],
    type: user?.practitionerProfile?.type?.replace("PractitionerProfile::", "") || undefined
  }), [defaultPrefixValue, formatMessage, user?.personalProfile?.firstName, user?.personalProfile?.lastName, user?.personalProfile?.preferredLocale, user?.personalProfile?.prefix, user?.practitionerProfile?.externalId, user?.practitionerProfile?.licenseSubdivisionCode, user?.practitionerProfile?.signupOrganizationName, user?.practitionerProfile?.specialties, user?.practitionerProfile?.type]);
  const form = useForm({
    defaultValues: formDefaultValues,
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      externalId: zod.string().min(5),
      firstName: zod.string().min(1),
      lastName: zod.string().min(1),
      licenseSubdivisionCode: zod.string().min(1),
      otherPrefix: zod.string().optional(),
      preferredLocale: zod.string().min(1),
      prefix: zod.string(),
      signupOrganizationName: zod.string().min(1),
      specialties: zod.array(zod.string()),
      type: zod.string().min(1)
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(async values => {
    try {
      const {
        otherPrefix,
        ...input
      } = values;
      input.prefix = values.prefix === formatMessage(translations.prefixes.other) ? otherPrefix : values.prefix;
      const otherValue = values.type.match(/Other::(.*)$/);
      const otherType = otherValue && otherValue[1] ? otherValue[1] || "Other" : null;
      submitProfileUpdate({
        onCompleted: ({
          profileUpdate
        }) => {
          Log.info(`CpProfileDetailsForm - profile updated: ${profileUpdate?.personalProfile.firstName}`);
          onSubmit();
          setFormError(null);
        },
        onError: err => {
          Log.error(`CpProfileDetailsForm - ${err}`);
          setFormError(err);
        },
        variables: {
          personalInput: {
            firstName: input.firstName,
            lastName: input.lastName,
            preferredLocale: input.preferredLocale,
            prefix: input.prefix
          },
          practitionerInput: {
            externalId: input.externalId,
            licenseCountryCode: user?.practitionerProfile?.licenseCountryCode || currentRegion.toUpperCase(),
            licenseSubdivisionCode: input.licenseSubdivisionCode,
            otherType: otherType,
            signupOrganizationName: input.signupOrganizationName,
            specialties: input.specialties,
            type: `PractitionerProfile::${values.type}`
          }
        }
      });
      setFormError(null);
      onSubmit();
    } catch (err) {
      Log.error(`CpProfileDetailsForm - ${err}`);
      if (err instanceof Error) {
        setFormError(err);
      }
    }
  }), [form, formatMessage, onSubmit, currentRegion, submitProfileUpdate, user?.practitionerProfile?.licenseCountryCode]);
  const handleCancel = useCallback(() => {
    form.reset(formDefaultValues);
    onSubmit();
  }, [form, formDefaultValues, onSubmit]);
  const handleBack = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(Routes.manage_account);
  }, [navigation]);
  return <>
      <_StyledCpForm {...form} onSubmit={handleSubmit} $_css={localStyles.form}>
        <View>
          <_StyledPrText $_css2={[styles.textHeader1, localStyles.headingText]}>
            <FormattedMessage id="w0ODrS" defaultMessage="Personal Profile" />
          </_StyledPrText>
        </View>
        <CpPersonalProfileForm readonly={!isEditing} />
        <View>
          <_StyledPrText2 $_css3={[styles.textHeader1, localStyles.headingText]}>
            <FormattedMessage id="7J9XHU" defaultMessage="Practitioner Profile" />
          </_StyledPrText2>
        </View>
        <CpLicenseInformation />
        <CpPractitionerProfileForm readonly={!isEditing} />
      </_StyledCpForm>

      <_StyledCpError error={formError} $_css4={[styles.formField, styles.errorBox]} />
      {isEditing ? <_StyledView $_css5={[!isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
          <_StyledPrButton busy={isSubmittingProfileUpdate} onPress={handleSubmit} testID="CpProfileDetailsFormSubmit" $_css6={localStyles.button}>
            <_StyledPrText3 children={formatMessage(translations.buttons.saveChanges)} $_css7={localStyles.buttonText} />
          </_StyledPrButton>
          <_StyledPrButton2 onPress={handleCancel} type="text" $_css8={localStyles.button}>
            <_StyledPrText4 children={translations.buttons.cancel} $_css9={localStyles.buttonText} />
          </_StyledPrButton2>
        </_StyledView> : <_StyledView2 $_css10={!isNarrowScreen ? localStyles.backButtonContainer : undefined}>
          <_StyledPrButton3 onPress={handleBack} type="text" $_css11={localStyles.button}>
            <_StyledPrText5 children={translations.buttons.cancel} $_css12={localStyles.buttonText} />
          </_StyledPrButton3>
        </_StyledView2>}
    </>;
};
export default CpProfileDetailsForm;
var _StyledCpForm = _styled(CpForm)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledPrText2 = _styled3(PrText)`${p => p.$_css3}`;
var _StyledCpError = _styled4(CpError)`${p => p.$_css4}`;
var _StyledView = _styled5(View)`${p => p.$_css5}`;
var _StyledPrButton = _styled6(PrButton)`${p => p.$_css6}`;
var _StyledPrText3 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrButton2 = _styled8(PrButton)`${p => p.$_css8}`;
var _StyledPrText4 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledView2 = _styled10(View)`${p => p.$_css10}`;
var _StyledPrButton3 = _styled11(PrButton)`${p => p.$_css11}`;
var _StyledPrText5 = _styled12(PrText).withConfig({
  displayName: "CpProfileDetailsForm___StyledPrText5",
  componentId: "sc-pu0op2-0"
})(["", ""], p => p.$_css12);