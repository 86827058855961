import _styled, { default as _styled2 } from "styled-components";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ScrollView } from "react-native";
import { Subject } from "rxjs";
import CpFabButton from "components/common/CpFabButton";
import { IconName } from "components/primitives/PrIcon";
import CxAvailableHeight from "contexts/CxAvailableHeight";
import useAppTheme from "hooks/useAppTheme";
import { styles } from "services/Theme";
import translations from "translations";
// Local context for nesting prevention
const CpScrollContext = createContext(undefined);

/**
 * Provides a scroll view with a "scroll to top" fab. Also has nested scroller detection which
 * will omit the scroller.
 */
const CpScroll = ({
  children,
  endThresholdPx = 50,
  onEndReached,
  preventNestedScroll,
  ...scrollViewProps
}) => {
  const parentScrollContext = useContext(CpScrollContext);
  const {
    scrollHeight,
    watchEndReached: parentWatchEndReached
  } = parentScrollContext ?? {};
  const shouldPreventScroll = preventNestedScroll && scrollHeight !== undefined;
  const {
    buttonHeights
  } = useAppTheme();
  const [contentViewportHeight, setContentViewportHeight] = useState(150);
  const handleContentLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setContentViewportHeight(layout.height - buttonHeights.large);
  }, [buttonHeights.large]);
  const [showTopFab, setShowTopFab] = useState(false);
  const [endWatcherChannel] = useState(() => {
    if (parentWatchEndReached) {
      // If this scroller is embedded in another scroller then it will use the parent's watch channel, and will not be able to trigger end events
      return {
        subject: undefined,
        subscribe: parentWatchEndReached
      };
    } else {
      // If this is a top level scroller then it will be able to issue events, and is
      const watchEndSubject = new Subject();
      return {
        subject: watchEndSubject,
        subscribe: onEvent => {
          const subscription = watchEndSubject.subscribe(onEvent);
          return subscription;
        }
      };
    }
  });
  const watchEndReached = useCallback(callback => {
    // TODO: Create channel
    const endWatcher = endWatcherChannel?.subscribe(callback);
    return {
      unsubscribe: endWatcher?.unsubscribe
    };
  }, [endWatcherChannel]);
  const handleContentScroll = useCallback(event => {
    const {
      nativeEvent: {
        contentOffset,
        contentSize
      }
    } = event;
    // NB: This number should big enough to not be seen immediately after scrolling, but small enough to be displayed at a reasonable scroll length
    // Currently set to display after ~4 scrollwheel steps (so menu is not visible)
    if (contentOffset.y > 500 && !showTopFab) {
      setShowTopFab(true);
    } else if (contentOffset.y <= 500 && showTopFab) {
      setShowTopFab(false);
    }

    // If the scroller is past the end threshold, notify the watchers
    if (contentOffset.y + endThresholdPx > contentSize.height) {
      endWatcherChannel?.subject?.next(event.nativeEvent);
    }
  }, [showTopFab, endThresholdPx, endWatcherChannel?.subject]);
  const scrollRef = useRef(null);
  const handleScrollToTop = useCallback(() => {
    scrollRef.current?.scrollTo({
      animated: true,
      x: 0,
      y: 0
    });
  }, []);

  // Scroll to top whenever content changes, unless a parent has already handled it
  useEffect(() => {
    if (!shouldPreventScroll) {
      handleScrollToTop();
    }
  }, [handleScrollToTop, shouldPreventScroll]);
  const scrollContext = useMemo(() => ({
    scrollHeight: contentViewportHeight,
    watchEndReached: parentWatchEndReached ?? watchEndReached
  }), [contentViewportHeight, parentWatchEndReached, watchEndReached]);

  // If the scroller has an onEnd handler, it should subscribe to the end channel
  useEffect(() => {
    if (onEndReached) {
      return endWatcherChannel.subscribe(onEndReached).unsubscribe;
    }
    return undefined;
  }, [onEndReached, endWatcherChannel]);
  if (shouldPreventScroll) {
    return <>{children}</>;
  } else {
    return <>
        <CpScrollContext.Provider value={scrollContext}>
          <CxAvailableHeight height={contentViewportHeight}>
            <_StyledScrollView {...scrollViewProps} onLayout={handleContentLayout} onScroll={handleContentScroll} ref={scrollRef}
          // Preventing performance issues from onScroll event firing by throttling the event (in milliseconds)
          // NB: This number was arbitrarily chosen
          scrollEventThrottle={750} $_css={styles.scrollWithFab}>
              {children}
            </_StyledScrollView>
          </CxAvailableHeight>
        </CpScrollContext.Provider>

        {showTopFab && <_StyledCpFabButton icon={IconName.top} label={translations.buttons.top} onPress={handleScrollToTop} $_css2={styles.fab} />}
      </>;
  }
};
export default CpScroll;
var _StyledScrollView = _styled(ScrollView)`${p => p.$_css}`;
var _StyledCpFabButton = _styled2(CpFabButton).withConfig({
  displayName: "CpScroll___StyledCpFabButton",
  componentId: "sc-1v5i0nw-0"
})(["", ""], p => p.$_css2);