import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8 } from "styled-components";
import React, { useCallback, useState } from "react";
import { SafeAreaView, View } from "react-native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpModal from "components/common/CpModal";
import CtCreateAccount from "components/containers/CtCreateAccount";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppRegion from "hooks/useAppRegion";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useNavigationRedirect from "hooks/useNavigationRedirect";
import Routes from "navigators/Routes";
import { FormattedMessage } from "react-intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  rightMargin: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  unconfirmedEmail: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `
};
/**
 * Screen for new users to create their own account from the provider app
 */
const ScCreateAccount = ({
  route
}) => {
  const {
    params
  } = route;
  const {
    currentRegionTranslation
  } = useAppRegion();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    signin
  } = useImdSession();
  const {
    setPendingUrl
  } = useNavigationRedirect();

  // Confirmation Modal State and Callbacks
  const [unconfirmedEmail, setUnconfirmedEmail] = useState(null);
  const [password, setPassword] = useState("");
  const handleShowConfirmationModal = useCallback((email, accountPassword) => {
    setUnconfirmedEmail(email);
    setPassword(accountPassword);
  }, []);
  const handleHideConfirmationModal = useCallback(() => setUnconfirmedEmail(null), []);
  const navigateToSignupWizard = useCallback(async () => {
    handleHideConfirmationModal();
    if (unconfirmedEmail && password) {
      await signin(unconfirmedEmail, password);
      setPendingUrl(Routes.profile_wizard);
    }
  }, [handleHideConfirmationModal, password, setPendingUrl, signin, unconfirmedEmail]);
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtCreateAccount onCreate={handleShowConfirmationModal} referralCode={params?.referralCode} />

      {/* Successful Submission Modal */}
      {unconfirmedEmail && <_StyledCpModal onBackgroundPress={navigateToSignupWizard} visible={!!unconfirmedEmail} $_css2={styles.marginHorizontalSingle}>
          <_StyledView $_css3={styles.modalContainer}>
            <_StyledPrText $_css4={styles.modalHeaderText}>
              <FormattedMessage id="1YymdW" defaultMessage="Welcome" />
            </_StyledPrText>
            <PrText>
              <FormattedMessage id="TohnzV" defaultMessage="Thank you for creating an account with IMD Health {region}" values={{
            region: <PrText children={currentRegionTranslation} />
          }} />
            </PrText>
            <_StyledView2 $_css5={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
              <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} $_css6={[css`
                    min-width: ${iconSizes.large}px;
                  `, localStyles.rightMargin]} />
              <PrText>
                <FormattedMessage id="CBke8c" defaultMessage="We have sent an email to {unconfirmedEmail} with a link to verify your account." values={{
              unconfirmedEmail: <_StyledPrText2 children={unconfirmedEmail} $_css7={localStyles.unconfirmedEmail} />
            }} />
              </PrText>
            </_StyledView2>
            <PrButton onPress={navigateToSignupWizard} testID="ScCreateAccountButton-continue" type="outline">
              <_StyledPrText3 children={translations.buttons.continue} $_css8={localStyles.buttonText} />
            </PrButton>
          </_StyledView>
        </_StyledCpModal>}
    </_StyledSafeAreaView>;
};
export default ScCreateAccount;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledCpModal = _styled2(CpModal)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText = _styled4(PrText)`${p => p.$_css4}`;
var _StyledView2 = _styled5(View)`${p => p.$_css5}`;
var _StyledPrIcon = _styled6(PrIcon)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrText3 = _styled8(PrText).withConfig({
  displayName: "ScCreateAccount___StyledPrText3",
  componentId: "sc-1jh4tf5-0"
})(["", ""], p => p.$_css8);