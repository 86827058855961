import _styled, { default as _styled2, default as _styled3, default as _styled4 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import renderDom from "dom-serializer";
import { DomUtils, parseDocument } from "htmlparser2";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import WebView from "react-native-webview";
import { useFragment } from "react-relay/hooks";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { resourceFragment } from "components/topic/CpResourceCommon";
import useAppTheme from "hooks/useAppTheme";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const localStyles = {
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  pdf: css`
    height: 100%;
  `
};
const localTranslations = defineMessages({
  monograph: {
    id: "p5d/ZT",
    defaultMessage: "Monograph"
  },
  reportError: {
    id: "rpJZaf",
    defaultMessage: "Report an error or reaction"
  },
  seeDrugLeaflet: {
    id: "e1r+L8",
    defaultMessage: "See Drug Info Leaflet"
  }
});

/**
 * Checks whether the page we are displaying is for one or more drugs (or drug-related content).
 * It may seem weird that we check this using the publisher name, but in V5 we do this the same way.
 */
function isDrugPage(data) {
  return data.publisher.name === "Vigilance";
}

/**
 * Renders an HTML resource
 */
const CpHtmlResource = ({
  resource,
  style
}) => {
  const {
    fontFamilies
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const data = useFragment(resourceFragment, resource);

  // Uses the extra HTML resource if we have one
  const rawHtml = (data.extraResource && data.extraResource?.type === "Resource::Html" ? data.extraResource?.embedMarkup : data?.embedMarkup) ?? "";
  const {
    setParams
  } = useNavigation();
  const handlePressBackFromLeaflet = useCallback(() => {
    setParams({
      viewResourceId: undefined
    });
  }, [setParams]);
  const renderLeafletBackButton = useMemo(() => <_StyledPrPressable onPress={handlePressBackFromLeaflet} $_css={styles.marginBottomSingle}>
        <_StyledView $_css2={[styles.row, styles.center]}>
          <PrIcon icon={IconName.keyboardArrowLeft} size={iconSizes.medium} />
          <_StyledPrText children={formatMessage(translations.buttons.backTo, {
        label: data.names.brand
      })} $_css3={localStyles.buttonText} />
        </_StyledView>
      </_StyledPrPressable>, [data.names.brand, formatMessage, handlePressBackFromLeaflet]);
  const renderedHtml = useMemo(() => {
    // Replace "[media type="{html or pdf}" resource_id={id}]" with a link
    function mediaReplacer(_match, _tagType, resourceType, resourceId) {
      let message;
      let accessibilityHint;
      if (resourceType === "html") {
        message = localTranslations.seeDrugLeaflet;
        accessibilityHint = translations.accessibility.hint.openDrugInformationLeaflet;
      } else {
        message = localTranslations.monograph;
        accessibilityHint = translations.accessibility.hint.openMonograph;
      }
      return `<div data-accessibility-hint="${formatMessage(accessibilityHint)}" data-id="extra-resource" data-resource="${resourceId}">${formatMessage(message)}</div>`;
    }
    const parsedDom = parseDocument(rawHtml.replaceAll(/\[\[(.*?)type='(html|pdf)' resource_id='(.*?)']]/g, mediaReplacer));

    // Append a "Report Error" button at the end of drug pages
    if (isDrugPage(data) && parsedDom.lastChild) {
      const reportErrorButtonTemplate = `<div data-id="external-link" data-url="https://www.mederror.ca/">${formatMessage(localTranslations.reportError)}</div>`;
      DomUtils.append(parsedDom.lastChild, parseDocument(reportErrorButtonTemplate));
    }
    return renderDom(parsedDom);
  }, [data, formatMessage, rawHtml]);
  if (!rawHtml) return null;

  // Uses the extra PDF resource if we have one
  if (data.extraResource?.url && data.extraResource.type === "Resource::Pdf") {
    return <_StyledView2 $_css4={[styles.paddingHorizontalSingle, localStyles.pdf]}>
        {renderLeafletBackButton}
        <WebView originWhitelist={["*"]} source={{
        uri: data.extraResource.url
      }} />
      </_StyledView2>;
  }
  const styledHtml = `
    <div style="font-family: ${fontFamilies.default};">
      ${renderedHtml}
    </div>
  `;
  return <View style={style} testID="CpHtmlResource">
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      {data?.extraResource ? renderLeafletBackButton : null}
      <CpDivHtmlWeb content={styledHtml} />
    </View>;
};
export default CpHtmlResource;
var _StyledPrPressable = _styled(PrPressable)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View).withConfig({
  displayName: "CpHtmlResource___StyledView2",
  componentId: "sc-1cvrzel-0"
})(["", ""], p => p.$_css4);