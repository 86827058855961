import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import { getLocales } from "react-native-localize";
import CpHeaderButton from "components/common/CpHeaderButton";
import CpItem from "components/common/CpItem";
import CpPopup from "components/common/CpPopup";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import PrButton from "components/primitives/PrButton";
import { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import { I18nContext } from "contexts/CxI18n";
import useAppRegion from "hooks/useAppRegion";
import Routes from "navigators/Routes";
import { backendSupportedLocaleNames, backendSupportedLocales } from "services/Intl";
import Log from "services/Log";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Renders the language picker button and menu for use in a NavHeader.
 * Optionally allows for changing the app region.
 */
const CpLanguagePicker = ({
  allowChangeRegion,
  type = ActionButtonUserType.NavHeader
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    locale,
    setI18nContext
  } = useContext(I18nContext);
  const localeDisplay = locale?.split("-")[0] ?? "";
  const {
    navigate
  } = useNavigation();
  const handleChangeRegionNavigation = useCallback(() => {
    navigate(Routes.change_region);
  }, [navigate]);

  // Language menu state and callbacks
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const handleHideLanguagePopup = useCallback(() => setShowLanguagePopup(false), [setShowLanguagePopup]);
  const handleShowLanguagePopup = useCallback(() => setShowLanguagePopup(true), [setShowLanguagePopup]);
  const handleSetLocale = useCallback(selectedLocale => {
    // Find the first user preferred locale for the selected locale
    const userPreferredLocales = getLocales();
    const userLocale = userPreferredLocales.find(candidateLocale => candidateLocale.languageCode === selectedLocale);
    Log.info("CpLanguagePicker - Locale selected based on device settings.", {
      preferredLocale: userPreferredLocales,
      userLocale: userLocale?.languageTag
    });
    const newLocale = userLocale?.languageCode || selectedLocale;
    setI18nContext({
      locale: newLocale
    });
    handleHideLanguagePopup();
    // TODO: For some reason `trackEvent` doesn't like being called here, even though it was a direct copy from its original location
    // trackEvent({
    //   data: { languageSelected: newLocale },
    //   eventType: AnalyticsEventName.language_change,
    // })
  }, [handleHideLanguagePopup, setI18nContext]);

  // Current region data and callbacks
  const {
    currentRegionTranslation
  } = useAppRegion();
  const handleChangeRegionPress = useCallback(() => {
    handleHideLanguagePopup();
    handleChangeRegionNavigation();
  }, [handleHideLanguagePopup, handleChangeRegionNavigation]);

  // Language menu layout setup and callbacks
  const [languagePopupLayout, setLanguagePopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleLanguageLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setLanguagePopupLayout({
      width: 250,
      x: layout.left + layout.width - 250,
      y: layout.top + layout.height
    });
  }, []);
  return <React.Fragment>
      <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderLanguageButton)} icon={IconName.language} label={formatMessage(translations.accessibility.label.navHeaderLanguageButton)} onLayout={handleLanguageLayout} onPress={handleShowLanguagePopup} shortLabel={localeDisplay.toUpperCase()} testID="CpLanguagePicker" type={type} />

      <CpPopup layout={languagePopupLayout} onBackgroundPress={handleHideLanguagePopup} popupStyle={styles.popupBorder} visible={showLanguagePopup}>
        <_StyledPrText children={translations.labels.selectLanguage} $_css={styles.smallCapsMenuHeader} />
        {backendSupportedLocales.map(supportedLocale => <_StyledCpItem key={supportedLocale} onPressCallback={handleSetLocale} onPressValue={supportedLocale} testID={`CpLanguagePicker-language-${supportedLocale}`} $_css2={[locale === supportedLocale && styles.selected]}>
            <_StyledView $_css3={styles.navHeaderStyles.languageMenuItem}>
              <_StyledPrText2 children={backendSupportedLocaleNames[supportedLocale]} $_css4={styles.navHeaderStyles.languageSupportedLabel} />
              <_StyledPrText3 children={supportedLocale} $_css5={styles.navHeaderStyles.languageCode} />
            </_StyledView>
          </_StyledCpItem>)}

        {allowChangeRegion ? <_StyledView2 $_css6={styles.paddingDouble}>
            <_StyledPrText5 children={<FormattedMessage {...translations.labels.youAreViewing} values={{
          region: <_StyledPrText4 children={currentRegionTranslation} $_css7={styles.italicText} />
        }} />} $_css8={[styles.navHeaderStyles.changeRegionLabel, styles.italicText]} />
            <PrButton onPress={handleChangeRegionPress} testID="CpLanguagePicker-changeRegion" type="outline">
              <_StyledPrText6 children={translations.buttons.changeCountryOrRegion} $_css9={styles.navHeaderStyles.changeRegionButtonText} />
            </PrButton>
          </_StyledView2> : null}
      </CpPopup>
    </React.Fragment>;
};
export default CpLanguagePicker;
var _StyledPrText = _styled(PrText)`${p => p.$_css}`;
var _StyledCpItem = _styled2(CpItem)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledView2 = _styled6(View)`${p => p.$_css6}`;
var _StyledPrText4 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrText5 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledPrText6 = _styled9(PrText).withConfig({
  displayName: "CpLanguagePicker___StyledPrText6",
  componentId: "sc-1tslrrs-0"
})(["", ""], p => p.$_css9);