import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8 } from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { DisplayDirection } from "components/form/types";
import CpSessionReviewSelectItem from "components/patientSession/CpSessionReviewSelectItem";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrRadioSegmented from "components/primitives/PrRadioSegmented";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  controlContainer: css`
    align-items: center;
    align-self: flex-end;
  `,
  controlContainerSmall: css`
    align-items: center;
    width: 100%;
  `,
  controls: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100%;
  `,
  headerTopic: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `
};
const localTranslations = defineMessages({
  entireTopic: {
    id: "GDdf5O",
    defaultMessage: "Entire topic"
  }
});
/**
 * Renders lists of resources grouped by their parent topic for selection by the user to include for sending as a PatientSession.
 */
const CpPatientSessionReviewSelect = ({
  nodes
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      annotations,
      selectedResources
    }
  } = usePatientSessionContext();

  // Reduce each topic and its child resources to a single group keyed by the topics revisionId
  const groupedPatientSessionResources = useMemo(() => (nodes ?? []).reduce((prevValue, node) => {
    if (!node || !node?.revisionId || !node?.rootRevisionId) {
      // Guard - trying to get TypeScript to not yell at me
      return prevValue;
    }
    const {
      revisionId,
      rootRevisionId
    } = node;

    // Find the resources parent group
    const currentIndex = prevValue.findIndex(group => group?.id === rootRevisionId);
    const isInPrevValue = currentIndex >= 0;
    // And either append to existing group, or create a new one that we can populate in further iterations
    const currentGroup = isInPrevValue ? prevValue[currentIndex] : {
      id: rootRevisionId,
      resources: [],
      topic: {}
      // This makes TypeScript happy, though it makes me sad
    };
    // Then find the matching resource in our PatientSession selectedResources state and append its 'selected' status to the node
    const selectedResource = selectedResources.find(resource => resource.revisionId === node.revisionId);
    const updatedNode = {
      ...node,
      isTopic: selectedResource?.isTopic ?? node.rootRevisionId === node.revisionId,
      selected: selectedResource?.selected ?? node.rootRevisionId !== node.revisionId
    };
    // Is the current node the topic or a resource?
    if (rootRevisionId === revisionId) {
      currentGroup.topic = updatedNode;
    } else {
      currentGroup.resources.push(updatedNode);
    }

    // Finally, either overwrite an existing group with the latest data, or add it to the stack
    if (isInPrevValue) prevValue[currentIndex] = currentGroup;else prevValue.push(currentGroup);
    return prevValue;
  }, []), [nodes, selectedResources]);

  // Initial state for each groups segmented controls
  // Defaults to 'resources' being selected, but takes into consideration 'selected' state
  const [groupState, setGroupState] = useState(() => {
    const initialState = {};
    groupedPatientSessionResources.forEach(group => {
      // Check for existing 'selected' statuses
      const topicSelected = group.topic.selected;
      const resourcesSelected = group.resources.some(resource => resource.selected);
      // Default to 'resources', unless the user previously made changes
      let initialStateType = topicSelected ? "topic" : "resources";
      if (!topicSelected && !resourcesSelected) {
        initialStateType = "none";
      }
      initialState[group.id] = initialStateType;
    });
    return initialState;
  });

  // Manage the group controls, switching between 'topic', 'resources' and 'none'
  // Will also update the 'selected' state for all topics and resources on change
  const handlePressGroupControls = useCallback((type, id) => {
    // Local copy of frozen selectedResources
    const localSelectedResources = selectedResources.map(resource => resource);
    // Simplify finding the related topic and resources by finding the current group
    const selectedGroup = groupedPatientSessionResources.find(group => group.id === id);
    const topicIndex = localSelectedResources.findIndex(selectedResource => selectedGroup?.topic.revisionId === selectedResource.revisionId);
    const resourceIndexes = selectedGroup?.resources.map(resource => localSelectedResources.findIndex(selectedResource => resource.revisionId === selectedResource.revisionId));
    // If 'topic' is pressed -> select topic, deselect all resources
    // If 'resources' is pressed -> select all resources, deselect the topic
    // If 'none' is pressed -> deselect the topic and all resources
    localSelectedResources[topicIndex].selected = type === "none" ? false : type === "topic";
    resourceIndexes?.map(index => localSelectedResources[index].selected = type === "none" ? false : type === "resources");
    dispatchPatientSession({
      payload: {
        selectedResources: localSelectedResources
      },
      type: PatientSessionActions.SetSelectedResources
    });
    setGroupState({
      ...groupState,
      [id]: type
    });
  }, [selectedResources, groupedPatientSessionResources, dispatchPatientSession, groupState]);
  // Manage a single item 'selected' status - will also change groupState if necessary
  const handleSelectItem = useCallback(value => {
    // Local copy of frozen selectedResources
    const localSelectedResources = selectedResources.map(resource => resource);
    // Find the correct selectedResource and update its 'selected' state
    const selectedIndex = localSelectedResources.findIndex(resource => resource.revisionId === value.revisionId);
    localSelectedResources[selectedIndex].selected = !localSelectedResources[selectedIndex].selected;
    const isNowSelected = localSelectedResources[selectedIndex].selected;

    // Check current groupState and change if necessary
    const selectedItemNode = (nodes ?? []).find(node => node?.revisionId === value.revisionId);
    const selectedItemGroup = groupedPatientSessionResources.find(group => group.id === selectedItemNode?.rootRevisionId);
    if (selectedItemGroup) {
      const currentGroupState = groupState[selectedItemGroup.id];
      if (isNowSelected && currentGroupState === "none") {
        // Selecting anything from a 'none' state will lock the correct group type ('topic' || 'resources')
        setGroupState({
          ...groupState,
          [selectedItemGroup.id]: selectedItemNode?.rootRevisionId === selectedItemNode?.revisionId ? "topic" : "resources"
        });
      } else if (!isNowSelected && currentGroupState === "topic") {
        // Deselecting a topic means nothing is selected - switch to 'none'
        setGroupState({
          ...groupState,
          [selectedItemGroup.id]: "none"
        });
      } else if (!isNowSelected && currentGroupState === "resources") {
        // Deselecting a resource may leave us in 'resources' or require a switch to 'none'
        const selectedResourceCount = selectedItemGroup.resources.reduce((acc, resource) => resource.selected ? acc + 1 : acc, 0);
        // This ONE resource is actually the one being deselected - so we switch to 'none'
        selectedResourceCount === 1 && setGroupState({
          ...groupState,
          [selectedItemGroup.id]: "none"
        });
      }
    }
    dispatchPatientSession({
      payload: {
        selectedResources: localSelectedResources
      },
      type: PatientSessionActions.SetSelectedResources
    });
  }, [dispatchPatientSession, groupState, groupedPatientSessionResources, nodes, selectedResources]);
  const handleToggleAnnotation = useCallback(annotation => {
    dispatchPatientSession({
      payload: annotation.revisionId,
      type: PatientSessionActions.SetSelectedAnnotation
    });
  }, [dispatchPatientSession]);
  return <>
      {groupedPatientSessionResources.map(({
      resources,
      topic
    }, groupIndex) => {
      if (!topic || !topic.revisionId || !topic.id) return null;

      // Extracting to a variable here so that TypeScript doesn't make me engage in fisticuffs with it
      const topicRevisionId = topic.revisionId;
      const groupStateTypeValue = groupState[topicRevisionId];
      const selectedResourceCount = resources.reduce((acc, resource) => resource.selected ? acc + 1 : acc, 0);
      let selectedContentTranslation;
      if (!selectedResourceCount && !topic.selected) {
        selectedContentTranslation = formatMessage(translations.labels.none);
      } else if (topic.selected) {
        selectedContentTranslation = formatMessage(localTranslations.entireTopic);
      } else {
        selectedContentTranslation = formatMessage(translations.entities.resourceCount, {
          count: selectedResourceCount
        });
      }
      return <_StyledView key={topic.id} $_css={styles.sessionStyles.reviewSelect.groupContainer}>
            <_StyledView2 $_css2={isNarrowScreen ? localStyles.controlContainerSmall : localStyles.controlContainer}>
              <_StyledPrRadioSegmented displayDirection={DisplayDirection.Horizontal} onChangeOption={value => handlePressGroupControls(value, topicRevisionId)} radioOptions={[{
            name: formatMessage(translations.entities.topic, {
              count: 1
            }),
            value: "topic"
          }, {
            name: formatMessage(translations.entities.resource, {
              count: 2
            }),
            value: "resources"
          }, {
            name: formatMessage(translations.labels.none),
            value: "none"
          }]} testID={`CpPatientSessionReviewSelectSegmented-${groupIndex}`} value={groupStateTypeValue} $_css3={localStyles.controls} />
            </_StyledView2>
            <_StyledPrText children={formatMessage(translations.entities.topic, {
          count: 1
        })} $_css4={localStyles.headerTopic} />
            <CpSessionReviewSelectItem disabled={groupStateTypeValue === "resources"} icon={topic.icon} identifier={`${groupIndex}-topic`} label={getNameVariant(topic)} onPressCallback={handleSelectItem} onPressValue={{
          revisionId: topic.revisionId
        }} publisher={topic?.publisher?.name} selected={topic.selected} />

            <_StyledPrText2 $_css5={styles.sessionStyles.reviewSelect.headerResource}>
              <FormattedMessage id="oqddmD" defaultMessage="Resources ({count})" values={{
            count: resources.length
          }} />
            </_StyledPrText2>
            {resources.map((resource, resourceIndex) => {
          if (!resource || !resource.id || !resource.revisionId) return null;
          const resourceAnnotation = annotations.find(annotation => annotation.resourceNodeRevisionId === resource.revisionId);
          const resourceIcon = resource?.type === "ResourceNode::Condition" && resource?.relatedNode?.icon ? resource?.relatedNode?.icon : resource?.icon;
          return <React.Fragment key={resource.id}>
                  {resourceIndex !== 0 && <_StyledView3 $_css6={styles.sessionStyles.reviewSelect.separator} />}
                  <CpSessionReviewSelectItem disabled={groupStateTypeValue === "topic"} icon={resourceIcon} identifier={`${groupIndex}-resource-${resourceIndex}`} label={getNameVariant(resource, ["short", "default"])} onPressCallback={handleSelectItem} onPressValue={{
              revisionId: resource.revisionId
            }} publisher={resource?.publisher?.name} selected={resource.selected} />
                  {resourceAnnotation && <CpSessionReviewSelectItem disabled={!resource.selected} identifier={`${groupIndex}-resource-${resourceIndex}-annotation`} isAnnotation label={formatMessage(translations.labels.drawing, {
              name: getNameVariant(resource, ["short", "default"])
            })} onPressCallback={handleToggleAnnotation} onPressValue={{
              revisionId: resourceAnnotation.resourceNodeRevisionId
            }} selected={resource.selected && resourceAnnotation.selected} />}
                </React.Fragment>;
        })}
            <_StyledPrText3 children={translations.labels.selectedForSharing} $_css7={[styles.fontSizeSmall, styles.paddingTopSingle]} />
            <_StyledPrText4 children={selectedContentTranslation} $_css8={styles.fontSizeSmall} />
          </_StyledView>;
    })}
    </>;
};
export default CpPatientSessionReviewSelect;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledPrRadioSegmented = _styled3(PrRadioSegmented)`${p => p.$_css3}`;
var _StyledPrText = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText2 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledView3 = _styled6(View)`${p => p.$_css6}`;
var _StyledPrText3 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrText4 = _styled8(PrText).withConfig({
  displayName: "CpPatientSessionReviewSelect___StyledPrText4",
  componentId: "sc-1g3pzxu-0"
})(["", ""], p => p.$_css8);