import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14 } from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpError from "components/common/CpError";
import CpPackageSessionTemplatesSelect from "components/patientSession/CpPackageSessionTemplatesSelect";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import QlPatientPackages from "query/QlPatientPackages";
import { useBackendSupportedLocale } from "services/Intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonText: css`
    /* Overriding line height as without this text is overly spread out on smaller screens */
    line-height: 105%;
  `
};
/**
 * Renders a screen allowing users to select from any existing packages templates to send patient(s) or save their current selection of items to a package as a template for future use.
 */
const CpPackageSessionTemplates = ({
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    organization,
    user
  } = useImdSession();
  const locale = useBackendSupportedLocale();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageResources
    }
  } = usePatientSessionContext();
  const [error, setError] = useState();
  const queryVariables = useMemo(() => {
    return user?._id && organization?._id ? {
      locale,
      organizationId: organization?._id,
      practitionerId: user?._id
    } : null;
  }, [locale, organization, user]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageStart
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);
  const handleSaveTemplate = useCallback(() => {
    // Save current session as template (opens Save Package Template modal)
    if (packageResources.length === 0) {
      setError(formatMessage(translations.errors.nothingInPackage));
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.PackageSaveTemplate
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [dispatchPatientSession, formatMessage, packageResources.length]);
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="NHoGPK" defaultMessage="Patient Information Package Templates" />
      </_StyledPrText>

      {/* Screen instructions */}
      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        <_StyledPrText2 $_css4={styles.paddingTopSingle}>
          <FormattedMessage id="JhoIPB" defaultMessage="Below you can find a list of any previously saved Patient Information Package Templates to choose from." />
        </_StyledPrText2>
        <_StyledPrText3 $_css5={styles.sessionStyles.subtext}>
          <FormattedMessage id="XwpZ4b" defaultMessage="NB: Selecting an existing template will clear any currently selected items." />
        </_StyledPrText3>
        <PrText>
          <FormattedMessage id="rCGkBh" defaultMessage="You can also save your currently selected items as a template for future use." />
        </PrText>

        {/* Save template button */}
        <_StyledPrButton accessibilityHint={formatMessage(translations.accessibility.hint.packageSave)} accessibilityRole="link" onPress={handleSaveTemplate} testID="CpPackageSessionTemplates-saveTemplate" type="text" $_css6={styles.marginVerticalSingle}>
          <_StyledView2 $_css7={[styles.rowAndCenter, styles.flexSingle]}>
            <_StyledPrIcon fill={buttonColors.primary} icon={IconName.medicalSave} size={iconSizes.medium} $_css8={styles.paddingHorizontalSingle} />
            <_StyledPrText4 children={translations.buttons.saveAsTemplate} $_css9={[styles.sessionStyles.buttonText, localStyles.buttonText]} />
          </_StyledView2>
        </_StyledPrButton>

        <_StyledCpError error={error} $_css10={styles.paddingVerticalSingle} />

        {/* List of saved templates */}
        {queryVariables && <QlPatientPackages variables={queryVariables}>
            {response => {
          if (response.patientPackages?.length) {
            return <CpPackageSessionTemplatesSelect data={response.patientPackages} />;
          } else {
            return <_StyledView3 $_css11={styles.sessionStyles.reviewSelect.groupContainer}>
                    <PrText>
                      <FormattedMessage id="Ksw+pT" defaultMessage="Looks as if you don't have any package templates yet." />
                    </PrText>
                    <_StyledPrText5 $_css12={styles.paddingTopSingle}>
                      <FormattedMessage id="06ZLuM" defaultMessage="Templates can be created using the button above. You can also create a template from your selection after reviewing, and prior to sending." />
                    </_StyledPrText5>
                  </_StyledView3>;
          }
        }}
          </QlPatientPackages>}

        <_StyledPrButton2 onPress={handleBack} testID="CpPackageSessionTemplates-back" type="outline" $_css13={styles.marginTopDouble}>
          <_StyledPrText6 children={translations.buttons.back} $_css14={styles.sessionStyles.buttonText} />
        </_StyledPrButton2>
      </_StyledView>
    </_StyledScrollView>;
};
export default CpPackageSessionTemplates;
var _StyledScrollView = _styled(ScrollView)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrButton = _styled6(PrButton)`${p => p.$_css6}`;
var _StyledView2 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrIcon = _styled8(PrIcon)`${p => p.$_css8}`;
var _StyledPrText4 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledCpError = _styled10(CpError)`${p => p.$_css10}`;
var _StyledView3 = _styled11(View)`${p => p.$_css11}`;
var _StyledPrText5 = _styled12(PrText)`${p => p.$_css12}`;
var _StyledPrButton2 = _styled13(PrButton)`${p => p.$_css13}`;
var _StyledPrText6 = _styled14(PrText).withConfig({
  displayName: "CpPackageSessionTemplates___StyledPrText6",
  componentId: "sc-rk8jby-0"
})(["", ""], p => p.$_css14);