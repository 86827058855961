import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9 } from "styled-components";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpPatientSessionHistorySelect from "components/patientSession/CpPatientSessionHistorySelect";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import QlPatientSession from "query/QlPatientSession";
import { useBackendSupportedLocale } from "services/Intl";
import { getSelectedResourcesFromViewedResources } from "services/PatientSession";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Renders the most recent Topic(s) viewed by the user to select as a starting point for a PatientSession not explicitly started. Starts a PatientSession based on the selected topic.
 */
const CpPatientSessionStartFromHistory = ({
  onClose,
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const locale = useBackendSupportedLocale();
  const {
    dispatchPatientSession,
    patientSessionData: {
      selectedResources,
      viewedResources
    }
  } = usePatientSessionContext();
  const queryVariables = useMemo(() => ({
    locale,
    revisionIds: viewedResources.map(resource => resource.revisionId)
  }), [locale, viewedResources]);
  const handleSelectFromHistory = useCallback((selectedRevisionId, nodes) => {
    // Compile a subset of selectedResources from viewedResources
    const localSelectedResources = getSelectedResourcesFromViewedResources({
      nodes,
      selectedResources,
      selectedRevisionId,
      viewedResources
    });
    dispatchPatientSession({
      payload: {
        selectedResources: localSelectedResources
      },
      type: PatientSessionActions.StartSessionFromHistory
    });
  }, [dispatchPatientSession, selectedResources, viewedResources]);
  return <QlPatientSession variables={queryVariables}>
      {response => {
      if (response.resourceNodesByRevision?.nodes) {
        return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
              <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
                <FormattedMessage id="iKOq+/" defaultMessage="Session History" />
              </_StyledPrText>
              <_StyledView $_css3={styles.paddingHorizontalSingle}>
                <View>
                  <_StyledPrText2 $_css4={styles.paddingTopSingle}>
                    <FormattedMessage id="jHhbM7" defaultMessage="Select the relevant starting point for this Patient Education Session from your recent viewing history below." />
                  </_StyledPrText2>
                  <_StyledPrText4 children={formatMessage(translations.patientSessionTranslations.patientPrivacy, {
                showMore: <_StyledPrText3 children={translations.buttons.showMore} $_css5={styles.italicText} />
              })} $_css6={styles.sessionStyles.subtext} />
                </View>

                <CpPatientSessionHistorySelect nodes={response.resourceNodesByRevision?.nodes} onPress={handleSelectFromHistory} />

                {/* Buttons */}
                <_StyledView2 $_css7={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
                  <_StyledPrButton onPress={onClose} testID="CpPatientSessionStartFromHistory-back" type="outline" $_css8={styles.marginTopDouble}>
                    <_StyledPrText5 children={translations.buttons.back} $_css9={styles.sessionStyles.buttonText} />
                  </_StyledPrButton>
                </_StyledView2>
              </_StyledView>
            </_StyledScrollView>;
      } else {
        return null;
      }
    }}
    </QlPatientSession>;
};
export default CpPatientSessionStartFromHistory;
var _StyledScrollView = _styled(ScrollView)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrText4 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledView2 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrButton = _styled8(PrButton)`${p => p.$_css8}`;
var _StyledPrText5 = _styled9(PrText).withConfig({
  displayName: "CpPatientSessionStartFromHistory___StyledPrText5",
  componentId: "sc-1er597w-0"
})(["", ""], p => p.$_css9);