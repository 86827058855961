import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17 } from "styled-components";
var _CtChangeAccountPasswordMutation;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpLinkButton from "components/common/CpLinkButton";
import CpModal from "components/common/CpModal";
import CpForm from "components/form/CpForm";
import CpFormNewPassword from "components/form/CpFormNewPassword";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useFormResolver from "hooks/useFormResolver";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { GraphqlErrorCode, SubmitStatus, maxPasswordLength } from "services/Constants";
import { isGraphqlError } from "services/Error";
import Log from "services/Log";
import { css, rnStyles, styles } from "services/Theme";
import { passwordValidation } from "services/Validation";
import translations from "translations";
const changePasswordMutation = _CtChangeAccountPasswordMutation !== void 0 ? _CtChangeAccountPasswordMutation : _CtChangeAccountPasswordMutation = require("./__generated__/CtChangeAccountPasswordMutation.graphql");
const localStyles = {
  buttonContainer: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  changeAccountPasswordContainer: css`
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  confirmButtonContainerDesktop: css`
    flex-direction: row-reverse;
    justify-content: flex-start;
  `,
  confirmButtonContainers: css`
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  modalButtonDesktop: css`
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  unconfirmedEmail: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `
};
const localTranslations = defineMessages({
  confirmationText: {
    id: "pmRlwn",
    defaultMessage: "Are you sure you want to change your account password?"
  },
  currentPassword: {
    id: "trlMg5",
    defaultMessage: "Current password"
  },
  incorrectPassword: {
    id: "RU2XC8",
    defaultMessage: "The current password that you provided is incorrect."
  },
  newPassword: {
    id: "ISd8D7",
    defaultMessage: "New password"
  },
  successMessage: {
    id: "FB/aza",
    defaultMessage: "You successfully changed your account password. Make sure your Password Manager is updated to use your new password next time you sign in to IMD Health."
  }
});
/**
 * Container that manages the layout and form that allows a user to change their account password.
 */
const CtChangeAccountPassword = () => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    navigate
  } = useNavigation();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.Waiting);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(translations.errors.genericError);
  const [submitChangePassword, isSubmittingChangePassword] = useMutation(changePasswordMutation);
  const [formError, setFormError] = useState();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      currentPassword: zod.string().min(1),
      password: zod.string().refine(...passwordValidation({
        minLength: 8,
        requireNumbers: true,
        requireSymbols: true
      }))
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(async values => {
    if (confirmationModalOpen) {
      submitChangePassword({
        onCompleted: ({
          changeUserPassword
        }) => {
          Log.info(`CtChangeAccountPassword - Password successfully changed for: ${changeUserPassword?.email}`);
          setSubmitStatus(SubmitStatus.Successful);
        },
        onError: err => {
          // TODO: Filter out expected errors, and send the rest to sentry
          Log.info(err);
          setSubmitStatus(SubmitStatus.Error);
          setFormError(err);
          if (isGraphqlError(err)) {
            const result = err.result?.[0];
            const isInvalid = result && result.extensions?.code === GraphqlErrorCode.Invalid;
            setErrorMessage(isInvalid ? localTranslations.incorrectPassword : translations.errors.genericError);
            return;
          }
          setErrorMessage(translations.errors.genericError);
        },
        variables: {
          input: values
        }
      });
    }
  }), [confirmationModalOpen, form, submitChangePassword]);
  const handleOpenConfirmationModal = useCallback(async () => {
    setSubmitStatus(SubmitStatus.Waiting);
    await form.trigger();
    if (form.formState.isValid) {
      setConfirmationModalOpen(true);
    }
  }, [form]);
  const handleCloseConfirmationModal = useCallback(() => {
    setSubmitStatus(SubmitStatus.Waiting);
    setConfirmationModalOpen(false);
  }, []);
  const handlePressContinue = useCallback(() => {
    handleCloseConfirmationModal();
    // We just want to reset the form in case user is successful
    if (submitStatus === SubmitStatus.Successful) {
      form.reset();
    }
  }, [form, handleCloseConfirmationModal, submitStatus]);
  const handleBack = useCallback(() => {
    navigate(Routes.manage_account);
  }, [navigate]);

  // Resets form error every time we close or open the confirmation modal
  useEffect(() => {
    setFormError("");
  }, [confirmationModalOpen]);
  return <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css={styles.screen}>
      <main>
        <CpBackgroundHeader childStyle={styles.flexSingle} header={translations.screens.change_password}>
          <_StyledView $_css2={[localStyles.changeAccountPasswordContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
            <CpForm {...form} onSubmit={handleSubmit}>
              <_StyledCpModal onBackgroundPress={submitStatus === SubmitStatus.Waiting ? handleCloseConfirmationModal : handlePressContinue} visible={confirmationModalOpen} $_css3={styles.marginHorizontalSingle}>
                <_StyledView2 $_css4={styles.modalContainer}>
                  <_StyledPrText accessibilityRole="header" children={translations.screens.change_password} $_css5={styles.modalHeaderText} />
                  {submitStatus === SubmitStatus.Waiting ? <>
                      <_StyledView3 $_css6={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                        <PrText children={formatMessage(localTranslations.confirmationText)} />
                      </_StyledView3>
                      <_StyledView4 $_css7={[localStyles.confirmButtonContainers, isNarrowScreen ? undefined : localStyles.confirmButtonContainerDesktop]}>
                        <_StyledPrButton busy={isSubmittingChangePassword} onPress={handleSubmit} testID="CtChangeAccountPasswordModalSubmit" $_css8={isNarrowScreen ? styles.marginBottomSingle : localStyles.modalButtonDesktop}>
                          <_StyledPrText2 children={translations.buttons.confirm} $_css9={localStyles.buttonText} />
                        </_StyledPrButton>
                        <PrButton onPress={handleCloseConfirmationModal} type="text">
                          <_StyledPrText3 children={translations.buttons.cancel} $_css10={localStyles.buttonText} />
                        </PrButton>
                      </_StyledView4>
                    </> : <>
                      <_StyledView5 $_css11={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                        <PrText children={submitStatus === SubmitStatus.Error ? formatMessage(translations.errors.customError, {
                      customError: formatMessage(errorMessage),
                      supportEmail: <CpLinkButton url="mailto:support@imdhealth.com">
                                      <PrText>support@imdhealth.com</PrText>
                                    </CpLinkButton>
                    }) : formatMessage(localTranslations.successMessage)} />
                      </_StyledView5>
                      <_StyledPrButton2 busy={isSubmittingChangePassword} onPress={handlePressContinue} testID="CtChangeAccountPasswordContinue" $_css12={styles.marginTopDouble}>
                        <_StyledPrText4 children={translations.buttons.continue} $_css13={localStyles.buttonText} />
                      </_StyledPrButton2>
                    </>}
                </_StyledView2>
              </_StyledCpModal>
              <CpFormTextInput accessibilityLabel={formatMessage(localTranslations.currentPassword)} autoCapitalize="none" autoCorrect={false} label={localTranslations.currentPassword} maxLength={maxPasswordLength} name="currentPassword" password={true} placeholder={translations.labels.accountPasswordPlaceholder} returnKeyType="next" textContentType="password" />
              <CpFormNewPassword accessibilityLabel={formatMessage(localTranslations.newPassword)} label={localTranslations.newPassword} name="password" onSubmitEditing={handleSubmit} placeholder={translations.attributes.user.newPassword} returnKeyType="next" />
              {!confirmationModalOpen && <_StyledCpError error={formError} $_css14={[styles.formField, styles.errorBox]} />}
              <_StyledView6 $_css15={[localStyles.buttonContainer, !isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
                <PrButton accessibilityHint={formatMessage(translations.accessibility.hint.changePasswordButton)} onPress={handleOpenConfirmationModal} testID="CtChangeAccountPasswordSubmit">
                  <_StyledPrText5 children={translations.buttons.submit} $_css16={localStyles.buttonText} />
                </PrButton>
                <PrButton onPress={handleBack} type="text">
                  <_StyledPrText6 children={translations.buttons.cancel} $_css17={localStyles.buttonText} />
                </PrButton>
              </_StyledView6>
            </CpForm>
          </_StyledView>
        </CpBackgroundHeader>
      </main>
    </_StyledScrollView>;
};
export default CtChangeAccountPassword;
var _StyledScrollView = _styled(ScrollView)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledCpModal = _styled3(CpModal)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledView3 = _styled6(View)`${p => p.$_css6}`;
var _StyledView4 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrButton = _styled8(PrButton)`${p => p.$_css8}`;
var _StyledPrText2 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledPrText3 = _styled10(PrText)`${p => p.$_css10}`;
var _StyledView5 = _styled11(View)`${p => p.$_css11}`;
var _StyledPrButton2 = _styled12(PrButton)`${p => p.$_css12}`;
var _StyledPrText4 = _styled13(PrText)`${p => p.$_css13}`;
var _StyledCpError = _styled14(CpError)`${p => p.$_css14}`;
var _StyledView6 = _styled15(View)`${p => p.$_css15}`;
var _StyledPrText5 = _styled16(PrText)`${p => p.$_css16}`;
var _StyledPrText6 = _styled17(PrText).withConfig({
  displayName: "CtChangeAccountPassword___StyledPrText6",
  componentId: "sc-1jdd2t3-0"
})(["", ""], p => p.$_css17);