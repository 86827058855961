import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17, default as _styled18, default as _styled19, default as _styled20, default as _styled21 } from "styled-components";
import { useRoute } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image, Modal, View } from "react-native";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CpDrawingCanvas from "components/topic/drawingTools/CpDrawingCanvas";
import CpDrawingToolsButtons from "components/topic/drawingTools/CpDrawingToolsButtons";
import { DrawingToolsActions } from "components/topic/drawingTools/drawingTools";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useDrawingToolsContext from "hooks/useDrawingToolsContext";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  annotationToggleButton: css`
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  button: css`
    border-radius: 0;
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  buttonText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  cardCaption: css`
    align-self: center;
    color: ${({
    theme
  }) => theme.textColors.default};
    font-family: ${({
    theme
  }) => theme.fontFamilies.default};
    font-style: italic;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    text-align: center;
  `,
  cardContainer: css`
    align-self: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    margin: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  cardLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    padding-right: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  cardLabelContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  disclaimerText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  drawAndFullScreen: css`
    align-items: center;
    flex-direction: row;
  `,
  drawContainer: css`
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    flex-direction: row;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
    margin-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    padding: 0;
  `,
  fullscreenButtonContainer: css`
    align-items: center;
    align-self: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    z-index: ${({
    theme
  }) => theme.layers.secondLayer}px;
  `,
  fullscreenCloseButton: css`
    ${styles.rowAndCenter};
    align-self: flex-end;
  `,
  fullscreenCloseText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  fullscreenContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    flex: 1;
  `,
  fullscreenImage: css`
    flex: 1;
    height: 100%;
    margin: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `,
  fullscreenText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `,
  image: css`
    align-self: center;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const largeScreenStyles = {
  cardCaption: css`
    ${localStyles.cardCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-top: 0;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fullscreenText: css`
    ${localStyles.fullscreenText};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
const smallScreenStyles = {
  cardCaption: css`
    ${localStyles.cardCaption};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  fullscreenCloseText: css`
    ${localStyles.fullscreenCloseText};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  fullscreenText: css`
    ${localStyles.fullscreenText};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
  `
};
/**
 * Renders an Image in a card format. Displays a label, and an optional caption. Has built in functionality for expanding to fullscreen (for larger screens).
 */
const CpImageCard = ({
  caption,
  label,
  style,
  testID,
  uri
}) => {
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors,
    iconSizes,
    spacing
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    appConfig,
    user
  } = useImdSession();
  const drawingTool = appConfig?.appTools?.find(tool => tool.name === "Draw");
  const isDrawingDisabled = drawingTool?.status !== "active";
  const isPatient = user?.isPatient;
  const allowAnnotations = Boolean(user) && !isPatient && !isDrawingDisabled;
  const {
    dispatchDrawingTools,
    drawingToolsData: {
      drawingEnabled,
      uploadedImage
    }
  } = useDrawingToolsContext();
  const {
    patientSessionData: {
      annotations,
      isViewingSession
    }
  } = usePatientSessionContext();
  const route = useRoute();
  const [viewOriginal, setViewOriginal] = useState(false);
  const [hasAnnotationImage, setHasAnnotationImage] = useState(false);
  const handleToggleViewOriginal = useCallback(() => {
    setViewOriginal(isOriginalImage => !isOriginalImage);
  }, []);

  // Clears context when we change routes
  useEffect(() => {
    dispatchDrawingTools({
      type: DrawingToolsActions.ClearContext
    });
  }, [dispatchDrawingTools, route]);

  // Decides which image to display
  const displayedImage = useMemo(() => {
    // Resets to default state of not having an annotation
    setHasAnnotationImage(false);
    if (viewOriginal) {
      return uri;
    }
    if (uploadedImage) {
      return uploadedImage;
    }
    if (!route.params?.selectedRevisionId) {
      return null;
    }
    const hasAnnotation = annotations.find(annotation => annotation.resourceNodeRevisionId === route.params.selectedRevisionId);
    if (hasAnnotation) {
      setHasAnnotationImage(true);
      return hasAnnotation.url;
    }
    return null;
  }, [annotations, route.params.selectedRevisionId, uploadedImage, uri, viewOriginal]);
  const [imageSize, setImageSize] = useState({
    aspectRatio: 1,
    height: 0,
    width: 0
  });
  const componentStyles = isNarrowScreen ? smallScreenStyles : largeScreenStyles;
  const [showFullscreen, setShowFullscreen] = useState(false);
  const handleSetShowFullscreen = useCallback(() => setShowFullscreen(true), []);
  const handleSetHideFullscreen = useCallback(() => setShowFullscreen(false), []);
  const {
    params: {
      draw
    }
  } = route;
  const handleOpenDrawingMode = useCallback(() => {
    dispatchDrawingTools({
      payload: {
        drawingEnabled: true,
        isDrawing: true
      },
      type: DrawingToolsActions.SetDrawingOptions
    });
    trackGoogleEvent({
      action: "enabled drawing tools",
      name: "drawingTools"
    });
    setShowFullscreen(true);
  }, [dispatchDrawingTools, trackGoogleEvent]);

  // Opens drawing mode if the drawing route param is present
  useEffect(() => {
    if (draw) {
      handleOpenDrawingMode();
    }
  }, [draw, handleOpenDrawingMode]);

  // Determine the optimal size to render the image
  const handleLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    if (uri) {
      Image.getSize(uri, (srcWidth, srcHeight) => {
        const aspectRatio = srcWidth / srcHeight;
        // In order to make sure the image fills the card properly, we use the width of the card component as the final size so long as it doesn't exceed our max width
        // Remove quadruple spacing to accommodate spacing on either side of the image and card
        // TODO: What is a good MAX image height/width? Should it be different per screen size?
        const maxWidth = Math.min(layout.width - spacing.double, 1200);
        const maxHeight = 1000;
        const scaleFactor = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        const adjustedHeight = scaleFactor * srcHeight;
        const adjustedWidth = scaleFactor * srcWidth;
        setImageSize({
          aspectRatio,
          height: adjustedHeight,
          width: adjustedWidth
        });
      });
    }
  }, [spacing, uri]);

  // Show the toggle annotation/original button if these conditions are met
  const showToggleAnnotationButton = displayedImage && displayedImage !== uri || uploadedImage || hasAnnotationImage || viewOriginal;
  const renderToggleAnnotationButton = useMemo(() => {
    return <_StyledPrButton onPress={handleToggleViewOriginal} testID="CpImageCardToggleAnnotationButton" type="text" $_css={[localStyles.button, localStyles.annotationToggleButton]}>
        <_StyledView $_css2={[styles.center, styles.row]}>
          <_StyledPrText $_css3={[localStyles.buttonText]}>
            {viewOriginal ? <FormattedMessage id="cm6nkZ" defaultMessage="View drawing" /> : <FormattedMessage id="HUo9w4" defaultMessage="View original" />}
          </_StyledPrText>
        </_StyledView>
      </_StyledPrButton>;
  }, [handleToggleViewOriginal, viewOriginal]);
  return <_StyledView2 onLayout={handleLayout} style={style} testID={testID} $_css4={localStyles.cardContainer}>
      {/* Fullscreen Image View - displays in edge-to-edge modal */}
      {showFullscreen && <Modal onRequestClose={handleSetHideFullscreen} transparent={true} visible={showFullscreen}>
          <_StyledView3 $_css5={localStyles.fullscreenContainer}>
            <_StyledView4 $_css6={localStyles.fullscreenButtonContainer}>
              {isViewingSession && showToggleAnnotationButton ? renderToggleAnnotationButton : null}
              <CpDrawingToolsButtons onCloseFullScreen={handleSetHideFullscreen} />
            </_StyledView4>
            {allowAnnotations && drawingEnabled ? <CpDrawingCanvas imageSize={imageSize} uri={uri} /> : <_StyledImage resizeMode="contain" source={{
          uri: displayedImage ? displayedImage : uri
        }} $_css7={localStyles.fullscreenImage} $_css8={imageSize.aspectRatio} />}
          </_StyledView3>
        </Modal>}

      {/* Card label and fullscreen button for non-mobile screens */}
      <_StyledView5 $_css9={localStyles.cardLabelContainer}>
        <_StyledPrText2 children={label} $_css10={componentStyles.cardLabel} />
        {!isNarrowScreen && <_StyledView6 $_css11={localStyles.drawAndFullScreen}>
            {showToggleAnnotationButton ? renderToggleAnnotationButton : null}
            {allowAnnotations && <_StyledView7 $_css12={localStyles.drawContainer}>
                <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.openDrawingMode)} onPress={handleOpenDrawingMode} testID="CpImageCardDraw" type="text" $_css13={[styles.flexSingle, localStyles.button]}>
                  <_StyledView8 $_css14={[styles.center, styles.row]}>
                    <_StyledPrIcon fill={buttonColors.primary} icon={IconName.brush} size={iconSizes.small} $_css15={styles.marginHorizontalSingle} />
                    <_StyledPrText3 children={translations.buttons.draw} $_css16={[localStyles.buttonText]} />
                  </_StyledView8>
                </_StyledPrButton2>
              </_StyledView7>}
            <_StyledPrPressable accessibilityHint={formatMessage(translations.accessibility.hint.openFullScreen)} accessibilityRole="button" onPress={handleSetShowFullscreen} $_css17={styles.center}>
              <PrIcon fill={buttonColors.primary} icon={IconName.fullscreen} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
              <_StyledPrText4 children={translations.buttons.fullscreen} $_css18={componentStyles.fullscreenText} />
            </_StyledPrPressable>
          </_StyledView6>}
      </_StyledView5>
      <_StyledImage2 resizeMode="contain" source={{
      uri: displayedImage ? displayedImage : uri
    }} testID="CpImageCardImage" $_css19={localStyles.image} $_css20={imageSize.aspectRatio} $_css21={imageSize.height} $_css22={imageSize.width} />
      {!!caption &&
    // Captions may include html tags that would require special parsing/handling
    <_StyledCpDivHtmlWeb content={caption} $_css23={[componentStyles.cardCaption,
    // Limiting width as longer captions on large screens will push caption beyond screen edges
    isWideScreen && css`
                max-width: ${imageSize.width}px;
              `]} />}
      {/* Only show disclaimer if: It's a patient session view, there is an annotation, and it's not original view mode */}
      {isViewingSession && hasAnnotationImage && !viewOriginal && <_StyledView9 $_css24={[styles.paddingHorizontalSingle, styles.marginBottomSingle]}>
          <_StyledPrText5 $_css25={localStyles.disclaimerText}>
            <FormattedMessage id="SyxDtn" defaultMessage="This image content might be changed in the future, we recommend you to keep your own copy by downloading or printing the image." />
          </_StyledPrText5>
        </_StyledView9>}
    </_StyledView2>;
};
export default CpImageCard;
var _StyledPrButton = _styled(PrButton)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledView3 = _styled5(View)`${p => p.$_css5}`;
var _StyledView4 = _styled6(View)`${p => p.$_css6}`;
var _StyledImage = _styled7(Image)`
                  ${p => p.$_css7};
                  aspect-ratio: ${p => p.$_css8};
                `;
var _StyledView5 = _styled8(View)`${p => p.$_css9}`;
var _StyledPrText2 = _styled9(PrText)`${p => p.$_css10}`;
var _StyledView6 = _styled10(View)`${p => p.$_css11}`;
var _StyledView7 = _styled11(View)`${p => p.$_css12}`;
var _StyledPrButton2 = _styled12(PrButton)`${p => p.$_css13}`;
var _StyledView8 = _styled13(View)`${p => p.$_css14}`;
var _StyledPrIcon = _styled14(PrIcon)`${p => p.$_css15}`;
var _StyledPrText3 = _styled15(PrText)`${p => p.$_css16}`;
var _StyledPrPressable = _styled16(PrPressable)`${p => p.$_css17}`;
var _StyledPrText4 = _styled17(PrText)`${p => p.$_css18}`;
var _StyledImage2 = _styled18(Image)`
          ${p => p.$_css19};
          aspect-ratio: ${p => p.$_css20};
          height: ${p => p.$_css21}px;
          width: ${p => p.$_css22}px;
        `;
var _StyledCpDivHtmlWeb = _styled19(CpDivHtmlWeb)`${p => p.$_css23}`;
var _StyledView9 = _styled20(View)`${p => p.$_css24}`;
var _StyledPrText5 = _styled21(PrText).withConfig({
  displayName: "CpImageCard___StyledPrText5",
  componentId: "sc-1czi2zp-0"
})(["", ""], p => p.$_css25);