import _styled, { default as _styled2, default as _styled3 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import CpError from "components/common/CpError";
import CpGrid from "components/common/CpGrid";
import CpGridItem from "components/home/tabs/CpGridItem";
import isUrlString from "helpers/isUrlString";
import Routes from "navigators/Routes";
import QlRelatedTopics from "query/QlRelatedTopics";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  container: css`
    align-items: center;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Renders a grid of topics related to the current topic
 */
const CpRelatedTopics = ({
  id
}) => {
  const navigation = useNavigation();
  const locale = useBackendSupportedLocale();
  const variables = useMemo(() => ({
    id,
    locale
  }), [id, locale]);
  const handleSelect = useCallback(rootRevisionId => {
    Log.info("Related root ResourceNode selected", {
      rootRevisionId
    });
    navigation.navigate(Routes.topic, {
      rootRevisionId
    });
  }, [navigation]);
  return <QlRelatedTopics variables={variables}>
      {response => {
      const childNodes = response?.node?.children;
      return childNodes ? <_StyledCpGrid $_css={localStyles.container}>
            <_StyledView $_css2={styles.rowAndWrap}>
              {childNodes.map(node => {
            const nodeId = node?.resourceNodeRevisionId;
            // Since GridItem wants a separate icon or image prop, we need to confirm them here
            const nodeImage = node?.relatedNode?.icon && isUrlString(node.relatedNode.icon) ? node.relatedNode.icon : undefined;
            const nodeIcon = nodeImage ? undefined : node?.relatedNode?.icon;
            const name = getNameVariant(node);
            if (!nodeId) {
              // ResourceNodeRevisionId is the root revision id for the related topic, without it, we can't link away
              Log.error(`CpRelatedTopics - No resourceNodeRevisionId found for "${name}" with revisionId: ${nodeId}`);
              return null;
            }
            return <CpGridItem color="primary" icon={nodeIcon} image={nodeImage} key={`CpRelatedTopics-${nodeId}`} link={{
              link_type: "topic",
              value: ""
            }} onPress={() => handleSelect(nodeId)} title={name} />;
          })}
            </_StyledView>
          </_StyledCpGrid> : <_StyledView2 $_css3={styles.paddingQuadruple}>
            <CpError error={translations.errors.default} />
          </_StyledView2>;
    }}
    </QlRelatedTopics>;
};
export default CpRelatedTopics;
var _StyledCpGrid = _styled(CpGrid)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View).withConfig({
  displayName: "CpRelatedTopics___StyledView2",
  componentId: "sc-101z5f4-0"
})(["", ""], p => p.$_css3);