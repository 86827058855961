import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5 } from "styled-components";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import CpPaginationItem from "components/common/CpPaginationItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { usePagination } from "hooks/usePagination";
import useScreenLayout from "hooks/useScreenLayout";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  disabled: css`
    color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
  `,
  paginationContainer: css`
    flex-direction: row;
    justify-content: center;
  `,
  paginationItem: css`
    align-items: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    flex: 1;
    justify-content: center;
    max-width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  rowReverse: css`
    flex-direction: row-reverse;
  `
};
/**
 * Renders a pagination component
 */
const CpPagination = ({
  currentPage,
  onPageChange,
  pageSize,
  siblingCount = 2,
  totalEntries
}) => {
  const {
    buttonColors,
    formColors
  } = useAppTheme();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const paginationRange = usePagination({
    currentPage,
    isNarrowScreen,
    pageSize,
    siblingCount,
    totalEntries
  });
  const totalPages = useMemo(() => {
    return Math.ceil(totalEntries / pageSize);
  }, [totalEntries, pageSize]);
  const handlePress = page => {
    onPageChange(page);
  };
  const handlePressNext = useCallback(() => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, onPageChange, totalPages]);
  const handlePressPrev = useCallback(() => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  // don't return the pagination component if there are no results
  if (!paginationRange.length) return null;
  return <_StyledView $_css={localStyles.paginationContainer}>
      <_StyledPrPressable disabled={currentPage === 1} onPress={handlePressPrev} $_css2={localStyles.paginationItem}>
        <PrIcon fill={currentPage === 1 ? formColors.fieldBorderInactive : buttonColors.primary} icon={IconName.keyboardArrowLeft} size={iconSizes.medium} />
      </_StyledPrPressable>
      {paginationRange.length && paginationRange.map((paginationGroup, index) => {
      if (!paginationGroup.length) return null;
      // If it's first or last array and they have ellipis, render ellipsis depending on the corret position
      if (index === 0 || index === 2) {
        return <React.Fragment key={paginationGroup[0]}>
                {index === 2 ? <_StyledView2 $_css3={localStyles.paginationItem}>
                    <PrText children="..." />
                  </_StyledView2> : null}
                <CpPaginationItem onPress={handlePress} page={paginationGroup[0]} />
                {index === 0 ? <_StyledView3 $_css4={localStyles.paginationItem}>
                    <PrText children="..." />
                  </_StyledView3> : null}
              </React.Fragment>;
      } else {
        return paginationGroup.map(page => {
          return <CpPaginationItem isSelected={page === currentPage} key={page} onPress={handlePress} page={page} />;
        });
      }
    })}
      <_StyledPrPressable2 disabled={currentPage === totalPages} onPress={handlePressNext} $_css5={localStyles.paginationItem}>
        <PrIcon fill={currentPage === totalPages ? formColors.fieldBorderInactive : buttonColors.primary} icon={IconName.keyboardArrowRight} size={iconSizes.medium} />
      </_StyledPrPressable2>
    </_StyledView>;
};
export default CpPagination;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrPressable = _styled2(PrPressable)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledView3 = _styled4(View)`${p => p.$_css4}`;
var _StyledPrPressable2 = _styled5(PrPressable).withConfig({
  displayName: "CpPagination___StyledPrPressable2",
  componentId: "sc-yqd0l2-0"
})(["", ""], p => p.$_css5);