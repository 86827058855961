import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15 } from "styled-components";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  container: css`
    ${styles.rowAndCenter};
    ${styles.paddingSingle};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
  `,
  description: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-style: italic;
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  expandButtonText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  expandedAction: css`
    justify-content: center;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  expandedActionLabel: css`
    ${styles.marginHorizontalSingle};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
  `,
  expandedContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  label: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
  `,
  resourceCount: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    padding-top: ${({
    theme
  }) => theme.spacing.quarter}px;
  `
};
/**
 * Renders the details and actions for a single saved package template.
 */
const CpPackageSessionTemplatesSelectItem = ({
  description,
  id,
  name,
  onReviewAndSend,
  packageId,
  resourceCount
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    organization,
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPackageSession
    }
  } = usePatientSessionContext();
  const [expanded, setExpanded] = useState(false);
  const handleSetExpanded = useCallback(() => {
    setExpanded(prevState => !prevState);
    if (!isPackageSession) {
      // Reporting packageSession Started when template loaded without prior sending Started event
      trackGoogleEvent({
        action: "Started",
        name: "packageSession",
        options: {
          dimensions: {
            [GoogleAnalyticsDimension.organizationId]: organization?._id,
            [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
          }
        }
      });
    }
    // PackageSession loadPackageTemplate button click
    trackGoogleEvent({
      action: "templateLoaded",
      name: "packageSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
  }, [organization?._id, trackGoogleEvent, user, isPackageSession]);
  const handleDelete = useCallback(() => dispatchPatientSession({
    payload: {
      id,
      name
    },
    type: PatientSessionActions.DeletePackage
  }), [dispatchPatientSession, id, name]);
  const handleReviewAndSend = useCallback(() => onReviewAndSend(id), [id, onReviewAndSend]);
  return <React.Fragment>
      <_StyledPrPressable accessibilityHint={formatMessage(translations.accessibility.hint.expand)} accessibilityState={{
      expanded
    }} onPress={handleSetExpanded} testID={`CpPackageSessionTemplatesSelectItem-${packageId}`} $_css={localStyles.container}>
        <_StyledView $_css2={styles.flexSingle}>
          <_StyledPrText children={name} $_css3={localStyles.label} />
          <_StyledPrText2 children={formatMessage(translations.entities.resourceCount, {
          count: resourceCount
        })} $_css4={localStyles.resourceCount} />
        </_StyledView>
        <_StyledView2 $_css5={[styles.center, styles.marginHorizontalSingle]}>
          <_StyledPrText3 children={expanded ? translations.buttons.less : translations.buttons.more} $_css6={localStyles.expandButtonText} />
          <PrIcon accessibilityLabel="" accessibilityRole="none" fill={brandColors.primary} icon={expanded ? IconName.keyboardArrowUp : IconName.keyboardArrowDown} size={iconSizes.medium} />
        </_StyledView2>
      </_StyledPrPressable>
      {expanded && <_StyledView3 $_css7={localStyles.expandedContainer}>
          {description && <_StyledPrText4 children={description} $_css8={localStyles.description} />}
          <_StyledView4 $_css9={[description ? styles.paddingTopSingle : undefined, !isNarrowScreen ? localStyles.buttonContainer : undefined]}>
            <_StyledPrButton accessibilityHint={formatMessage(translations.accessibility.hint.reviewAndSend)} accessibilityRole="link" color="primary" onPress={handleReviewAndSend} testID={`CpPackageSessionTemplatesSelectItem-reviewAndSend-${packageId}`} transparent={false} type="outline" $_css10={localStyles.expandedAction}>
              <_StyledView5 $_css11={[styles.rowAndCenter, styles.paddingSingle]}>
                <PrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.small} />
                <_StyledPrText5 children={translations.buttons.reviewAndSend} $_css12={localStyles.expandedActionLabel} />
              </_StyledView5>
            </_StyledPrButton>
            <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.packageDelete)} accessibilityRole="link" color="destructive" onPress={handleDelete} testID={`CpPackageSessionTemplatesSelectItem-delete-${packageId}`} transparent={false} type="text" $_css13={[localStyles.expandedAction, isNarrowScreen ? styles.marginVerticalSingle : undefined]}>
              <_StyledView6 $_css14={styles.rowAndCenter}>
                <_StyledPrText6 children={translations.buttons.delete} $_css15={localStyles.expandedActionLabel} />
                <PrIcon fill={buttonColors.destructive} icon={IconName.trash} size={iconSizes.small} />
              </_StyledView6>
            </_StyledPrButton2>
          </_StyledView4>
        </_StyledView3>}
    </React.Fragment>;
};
export default CpPackageSessionTemplatesSelectItem;
var _StyledPrPressable = _styled(PrPressable)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledView2 = _styled5(View)`${p => p.$_css5}`;
var _StyledPrText3 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledView3 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrText4 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledView4 = _styled9(View)`${p => p.$_css9}`;
var _StyledPrButton = _styled10(PrButton)`${p => p.$_css10}`;
var _StyledView5 = _styled11(View)`${p => p.$_css11}`;
var _StyledPrText5 = _styled12(PrText)`${p => p.$_css12}`;
var _StyledPrButton2 = _styled13(PrButton)`${p => p.$_css13}`;
var _StyledView6 = _styled14(View)`${p => p.$_css14}`;
var _StyledPrText6 = _styled15(PrText).withConfig({
  displayName: "CpPackageSessionTemplatesSelectItem___StyledPrText6",
  componentId: "sc-6qvk8z-0"
})(["", ""], p => p.$_css15);