import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import * as zod from "zod";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpImdLogo from "components/common/CpImdLogo";
import CpLinkButton from "components/common/CpLinkButton";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import usePersistentStorage from "hooks/usePersistentStorage";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { maxEmailLength, maxPasswordLength } from "services/Constants";
import { css, rnStyles, styles } from "services/Theme";
import { emailValidation } from "services/Validation";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    justify-content: space-between;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  forgotPasswordContainer: css`
    padding-right: ${({
    theme
  }) => theme.spacing.half * 3}px;
    text-align: right;
  `,
  forgotPasswordText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-weight: normal;
  `,
  orContainer: css`
    ${styles.rowAndCenter};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  orSeparator: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    flex: 1;
    height: 1px;
  `,
  orText: css`
    ${styles.uppercase};
    color: ${({
    theme
  }) => theme.textColors.muted};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  textInputWrapper: css`
    margin-top: 0;
  `
};
/**
 * The core functionality for the signin page.
 */
const CpSignin = ({
  onSuccess
}) => {
  const {
    authTokenSignedOut,
    signin
  } = useImdSession();
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    iconSizes,
    name,
    textColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const [formError, setFormError] = useState();
  const navigation = useNavigation();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      email: zod.string().refine(...emailValidation()),
      password: zod.string().min(1)
    }), []))
  });

  // This is dumb but the usePersistentStorage hook is asynchronous, so it will return undefined on first
  // render and then yield the async result. But form default values can only be set once and then
  // reset must be used
  const [lastSigninEmail] = usePersistentStorage("lastSigninEmail");
  useEffect(() => {
    if (lastSigninEmail) {
      form.reset({
        email: lastSigninEmail
      });
    }
  }, [form.reset, lastSigninEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(Routes.home);
  }, [navigation]);
  const navigateToCreateAccount = useCallback(() => navigation.navigate(Routes.create_account, {}), [navigation]);
  const handleSigninError = useCallback(error => {
    setFormError(error);
    trackGoogleEvent({
      action: "Signin request failed",
      name: "signin"
    });
  }, [trackGoogleEvent]);
  const handleSigninSuccess = useCallback(() => {
    onSuccess?.();
    trackGoogleEvent({
      action: "Signin request success",
      name: "signin"
    });
  }, [onSuccess, trackGoogleEvent]);
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    signin(values.email, values.password, {
      onSigninError: handleSigninError,
      onSigninSuccess: handleSigninSuccess
    });
  }), [form, signin, handleSigninError, handleSigninSuccess]);
  const passwordFieldRef = useRef(null);
  return <ScrollView contentContainerStyle={rnStyles.flexSingle}>
      <CpBackgroundHeader childStyle={styles.flexSingle} header={<FormattedMessage id="rGJQtx" defaultMessage="Sign In" />}>
        <_StyledView $_css={[styles.paddingDouble, !isMediumOrSmallerScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledCpImdLogo fill={name === "dark" ? textColors.default : undefined} height={iconSizes.xlarge} $_css2={styles.paddingSingle} />
          {authTokenSignedOut && <_StyledPrText $_css3={styles.formField}>
              <FormattedMessage id="mfG4yb" defaultMessage="You have been signed out for your security. Please sign in again." />
            </_StyledPrText>}

          {/* Sign In Form */}
          <CpForm onSubmit={handleSubmit} {...form}>
            <CpFormTextInput autoCapitalize="none" autoComplete="email" autoCorrect={false} defaultValue={lastSigninEmail} keyboardType="email-address" label={translations.attributes.user.email} maxLength={maxEmailLength} name="email" onSubmitEditing={passwordFieldRef.current?.focus} placeholder={translations.labels.placeholderSigninEmail} returnKeyType="next" textContentType="emailAddress" />
            <_StyledView2 $_css4={localStyles.forgotPasswordContainer}>
              <CpLinkButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
              pageName: formatMessage(translations.screens.forgot_password)
            })} route={Routes.forgot_password} testID="CpSigninForgotPassword">
                <_StyledPrText2 $_css5={localStyles.forgotPasswordText}>
                  <FormattedMessage id="D3ZCCh" defaultMessage="Forgot Password?" />
                </_StyledPrText2>
              </CpLinkButton>
            </_StyledView2>
            <CpFormTextInput autoCapitalize="none" autoComplete="password" autoCorrect={false} label={translations.attributes.user.password} maxLength={maxPasswordLength} name="password" onSubmitEditing={handleSubmit} password={true} placeholder={translations.labels.placeholderSigninPassword} returnKeyType="go" textContentType="password" wrapperStyle={localStyles.textInputWrapper} />
            <_StyledCpError error={formError} $_css6={[styles.formField, styles.errorBox]} />
            <_StyledView3 $_css7={!isMediumOrSmallerScreen ? localStyles.buttonContainer : undefined}>
              <_StyledPrButton busy={form.formState.isSubmitting} disabled={form.formState.isSubmitting} onPress={handleSubmit} testID="CpSigninButton" $_css8={styles.formField}>
                <_StyledPrText3 children={translations.buttons.signin} $_css9={localStyles.buttonText} />
              </_StyledPrButton>
              <_StyledPrButton2 onPress={handleCancel} type="text" $_css10={styles.formField}>
                <_StyledPrText4 children={translations.buttons.cancel} $_css11={localStyles.buttonText} />
              </_StyledPrButton2>
            </_StyledView3>
          </CpForm>

          {/* Create Account Option */}
          <_StyledView4 $_css12={localStyles.orContainer}>
            <_StyledView5 $_css13={localStyles.orSeparator} />
            <_StyledPrText5 $_css14={localStyles.orText}>
              <FormattedMessage id="0OieUL" defaultMessage="Or" />
            </_StyledPrText5>
            <_StyledView6 $_css15={localStyles.orSeparator} />
          </_StyledView4>
          <_StyledPrButton3 onPress={navigateToCreateAccount} testID="CpSigninCreateAccount" type="outline" $_css16={styles.marginVerticalDouble}>
            <_StyledPrText6 children={translations.buttons.createAccount} $_css17={localStyles.buttonText} />
          </_StyledPrButton3>
        </_StyledView>
      </CpBackgroundHeader>
    </ScrollView>;
};
export default CpSignin;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledCpImdLogo = _styled2(CpImdLogo)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledPrText2 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledCpError = _styled6(CpError)`${p => p.$_css6}`;
var _StyledView3 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrButton = _styled8(PrButton)`${p => p.$_css8}`;
var _StyledPrText3 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledPrButton2 = _styled10(PrButton)`${p => p.$_css10}`;
var _StyledPrText4 = _styled11(PrText)`${p => p.$_css11}`;
var _StyledView4 = _styled12(View)`${p => p.$_css12}`;
var _StyledView5 = _styled13(View)`${p => p.$_css13}`;
var _StyledPrText5 = _styled14(PrText)`${p => p.$_css14}`;
var _StyledView6 = _styled15(View)`${p => p.$_css15}`;
var _StyledPrButton3 = _styled16(PrButton)`${p => p.$_css16}`;
var _StyledPrText6 = _styled17(PrText).withConfig({
  displayName: "CpSignin___StyledPrText6",
  componentId: "sc-155r9xt-0"
})(["", ""], p => p.$_css17);