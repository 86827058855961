import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11 } from "styled-components";
var _CpPackageSessionSaveTemplateMutation;
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import * as zod from "zod";
import CpError from "components/common/CpError";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import { FormNecessity } from "components/form/types";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { maxEmailLength } from "services/Constants";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import { getPackageInputGroups } from "services/PatientSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const patientPackageCreateMutation = _CpPackageSessionSaveTemplateMutation !== void 0 ? _CpPackageSessionSaveTemplateMutation : _CpPackageSessionSaveTemplateMutation = require("./__generated__/CpPackageSessionSaveTemplateMutation.graphql");
const localStyles = {
  buttonContainer: css`
    align-items: center;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    /* Overriding line height as without this text is overly spread out on smaller screens */
    line-height: 105%;
  `
};
const localTranslations = defineMessages({
  saveTemplate: {
    id: "jmt5iP",
    defaultMessage: "Save package as template"
  }
});
/**
 * Renders the form for creating a PatientPackage template for future (re)use
 */
const CpPackageSessionSaveTemplate = ({
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    organization,
    user
  } = useImdSession();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageResources
    }
  } = usePatientSessionContext();
  // Packages are only available to users, who must have an organization, but the id may still be undefined... so... that's annoying
  const organizationId = organization?._id ?? "";
  const handleViewTemplates = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageSelectTemplate
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]);

  // Field refs
  const descriptionFieldRef = useRef(null);

  // Mutation and form submit handlers
  const [submitPatientPackage, isSubmittingPatientPackage] = useMutation(patientPackageCreateMutation);
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      description: zod.string().optional(),
      name: zod.string().min(1)
    }), []))
  });
  const [formError, setFormError] = useState();
  const handleSubmit = useMemo(() => {
    return form.handleSubmit(values => {
      // PackageSession savePackageAsTemplate (button click)
      trackGoogleEvent({
        action: "SavedAsTemplate",
        name: "packageSession",
        options: {
          dimensions: {
            [GoogleAnalyticsDimension.organizationId]: organization?._id,
            [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
          }
        }
      });
      const {
        description,
        name
      } = values;
      Log.info("CpPackageSessionSaveTemplate - Creating PatientPackage template");
      // Compile the final selection of resources for the PatientPackage
      const {
        commonTopicsInput,
        topicNodesInput
      } = getPackageInputGroups(packageResources, organizationId);
      const input = {
        commonTopicsInput,
        description,
        name,
        topicNodesInput
      };
      const submit = async () => {
        submitPatientPackage({
          onCompleted: ({
            patientPackageCreate
          }) => {
            Log.info("CpPackageSessionSaveTemplate - PatientSession successfully created: ", {
              packageId: patientPackageCreate?.patientPackage.id
            });
            handleViewTemplates();
          },
          onError: setFormError,
          variables: {
            input
          }
        });
      };
      submit();
    });
  }, [form, handleViewTemplates, organization?._id, organizationId, trackGoogleEvent, user, packageResources, submitPatientPackage]);
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="pr0lpR" defaultMessage="Save Package Template" />
      </_StyledPrText>
      <_StyledPrText2 $_css3={styles.paddingSingle}>
        <FormattedMessage id="Ze6/wG" defaultMessage="Provide a unique name and a short description for this template. You can access it via the {loadOrSavePackages} option from the main packages screen. " values={{
        loadOrSavePackages: <_StyledPrText3 children={translations.buttons.loadOrSavePackages} $_css4={styles.italicText} />
      }} />
      </_StyledPrText2>

      <_StyledView $_css5={styles.paddingHorizontalSingle}>
        {/* Form */}
        <CpForm onSubmit={handleSubmit} {...form}>
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.name)} autoCapitalize="words" autoComplete="off" autoCorrect={false} autoFocus={true} keyboardType="default" label={translations.labels.name} maxLength={maxEmailLength} name="name" onSubmitEditing={descriptionFieldRef.current?.focus} returnKeyType="next" testID="CpPackageSessionSaveTemplate-nameField" textContentType="none" />
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.description)} autoCapitalize="sentences" autoComplete="off" autoCorrect={true} forwardRef={descriptionFieldRef} keyboardType="default" label={translations.labels.description} multiline={true} name="description" necessity={FormNecessity.Optional} numberOfLines={3} onSubmitEditing={handleSubmit} returnKeyType="next" testID="CpPackageSessionSaveTemplate-descriptionField" textContentType="none" />

          <_StyledCpError error={formError} $_css6={styles.paddingVerticalSingle} />

          {/* Buttons */}
          <_StyledView2 $_css7={[localStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
            <_StyledPrButton accessibilityLabel={formatMessage(localTranslations.saveTemplate)} busy={isSubmittingPatientPackage} disabled={isSubmittingPatientPackage} onPress={handleSubmit} testID="CpPackageSessionSaveTemplate-submit" type="raised" $_css8={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
              <_StyledPrText4 children={localTranslations.saveTemplate} $_css9={styles.sessionStyles.buttonText} />
            </_StyledPrButton>
            <_StyledPrButton2 accessibilityLabel={formatMessage(translations.buttons.cancel)} onPress={handleViewTemplates} testID="CpPackageSessionSaveTemplate-cancel" type="text" $_css10={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
              <_StyledPrText5 children={translations.buttons.cancel} $_css11={styles.sessionStyles.buttonText} />
            </_StyledPrButton2>
          </_StyledView2>
        </CpForm>
      </_StyledView>
    </_StyledScrollView>;
};
export default CpPackageSessionSaveTemplate;
var _StyledScrollView = _styled(ScrollView)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledPrText2 = _styled3(PrText)`${p => p.$_css3}`;
var _StyledPrText3 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledView = _styled5(View)`${p => p.$_css5}`;
var _StyledCpError = _styled6(CpError)`${p => p.$_css6}`;
var _StyledView2 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrButton = _styled8(PrButton)`${p => p.$_css8}`;
var _StyledPrText4 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledPrButton2 = _styled10(PrButton)`${p => p.$_css10}`;
var _StyledPrText5 = _styled11(PrText).withConfig({
  displayName: "CpPackageSessionSaveTemplate___StyledPrText5",
  componentId: "sc-1love66-0"
})(["", ""], p => p.$_css11);