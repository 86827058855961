import _styled, { default as _styled2, default as _styled3 } from "styled-components";
import React from "react";
import { ImageBackground, View } from "react-native";
// eslint-disable-next-line no-restricted-imports

import PrText from "components/primitives/PrText";
import { css, styles } from "services/Theme";
const localStyles = {
  background: css`
    background-image: ${({
    theme
  }) => theme.headerBackgroundColor};
    flex: 1;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
  `,
  childrenWrapper: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
  `,
  pageHeader: css`
    ${styles.pageHeading};
    align-self: center;
    color: ${({
    theme
  }) => theme.textColors.heading1};
    padding-horizontal: 0;
    text-align: center;
    /* Quick and dirty way to add contrast to text on background */
    text-shadow: 0 0 10px black;
  `
};
/**
 * Renders the IMD gradient background + SVG icon overlay with a page header
 * Page header styling ensures contrast remains high on stylized background
 */
const CpBackgroundHeader = ({
  childStyle,
  children,
  header,
  style
}) => {
  return <_StyledImageBackground
  // Blank label and role of 'none' essentially amount to something being for presentation purposes only, and should thus be ignored by screen readers
  accessibilityLabel="" accessibilityRole="none" source={{}} style={style} $_css={localStyles.background}>
      <_StyledPrText accessibilityRole="header" children={header} $_css2={localStyles.pageHeader} />
      <_StyledView $_css3={[localStyles.childrenWrapper, childStyle]}>{children}</_StyledView>
    </_StyledImageBackground>;
};
export default CpBackgroundHeader;
var _StyledImageBackground = _styled(ImageBackground)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView = _styled3(View).withConfig({
  displayName: "CpBackgroundHeader___StyledView",
  componentId: "sc-vuir7q-0"
})(["", ""], p => p.$_css3);