import _styled, { default as _styled2 } from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useFragment } from "react-relay";
import CpImageCard from "components/common/CpImageCard";
import { resourceFragment } from "components/topic/CpResourceCommon";
import { CxDrawingTools } from "contexts/CxDrawingTools";
import useAppTheme from "hooks/useAppTheme";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    ${styles.paddingTopDouble};
  `
};
/**
 * Renders a standalone image resource, with the option for being viewed fullscreen.
 */
const CpImageResource = ({
  resource,
  style
}) => {
  const {
    spacing
  } = useAppTheme();
  const data = useFragment(resourceFragment, resource);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const handleLayoutWidth = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    setLayoutWidth(layout.width);
  }, [setLayoutWidth]);
  if (!data.url) return null;
  return <_StyledView onLayout={handleLayoutWidth} style={style} $_css={localStyles.container}>
      <CxDrawingTools>
        <_StyledCpImageCard caption={data.caption} label={getNameVariant(data)} testID="CpImageResource" uri={data.url} $_css2={layoutWidth - spacing.double} />
      </CxDrawingTools>
    </_StyledView>;
};
export default CpImageResource;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledCpImageCard = _styled2(CpImageCard).withConfig({
  displayName: "CpImageResource___StyledCpImageCard",
  componentId: "sc-171ighp-0"
})(["width:", "px;"], p => p.$_css2);