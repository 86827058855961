import _styled from "styled-components";
import React, { createContext, useContext } from "react";
import { Text } from "react-native"; // eslint-disable-line no-restricted-imports

import useAsText from "hooks/useAsText";
import { css } from "services/Theme";

/**
 * The union of all types that can be used as children of the react-native Text component
 */

/**
 * The union of all types that can be used as children of PrText
 */

const defaultTextStyle = css`
  color: ${({
  theme
}) => theme.textColors.default};
  font-family: ${({
  theme
}) => theme.fontFamilies.default};
  font-size: ${({
  theme
}) => theme.fontSize("medium")};
`;
const HasPrTextParentContext = createContext(false);

/**
 * A Text component themed with the default text styles and a wider palette of children it can render.
 */
const PrText = ({
  accessibilityRole = "text",
  children,
  ...textProps
}) => {
  const hasPrTextParent = useContext(HasPrTextParentContext);
  const text = useAsText(children);
  if (hasPrTextParent) {
    return <Text accessibilityRole={accessibilityRole} children={text} {...textProps} />;
  } else {
    return <HasPrTextParentContext.Provider value={true}>
        <_StyledText accessibilityRole={accessibilityRole} children={text} {...textProps} />
      </HasPrTextParentContext.Provider>;
  }
};
export default PrText;
var _StyledText = _styled(Text).withConfig({
  displayName: "PrText___StyledText",
  componentId: "sc-1o6t4h9-0"
})(["", ""], defaultTextStyle);