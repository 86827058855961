import _styled, { default as _styled2, default as _styled3 } from "styled-components";
var _CpImageResourceNodeQuery;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpImageCard from "components/common/CpImageCard";
import CpThumbnailItem from "components/common/CpThumbnailItem";
import CtApiSuspense from "components/containers/CtApiSuspense";
import CpMediaNodeNavigatorLarge from "components/topic/CpMediaNodeNavigatorLarge";
import CpMediaNodeNavigatorSmall from "components/topic/CpMediaNodeNavigatorSmall";
import { CxDrawingTools } from "contexts/CxDrawingTools";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `
};
const resourceNodeQuery = _CpImageResourceNodeQuery !== void 0 ? _CpImageResourceNodeQuery : _CpImageResourceNodeQuery = require("./__generated__/CpImageResourceNodeQuery.graphql");
const ImageResourceNode = ({
  queryReference
}) => {
  const navigation = useNavigation();
  const {
    rootRevisionId,
    selectedRevisionId,
    setDefaultNodeRevisionId
  } = useTopicContext();
  const {
    buttonHeights,
    spacing
  } = useAppTheme();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const [layout, setLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const handleLayout = useCallback(({
    nativeEvent
  }) => {
    setLayout(nativeEvent.layout);
  }, []);
  const resourceNode = usePreloadedQuery(resourceNodeQuery, queryReference).node;
  const image = resourceNode?.resource;
  const cardWidth = isNarrowScreen ? layout.width : layout.width - 2 * buttonHeights.small;
  useEffect(() => {
    if (!selectedRevisionId && resourceNode?.revisionId && setDefaultNodeRevisionId) {
      setDefaultNodeRevisionId(resourceNode.revisionId);
    }
  }, [resourceNode, selectedRevisionId, setDefaultNodeRevisionId]);

  // Callback and item renderer for ribbon items
  const handleSelect = useCallback(revisionId => navigation.navigate(Routes.topic, {
    rootRevisionId,
    selectedRevisionId: revisionId
  }), [navigation, rootRevisionId]);
  const renderRibbonItem = useCallback(({
    item
  }) => {
    const {
      resource,
      revisionId
    } = item;
    return <CpThumbnailItem name={getNameVariant(item) ?? ""} onPressCallback={handleSelect} onPressValue={revisionId} selected={item.revisionId === resourceNode?.revisionId} size="Medium" uri={resource.url} />;
  }, [handleSelect, resourceNode]);
  const ribbonItemKeyExtractor = useCallback(item => {
    return item.revisionId;
  }, []);
  if (!resourceNode || !image?.url) return null;
  const MediaNodeNavigator = isNarrowScreen ? CpMediaNodeNavigatorSmall : CpMediaNodeNavigatorLarge;
  const ancestors = resourceNode.ancestors ?? [];
  const parent = ancestors.length > 1 ? ancestors[ancestors.length - 1] : null;
  const belongsToMediaNode = ["Supporting Diagrams", "Diagrammes de soutien", "Diagramas de apoyo"].includes(getNameVariant(parent) ?? "");
  const resourceNodeName = image ? getNameVariant(image) : undefined;
  const imageStyle = css`
    width: ${cardWidth - spacing.double}px;
  `;
  return <_StyledView onLayout={handleLayout} $_css={localStyles.container}>
      <CpUpdatePageTitle image={image?.url} imageAlt={resourceNodeName} title={resourceNodeName} />
      <CxDrawingTools>
        {belongsToMediaNode ? <MediaNodeNavigator keyExtractor={ribbonItemKeyExtractor} renderRibbonItem={renderRibbonItem} resourceNodeKey={resourceNode}
      // NB: You can't use css prop on this because the component is dynamic and it causes some sort of collision on the styled component
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        flex: 1,
        width: layout.width
      }} type="Diagram">
            <_StyledCpImageCard caption={image.caption} label={resourceNodeName} testID="CpImageResourceNode" uri={image.url} $_css2={imageStyle} />
          </MediaNodeNavigator> : <_StyledCpImageCard2 caption={image.caption} label={resourceNodeName} testID="CpImageResourceNode" uri={image.url} $_css3={imageStyle} />}
      </CxDrawingTools>
    </_StyledView>;
};
/**
 * Renders an image resource node.
 * The image is wrapped in a media carousel to explore all siblings if the node is a child of a media section node.
 * As a result, it fetches the resource node along with its siblings.
 * It is also given a full screen viewing option.
 */
const CpImageResourceNode = ({
  resourceNodeId,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const [imageResourceNodeQueryReference, loadImageResourceNodeQuery, disposeImageResourceNodeQuery] = useQueryLoader(resourceNodeQuery);
  useEffect(() => {
    loadImageResourceNodeQuery({
      id: resourceNodeId,
      locale
    });
    return disposeImageResourceNodeQuery;
  }, [locale, loadImageResourceNodeQuery, disposeImageResourceNodeQuery, resourceNodeId]);
  return <View style={style}>
      <CtApiSuspense>
        {imageResourceNodeQueryReference && <ImageResourceNode queryReference={imageResourceNodeQueryReference} />}
      </CtApiSuspense>
    </View>;
};
export default CpImageResourceNode;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledCpImageCard = _styled2(CpImageCard)`${p => p.$_css2}`;
var _StyledCpImageCard2 = _styled3(CpImageCard).withConfig({
  displayName: "CpImageResourceNode___StyledCpImageCard2",
  componentId: "sc-aaa40-0"
})(["", ""], p => p.$_css3);