import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5 } from "styled-components";
import React from "react";
import { useIntl } from "react-intl";
import { SafeAreaView, ScrollView, View } from "react-native";
import CpError from "components/common/CpError";
import CpImdLogo from "components/common/CpImdLogo";
import { styles } from "services/Theme";
import { logoSize } from "services/Theme/common";
import translations from "translations";

/**
 * Error to show when the application fails on startup, and we want to give the user an option to restart.
 */
const CpAppLoadingError = ({
  clearError
}) => {
  const {
    formatMessage
  } = useIntl();
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <_StyledScrollView $_css2={styles.flexSingle}>
        <_StyledView $_css3={[styles.screen, styles.paddingDouble, styles.center]}>
          <CpImdLogo accessibilityLabel={formatMessage(translations.accessibility.label.imdLogo, {
          region: ""
        })} accessibilityRole="image" height={logoSize} />
          <_StyledView2 $_css4={styles.paddingQuadruple}>
            <_StyledCpError error={translations.errors.appInitialization} onPress={clearError} $_css5={styles.errorBox} />
          </_StyledView2>
        </_StyledView>
      </_StyledScrollView>
    </_StyledSafeAreaView>;
};
export default CpAppLoadingError;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledScrollView = _styled2(ScrollView)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledCpError = _styled5(CpError).withConfig({
  displayName: "CpAppLoadingError___StyledCpError",
  componentId: "sc-z783ns-0"
})(["", ""], p => p.$_css5);