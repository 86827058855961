import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10 } from "styled-components";
var _CpResourceNodeSearchTab_tabData;
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Linking, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import CpPrevNextPagination from "components/common/CpPrevNextPagination";
import CpSearchBar from "components/common/CpSearchBar";
import CpContentSearchItem from "components/content/CpContentSearchItem";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import useRandomSearchPlaceholder from "hooks/useRandomSearchPlaceholder";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlHomeSearch from "query/QlHomeSearch";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { useBackendSupportedLocale, useCurrentAlphabet } from "services/Intl";
import { css, styles } from "services/Theme";
import translations from "translations";
const tabDataFragment = _CpResourceNodeSearchTab_tabData !== void 0 ? _CpResourceNodeSearchTab_tabData : _CpResourceNodeSearchTab_tabData = require("./__generated__/CpResourceNodeSearchTab_tabData.graphql");
const localStyles = {
  letterPickerButton: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    margin: ${({
    theme
  }) => theme.spacing.third}px;
    width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  paragraphContainer: css`
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  paragraphText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  resultText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
    text-align: center;
  `,
  resultsContainer: css`
    align-items: center;
    justify-content: center;
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    margin: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resultsText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `
};
/**
 * Renders a home screen tab that renders the contents of an entire topic tree
 */
const CpResourceNodeSearchTab = ({
  tab
}) => {
  const pageSize = 100;
  const firstPage = useMemo(() => {
    return {
      first: pageSize
    };
  }, [pageSize]);
  const {
    navigate
  } = useNavigation();
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    licensee
  } = useImdSession();
  const tabData = useFragment(tabDataFragment, tab);
  const locale = useBackendSupportedLocale();
  const currentAlphabet = useCurrentAlphabet();
  const currentAlphabetFirstLetter = currentAlphabet?.[0];
  const [activeSearchQuery, setActiveSearchQuery] = useState();
  const [startsWith, setStartsWith] = useState(tabData?.letterPicker ? currentAlphabetFirstLetter : undefined);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const placeholder = tabData?.search?.placeholder;
  const randomPlaceholder = useRandomSearchPlaceholder(isMediumOrSmallerScreen ? 1 : 2);
  const handleSearchQueryUpdate = useCallback(value => {
    setStartsWith(undefined);
    setActiveSearchQuery(value);
    setCurrentPage(firstPage);
  }, [firstPage]);
  const handleStartsWithUpdate = useCallback(value => {
    ref.current?.clear();
    setStartsWith(value);
    setActiveSearchQuery(undefined);
    setCurrentPage(firstPage);
  }, [firstPage]);
  const searchVariables = useMemo(() => {
    if ((activeSearchQuery || startsWith) && licensee?.id) {
      return {
        contentFilters: {
          startsWith: startsWith,
          text: activeSearchQuery
        },
        locale,
        organizationId: licensee.id,
        ...currentPage
      };
    } else {
      return undefined;
    }
  }, [activeSearchQuery, licensee?.id, startsWith, locale, currentPage]);
  const ref = useRef(null);

  // Handles selection of a search result
  const handleSelect = useCallback(contentItem => {
    // Clear the search bar before navigating
    ref.current?.clear();
    if (contentItem.revisionId === contentItem.rootRevisionId) {
      navigate(Routes.topic, {
        rootRevisionId: contentItem.revisionId
      });
    } else {
      navigate(Routes.topic, {
        rootRevisionId: contentItem.rootRevisionId,
        selectedRevisionId: contentItem.revisionId
      });
    }
  }, [navigate]);
  const handleAuxSelect = useCallback(contentItem => {
    // Clear the search bar before navigating
    ref.current?.clear();
    if (contentItem.revisionId === contentItem.rootRevisionId) {
      Linking.openURL(`${location.origin}/${Routes.topic}/${contentItem.revisionId}`);
      navigate(Routes.topic, {
        rootRevisionId: contentItem.revisionId
      });
    } else {
      Linking.openURL(`${location.origin}/${Routes.topic}/${contentItem.rootRevisionId}/${contentItem.revisionId}`);
      navigate(Routes.topic, {
        rootRevisionId: contentItem.rootRevisionId,
        selectedRevisionId: contentItem.revisionId
      });
    }
  }, [navigate]);
  const handleChangeNextPagination = useCallback(pageInfo => {
    if (pageInfo?.hasNextPage) {
      setCurrentPage({
        after: pageInfo?.endCursor,
        first: pageSize
      });
    }
  }, [pageSize]);
  const handleChangePrevPagination = useCallback(pageInfo => {
    if (pageInfo?.hasPreviousPage) {
      setCurrentPage({
        before: pageInfo?.startCursor,
        last: pageSize
      });
    }
  }, []);
  return <>
      {tabData?.tabParagraph && <_StyledView $_css={[localStyles.paragraphContainer, tabData?.tabParagraphContainerStyles]}>
          <_StyledPrText children={tabData.tabParagraph} $_css2={[localStyles.paragraphText, tabData?.tabParagraphStyles]} />
        </_StyledView>}

      {tabData?.letterPicker ? <_StyledView2 $_css3={[styles.rowAndWrap, styles.center, styles.marginHorizontalSingle, styles.paddingTopDouble]}>
          {currentAlphabet?.map(letter => {
        return <_StyledPrButton key={letter} onPress={() => {
          handleStartsWithUpdate(letter);
        }} type={letter === startsWith ? "raised" : "text"} $_css4={localStyles.letterPickerButton}>
                <PrText children={letter.toLocaleUpperCase()} />
              </_StyledPrButton>;
      }, [])}
        </_StyledView2> : null}

      {tabData?.search ? <_StyledView3 $_css5={[styles.paddingVerticalSingle, styles.paddingHorizontalDouble]}>
          <CpSearchBar onQueryChanged={handleSearchQueryUpdate} placeholder={placeholder || placeholder === "" ? placeholder : randomPlaceholder} ref={ref} />
        </_StyledView3> : null}

      {searchVariables ? <QlHomeSearch variables={searchVariables}>
          {searchResponse => {
        if (searchResponse) {
          const content = searchResponse.node?.content;
          const edges = content?.edges ?? [];
          const pageInfo = content?.pageInfo;
          return edges.length === 0 ? <_StyledView4 $_css6={localStyles.resultsContainer}>
                  <_StyledPrText2 children={translations.labels.noResultsFound} $_css7={localStyles.resultsText} />
                </_StyledView4> : <>
                  <View>
                    <_StyledPrText3 children={formatMessage(translations.labels.results, {
                num: content?.totalCount
              })} $_css8={localStyles.resultText} />
                  </View>
                  {edges.map(edge => {
              return edge?.node ? <CpContentSearchItem contentItem={edge.node} key={edge.cursor} onAuxPress={handleAuxSelect} onPress={handleSelect} /> : null;
            })}

                  {pageInfo ? <CpPrevNextPagination onNextPageChange={handleChangeNextPagination} onPrevPageChange={handleChangePrevPagination} pageInfo={pageInfo} /> : undefined}
                </>;
        } else {
          return <PrActivityIndicator />;
        }
      }}
        </QlHomeSearch> : <_StyledView5 $_css9={localStyles.resultsContainer}>
          <_StyledPrText4 children={<FormattedMessage id="4O/8iQ" defaultMessage="Please enter a query to see results." />} $_css10={localStyles.resultsText} />
        </_StyledView5>}
    </>;
};
export default CpResourceNodeSearchTab;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledPrButton = _styled4(PrButton)`${p => p.$_css4}`;
var _StyledView3 = _styled5(View)`${p => p.$_css5}`;
var _StyledView4 = _styled6(View)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrText3 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledView5 = _styled9(View)`${p => p.$_css9}`;
var _StyledPrText4 = _styled10(PrText).withConfig({
  displayName: "CpResourceNodeSearchTab___StyledPrText4",
  componentId: "sc-13fxev7-0"
})(["", ""], p => p.$_css10);