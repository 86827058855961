import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10 } from "styled-components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable/PrPressable";
import PrText from "components/primitives/PrText";
import PrTextInput from "components/primitives/PrTextInput/PrTextInput";
import { css } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  container: css`
    flex-direction: column;
    justify-content: space-between;
  `,
  dropdownItem: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  dropdownMenu: css`
    border-color: ${({
    theme
  }) => theme.brandColors.separator};
    border-top-width: 1px;
    max-height: 250px;
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  icon: css`
    cursor: pointer;
  `,
  overlay: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    left: 0;
    height: 100vh;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${({
    theme
  }) => theme.layers.firstLayer};
  `,
  placeholderText: css`
    color: ${({
    theme
  }) => theme.textColors.textInputPlaceholder};
  `,
  pressable: css`
    min-height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
  `,
  pressableSearchable: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectedValue: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  textInput: css`
    flex: 1;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding: 0;
  `
};
/**
 * Select Component for single option
 */
const PrSelectSingle = ({
  onChangeOption,
  onFocus,
  placeholder,
  searchable = false,
  selectOptions,
  testID,
  value = {
    name: "",
    value: ""
  },
  ...otherProps
}) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(selectOptions);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    setFilteredOptions(selectOptions);
  }, [selectOptions]);
  const textInputRef = useRef(null);
  const filterOptions = useCallback(filterInput => {
    setFilteredOptions(selectOptions.filter(option => option.name.toLocaleLowerCase().includes(filterInput) || option.value.toLocaleLowerCase().includes(filterInput)));
  }, [selectOptions]);
  const handleChangeText = useCallback(text => {
    setSearchInput(text);
    filterOptions(text.toLowerCase());
    setDropDownOpen(true);
  }, [filterOptions]);
  const handleChangeOption = useCallback(option => {
    onChangeOption && onChangeOption(option);
    setDropDownOpen(false);
    const selectedOption = selectOptions.find(selectOption => selectOption.value === option);
    setSearchInput(selectedOption?.name || "");
  }, [onChangeOption, selectOptions]);
  const handleCloseDropdown = useCallback(() => {
    setDropDownOpen(false);
  }, []);
  const handleOpenDropdown = useCallback(() => {
    setDropDownOpen(true);
  }, []);
  const handleToggleDropdown = useCallback(() => {
    setDropDownOpen(!dropDownOpen);
  }, [dropDownOpen]);
  const handleFocusTextInput = useCallback(() => {
    textInputRef?.current?.focus();
  }, []);

  // Focus on input on dropdown open in case it's searchable
  useEffect(() => {
    if (dropDownOpen && searchable) {
      handleFocusTextInput();
    }
  }, [dropDownOpen, handleFocusTextInput, searchable]);
  useEffect(() => {
    onFocus && onFocus(dropDownOpen);
  }, [dropDownOpen, onFocus]);
  const isPlaceholder = useMemo(() => {
    return !(selectOptions && selectOptions.filter(values => values.value === value)[0]?.name);
  }, [selectOptions, value]);
  return <_StyledView {...otherProps} $_css={[localStyles.container]}>
      <_StyledPrPressable focusedBackgroundColor="surface" hoveredBackgroundColor="surface" onPress={handleToggleDropdown} pressedBackgroundColor="surface" testID={testID} $_css2={localStyles.pressable}>
        <_StyledView2 $_css3={localStyles.selectedValue}>
          {searchable && dropDownOpen ? <_StyledPrTextInput onChangeText={handleChangeText} onClear={handleFocusTextInput} onFocus={handleOpenDropdown} placeholder={placeholder} ref={textInputRef} value={searchInput} $_css4={localStyles.textInput} /> : <_StyledPrText children={selectOptions && selectOptions.filter(values => values.value === value)[0]?.name || placeholder} numberOfLines={1} $_css5={[isPlaceholder ? localStyles.placeholderText : undefined, localStyles.textInput]} />}
          <_StyledPrIcon icon={dropDownOpen ? IconName.arrowDropUp : IconName.arrowDropDown} size={iconSizes.small} $_css6={localStyles.icon} />
        </_StyledView2>
      </_StyledPrPressable>
      {dropDownOpen && <>
          <_StyledScrollView $_css7={localStyles.dropdownMenu}>
            {filteredOptions && filteredOptions.map(option => <_StyledPrPressable2 key={option.value} onPress={() => handleChangeOption(option.value)} testID={`SelectOption${option.value}`} $_css8={localStyles.pressable}>
                  <_StyledPrText2 $_css9={localStyles.dropdownItem}>{option.name}</_StyledPrText2>
                </_StyledPrPressable2>)}
          </_StyledScrollView>
          <_StyledPressable onPress={handleCloseDropdown} $_css10={localStyles.overlay} />
        </>}
    </_StyledView>;
};
export default PrSelectSingle;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrPressable = _styled2(PrPressable)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledPrTextInput = _styled4(PrTextInput)`${p => p.$_css4}`;
var _StyledPrText = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrIcon = _styled6(PrIcon)`${p => p.$_css6}`;
var _StyledScrollView = _styled7(ScrollView)`${p => p.$_css7}`;
var _StyledPrPressable2 = _styled8(PrPressable)`${p => p.$_css8}`;
var _StyledPrText2 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledPressable = _styled10(Pressable).withConfig({
  displayName: "PrSelectSingle___StyledPressable",
  componentId: "sc-1uh72gu-0"
})(["", ""], p => p.$_css10);