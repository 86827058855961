var _QlTopicTreeSectionQuery;
import React, { useCallback } from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import SortNodeChildren from "services/SortNodeChildren";
/**
 * Used to fetch the topic node along with its children to render the tabs
 */
const topicTreeTabQuery = _QlTopicTreeSectionQuery !== void 0 ? _QlTopicTreeSectionQuery : _QlTopicTreeSectionQuery = require("./__generated__/QlTopicTreeSectionQuery.graphql");

// The leaves of QlTopicTreeSubsection
// While we can go down further in the tree to greater depths, for now the topic tree returned will h

// The direct children of the topic tree root node

// A record return by the QlTopicTreeSection parser
// This is the "root" node of a topic tree

/**
 * Loads data used to display a topic tree section
 * Returns a tree with a maximum depth of 2.
 * While we could go further down the tree, for now we're restricting the depth to 2 for the UI.
 */
const QlTopicTreeSection = ({
  children,
  variables
}) => {
  const processResponse = useCallback(response => {
    if (!response?.topicNode) return undefined;
    const root = {
      children: response.topicNode.children.map(rootChild => {
        const currentNode = {
          children: rootChild.children.map(leafData => {
            // Create a child of the topic root node
            return {
              icon: leafData.icon,
              id: leafData.id,
              isLeaf: leafData.isLeaf,
              isLocked: leafData.isLocked,
              isProfessionalTool: leafData.isProfessionalTool,
              names: leafData.names,
              rank: leafData.rank,
              resourceNodeCompletion: leafData.resourceNodeCompletion,
              resourceNodeEmbedSnippet: leafData.resourceNode?.defaultResourceNode?.resource?.embedSnippet ?? null,
              resourceNodeRevisionId: leafData.resourceNodeRevisionId,
              revisionId: leafData.revisionId,
              treeId: leafData.treeId
            };
          }),
          icon: rootChild.icon,
          isLeaf: rootChild.isLeaf,
          isLocked: rootChild.isLocked,
          isProfessionalTool: rootChild.isProfessionalTool,
          names: rootChild.names,
          pinSelfToBottom: rootChild.pinSelfToBottom,
          pinSelfToTop: rootChild.pinSelfToTop,
          rank: rootChild.rank,
          resourceNodeEmbedSnippet: rootChild.resourceNode?.defaultResourceNode?.resource?.embedSnippet ?? null,
          resourceNodeRevisionId: rootChild.resourceNodeRevisionId ?? null,
          revisionId: rootChild.revisionId,
          shouldSortChildrenByRank: rootChild.shouldSortChildrenByRank,
          treeId: rootChild.treeId ?? null
        };
        SortNodeChildren(currentNode.children, currentNode.shouldSortChildrenByRank);
        return currentNode;
      }),
      revisionId: response.topicNode.revisionId,
      shouldSortChildrenByRank: response.topicNode.shouldSortChildrenByRank,
      treeId: response.topicNode.treeId
    };
    SortNodeChildren(root.children, root.shouldSortChildrenByRank);
    return root;
  }, []);
  return <CtGraphqlQuery process={processResponse} query={topicTreeTabQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};
export default QlTopicTreeSection;