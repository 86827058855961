import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10 } from "styled-components";
import React, { useCallback, useMemo } from "react";
import { Platform, View } from "react-native";
import CpHeaderActions from "components/app/header/CpHeaderActions";
import CpHeaderLogo from "components/app/header/CpHeaderLogo";
import CpHomeButton from "components/app/header/CpHomeButton";
import CpContentSearchPicker from "components/content/CpContentSearchPicker";
import useActiveNavigationKey from "hooks/useActiveNavigationKey";
import useAppTheme from "hooks/useAppTheme";
import { useHideRules } from "hooks/useHideRules";
import useImdSession from "hooks/useImdSession";
import useRandomSearchPlaceholder from "hooks/useRandomSearchPlaceholder";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
const localStyles = {
  logoContainer: css`
    align-items: center;
  `
};
/**
 * The navigation header at the top of each screen (public/provider apps).
 */
const CpPublicNavHeader = ({
  route
}) => {
  const {
    appConfig
  } = useImdSession();
  const {
    additionalHeaderStyles
  } = useAppTheme();
  const {
    isMediumOrSmallerScreen,
    isNarrowScreen
  } = useScreenLayout();

  // in case we have a custom app header
  const customHeader = appConfig?.appHeader;

  // React-navigation is a horrible library, ane everyone invovled in it should feel bad
  const navKey = useActiveNavigationKey();
  const shouldHideSearchBar = useHideRules({
    rules: customHeader?.search?.hideRules
  });
  const hideSearchBar = customHeader?.search?.hideRules ? shouldHideSearchBar : route.name !== Routes.home;
  const shouldHideHeader = useHideRules({
    rules: appConfig?.appHeader?.hideRules
  });
  const searchBarStyles = useMemo(() => {
    return {
      flex: 1,
      maxWidth: "unset"
    };
  }, []);
  const placeholder = customHeader?.search?.placeholder;
  const randomPlaceholder = useRandomSearchPlaceholder(isMediumOrSmallerScreen ? 1 : 2);
  const placeholderFinal = placeholder || placeholder === "" ? placeholder : randomPlaceholder;
  const renderSection = useCallback((sectionType, position) => {
    switch (sectionType) {
      case "LOGO":
        return <_StyledView $_css={[localStyles.logoContainer, styles.row, isNarrowScreen ? undefined : styles.flexSingle]}>
              <CpHeaderLogo logoAction={customHeader?.logoAction} position={position} />
              <CpHomeButton />
            </_StyledView>;
      case "MENU":
        return <CpHeaderActions position={position} />;
      case "SEARCH_BAR":
        return !isNarrowScreen && !hideSearchBar ? <_StyledCpContentSearchPicker placeholder={placeholderFinal} style={searchBarStyles} textInputStyle={styles.navHeaderStyles.secondarySearchTextInput} $_css2={styles.navHeaderStyles.row1SearchInput} /> :
        // Still want the searchbar space to be occupied (not on mobile though)
        !isNarrowScreen && <_StyledView2 $_css3={styles.flexSingle} />;
      default:
        return null;
    }
  }, [customHeader?.logoAction, isNarrowScreen, placeholderFinal, searchBarStyles, hideSearchBar]);
  const renderHeaderSections = useMemo(() => {
    const defaultHeaderSections = {
      left: <_StyledView3 $_css4={[styles.flexSingle, styles.row]}>
          <CpHeaderLogo logoAction={customHeader?.logoAction} />
          <CpHomeButton />
        </_StyledView3>,
      middle: !isNarrowScreen && !hideSearchBar ? <_StyledCpContentSearchPicker2 placeholder={placeholderFinal} testID="CpPublicNavHeader-search" textInputStyle={styles.navHeaderStyles.secondarySearchTextInput} $_css5={styles.navHeaderStyles.row1SearchInput} /> : <_StyledView4 $_css6={styles.flexSingle} />,
      right: <CpHeaderActions />
    };
    if (!customHeader) {
      return defaultHeaderSections;
    }
    const {
      sections
    } = customHeader;
    if (!sections) {
      return defaultHeaderSections;
    }
    return {
      left: renderSection(sections.left, "LEFT"),
      middle: renderSection(sections.middle, "MIDDLE"),
      right: renderSection(sections.right, "RIGHT")
    };
  }, [customHeader, isNarrowScreen, placeholderFinal, renderSection, hideSearchBar]);
  if (shouldHideHeader) {
    return null;
  } else if (Platform.OS === "web" && navKey !== route.key) {
    // This if statement prevents multiple copies of headers from being rendered in float mode when you navigate between pages on web
    return null;
  } else {
    return <_StyledView5 testID="CpPublicNavHeader" $_css7={[styles.navHeaderStyles.container, additionalHeaderStyles]}>
        <_StyledView6 $_css8={styles.navHeaderStyles.row1Container}>
          {renderHeaderSections?.left}
          {renderHeaderSections?.middle}
          {renderHeaderSections?.right}
        </_StyledView6>

        {!hideSearchBar && isNarrowScreen && <_StyledView7 $_css9={styles.navHeaderStyles.row2Container}>
            <_StyledCpContentSearchPicker3 placeholder={placeholderFinal} testID="CpPublicNavHeader-search" textInputStyle={styles.navHeaderStyles.secondarySearchTextInput} $_css10={styles.navHeaderStyles.row2SearchInput} />
          </_StyledView7>}
      </_StyledView5>;
  }
};
export default CpPublicNavHeader;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledCpContentSearchPicker = _styled2(CpContentSearchPicker)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledView3 = _styled4(View)`${p => p.$_css4}`;
var _StyledCpContentSearchPicker2 = _styled5(CpContentSearchPicker)`${p => p.$_css5}`;
var _StyledView4 = _styled6(View)`${p => p.$_css6}`;
var _StyledView5 = _styled7(View)`${p => p.$_css7}`;
var _StyledView6 = _styled8(View)`${p => p.$_css8}`;
var _StyledView7 = _styled9(View)`${p => p.$_css9}`;
var _StyledCpContentSearchPicker3 = _styled10(CpContentSearchPicker).withConfig({
  displayName: "CpPublicNavHeader___StyledCpContentSearchPicker3",
  componentId: "sc-1ctkwor-0"
})(["", ""], p => p.$_css10);