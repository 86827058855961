import _styled, { default as _styled2, default as _styled3 } from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { resourceNodeTypes } from "translations/enums";

// Common elements for both CpPersonalShortcuts && CpOrganizationShortcuts

export const CpShortcutSectionStyles = {
  contentContainer: css`
    ${styles.screen};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  mobileContainer: css`
    width: 100%;
  `,
  noticeContainer: css`
    border: solid ${({
    theme
  }) => theme.brandColors.separator} 1px;
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  sectionHeading: css`
    ${styles.textHeader2};
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-bottom: 0;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  tilesContainer: css`
    ${styles.rowAndWrap};
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * Compile shortcut sections - header based on the ResourceNode Type and an array of shortcuts
 */
export const buildShortcutSections = (groupedShortcuts, isNarrowScreen) => {
  const sectionsToReturn = [];
  Object.entries(groupedShortcuts).forEach(([shortcutType, shortcuts]) => {
    let shortcutHeader;
    const hasResourceNodeType = Boolean(Object.keys(resourceNodeTypes).find(type => type === shortcutType));
    if (!hasResourceNodeType) {
      Log.warn("Shortcuts - resourceNode type translation not found for: ", {
        shortcutType
      });
      shortcutHeader = shortcutType;
    } else {
      shortcutHeader = <FormattedMessage {...resourceNodeTypes[shortcutType]} />;
    }
    sectionsToReturn.push(<_StyledView key={shortcutType} $_css={styles.paddingVerticalDouble}>
        <_StyledPrText $_css2={CpShortcutSectionStyles.sectionHeading}>{shortcutHeader}</_StyledPrText>
        <_StyledView2 $_css3={isNarrowScreen ? CpShortcutSectionStyles.mobileContainer : CpShortcutSectionStyles.tilesContainer}>
          {shortcuts}
        </_StyledView2>
      </_StyledView>);
  });
  return sectionsToReturn;
};
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View).withConfig({
  displayName: "Shortcuts___StyledView2",
  componentId: "sc-qrcuol-0"
})(["", ""], p => p.$_css3);