import _styled, { default as _styled2, default as _styled3 } from "styled-components";
var _CpVideoResourceNodeQuery;
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpThumbnailItem from "components/common/CpThumbnailItem";
import CpVideoCard from "components/common/CpVideoCard";
import CtApiSuspense from "components/containers/CtApiSuspense";
import { IconName } from "components/primitives/PrIcon";
import CpMediaNodeNavigatorLarge from "components/topic/CpMediaNodeNavigatorLarge";
import CpMediaNodeNavigatorSmall from "components/topic/CpMediaNodeNavigatorSmall";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import { css, rnStyles } from "services/Theme";
const resourceNodeQuery = _CpVideoResourceNodeQuery !== void 0 ? _CpVideoResourceNodeQuery : _CpVideoResourceNodeQuery = require("./__generated__/CpVideoResourceNodeQuery.graphql");
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `
};
const VideoResourceNode = ({
  queryReference
}) => {
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    buttonHeights,
    spacing
  } = useAppTheme();
  const [layout, setLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const handleLayout = useCallback(({
    nativeEvent
  }) => {
    setLayout(nativeEvent.layout);
  }, []);
  const resourceNode = usePreloadedQuery(resourceNodeQuery, queryReference).node;
  const video = resourceNode?.resource;
  const cardWidth = isNarrowScreen ? layout.width : layout.width - 2 * buttonHeights.small;
  const videoCardStyle = css`
    width: ${cardWidth - spacing.double}px;
  `;

  // Callback and item renderer for ribbon items
  const handleSelect = useCallback(selectedRevisionId => navigation.navigate(Routes.topic, {
    rootRevisionId,
    selectedRevisionId
  }), [navigation, rootRevisionId]);
  const renderRibbonItem = useCallback(({
    item
  }) => {
    const {
      revisionId
    } = item;
    return <CpThumbnailItem icon={IconName.video} name={getNameVariant(item) ?? ""} onPressCallback={handleSelect} onPressValue={revisionId} selected={item.revisionId === resourceNode?.revisionId} size="Medium" />;
  }, [handleSelect, resourceNode]);
  const ribbonItemKeyExtractor = useCallback(item => {
    return item.revisionId;
  }, []);
  if (!resourceNode || !video?.embedMarkup) return null;
  const MediaNodeNavigator = isNarrowScreen ? CpMediaNodeNavigatorSmall : CpMediaNodeNavigatorLarge;
  const hasMediaSiblings = resourceNode.siblings?.find(siblingNode => siblingNode?.resource?.type === "Resource::Video");
  const resourceNodeName = video ? getNameVariant(video) : undefined;
  return <_StyledView onLayout={handleLayout} $_css={localStyles.container}>
      <CpUpdatePageTitle pageType="video.other" title={resourceNodeName || ""} />

      {hasMediaSiblings ? <MediaNodeNavigator keyExtractor={ribbonItemKeyExtractor} renderRibbonItem={renderRibbonItem} resourceNodeKey={resourceNode}
    // NB: You can't use css prop on this because the component is dynamic and it causes some sort of collision on the styled component
    style={rnStyles.flexSingle} type="Video">
          <_StyledCpVideoCard embedMarkup={video?.embedMarkup} label={resourceNodeName} testID="CpVideoResourceNode" $_css2={videoCardStyle} />
        </MediaNodeNavigator> : <_StyledCpVideoCard2 aspectRatio={video?.aspectRatio} embedMarkup={video?.embedMarkup} label={resourceNodeName} testID="CpVideoResourceNode" $_css3={videoCardStyle} />}
    </_StyledView>;
};
/**
 * Renders a Video resource, along with different options for moving between sibling videos depending on screen size
 */
const CpVideoResourceNode = ({
  resourceNodeId,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const [videoResourceNodeQueryReference, loadVideoResourceNodeQuery, disposeVideoResourceNodeQuery] = useQueryLoader(resourceNodeQuery);
  useEffect(() => {
    loadVideoResourceNodeQuery({
      id: resourceNodeId,
      locale
    });
    return disposeVideoResourceNodeQuery;
  }, [disposeVideoResourceNodeQuery, loadVideoResourceNodeQuery, locale, resourceNodeId]);
  return <View style={style}>
      <CtApiSuspense>
        {videoResourceNodeQueryReference && <VideoResourceNode queryReference={videoResourceNodeQueryReference} />}
      </CtApiSuspense>
    </View>;
};
export default CpVideoResourceNode;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledCpVideoCard = _styled2(CpVideoCard)`${p => p.$_css2}`;
var _StyledCpVideoCard2 = _styled3(CpVideoCard).withConfig({
  displayName: "CpVideoResourceNode___StyledCpVideoCard2",
  componentId: "sc-140mina-0"
})(["", ""], p => p.$_css3);