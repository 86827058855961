import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14, default as _styled15, default as _styled16, default as _styled17, default as _styled18, default as _styled19, default as _styled20, default as _styled21, default as _styled22, default as _styled23, default as _styled24 } from "styled-components";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpSelectMembership from "components/organization/CpSelectMembership";
import CpSelectSubOrganization from "components/organization/CpSelectSubOrganization";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppBackend from "hooks/useAppBackend";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useNavigationRedirect from "hooks/useNavigationRedirect";
import usePersistentStorage from "hooks/usePersistentStorage";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlSelectOrganization from "query/QlSelectOrganization";
import Log from "services/Log";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  bottomMargin: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  breadcrumbButton: css`
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  breadcrumbButtonIcon: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.quarter}px;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  breadcrumbButtonInnerContainer: css`
    align-items: center;
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  breadcrumbButtonText: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: normal;
  `,
  breadcrumbContainer: css`
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    box-shadow: 0px 1px 3px ${({
    theme
  }) => theme.brandColors.primary};
    flex-direction: column;
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  breadcrumbDisabled: css`
    color: ${({
    theme
  }) => theme.textColors.default};
  `,
  breadcrumbOptions: css`
    box-decoration-break: clone;
    flex-direction: row;
    flex-wrap: wrap;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  breadcrumbRoot: css`
    margin-left: 0;
  `,
  breadcrumbText: css`
    font-weight: bold;
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  breadcrumbTitle: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonLeft: css`
    flex: 1;
    margin-right: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  buttonRight: css`
    flex: 1;
    margin-left: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    line-height: 110%;
    text-align: center;
  `,
  container: css`
    min-height: 90vh;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  header: css`
    ${styles.textHeader1};
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  instructions: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resetSearch: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    width: 100%;
  `
};

/**
 * This component presents memberships and organizations to the user, so that they can choose an organization to use in context.
 * Each visit to this page will have no knowledge of any currently selected memberships/organizations
 */
const CpSelectOrganization = ({
  headerMessage,
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const navigation = useNavigation();
  const {
    user
  } = useImdSession();
  const {
    persistentHeaders: {
      organizationId
    }
  } = useAppBackend();
  const [, updateCurrentOrganizationId] = usePersistentStorage("organizationId");
  const [, updateCurrentMembershipId] = usePersistentStorage("membershipId");
  const {
    pendingUrl,
    setPendingUrl
  } = useNavigationRedirect();
  const linkTo = useLinkTo();
  const {
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors,
    iconSizes,
    textColors
  } = useAppTheme();

  // Layout and Scroll handling
  const scrollRef = useRef(null);
  const [subOrgViewLayout, setSubOrgViewLayout] = useState();
  const handleSubOrgViewLayout = useCallback(event => {
    setSubOrgViewLayout(event.nativeEvent.layout);
  }, []);

  // Selected memberships will be held in local state as we don't actually want to change them until the process is complete.
  const [pendingState, setPendingState] = useState({});
  const [organizationHistory, setOrganizationHistory] = useState([]);
  const {
    pendingMembership,
    pendingOrganization
  } = pendingState;

  // Navigation callbacks
  const handleCancel = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigation.navigate(Routes.home);
  }, [navigation]);

  // Selection callbacks
  const handleSelectMembership = useCallback(membership => {
    setPendingState({
      pendingMembership: membership
    });

    // Scroll to next section to guide user along flow
    scrollRef?.current?.scrollTo({
      animated: true,
      y: subOrgViewLayout?.y
    });

    // Checks if the org is already on the org list, if it is just return the breadcrumb to that org
    if (membership.organization._id === pendingMembership?.organization._id) {
      setOrganizationHistory([]);
    }
    const isOrgPresentIndex = organizationHistory.findIndex(org => {
      return org._id === membership.organization._id;
    });
    if (isOrgPresentIndex > -1) {
      setOrganizationHistory(lastHistory => lastHistory.slice(0, isOrgPresentIndex + 1));
      return;
    }
  }, [organizationHistory, pendingMembership?.organization._id, subOrgViewLayout?.y]);
  const handleSelectOrganization = useCallback((selectedOrganization, index = -1) => {
    setPendingState(lastPendingState => ({
      ...lastPendingState,
      pendingOrganization: selectedOrganization
    }));
    if (index >= 0) {
      setOrganizationHistory(lastOrganizationHistory => lastOrganizationHistory.slice(0, index + 1));
    } else {
      setOrganizationHistory(lastOrgHistoryState => [...lastOrgHistoryState, selectedOrganization]);
    }
  }, []);
  const handleClearOrganization = useCallback(() => {
    setPendingState(lastPendingState => ({
      pendingMembership: lastPendingState.pendingMembership
    }));
    setOrganizationHistory([]);
  }, []);

  // Used to confirm the default membership organizatino
  const handleConfirmMembership = useCallback(() => {
    const confirmedUserSettings = {
      membershipId: pendingMembership?._id,
      organizationId: pendingMembership?.organization._id
    };
    Log.info("CpSelectOrganization - finalizing organization selection: ", confirmedUserSettings);
    updateCurrentMembershipId(pendingMembership?._id);
    updateCurrentOrganizationId(pendingMembership?.organization._id);
    if (pendingUrl) {
      linkTo(`/${pendingUrl}`);
      setPendingUrl(undefined);
    } else {
      navigation.navigate(Routes.home);
    }
  }, [pendingMembership?._id, pendingMembership?.organization._id, updateCurrentMembershipId, updateCurrentOrganizationId, pendingUrl, linkTo, setPendingUrl, navigation]);

  // Used to confirm the current sub-organization
  const handleConfirmOrganization = useCallback(() => {
    const confirmedUserSettings = {
      membershipId: pendingMembership?._id,
      organizationId: pendingOrganization?._id
    };
    Log.info("CpSelectOrganization - finalizing organization selection: ", confirmedUserSettings);
    updateCurrentMembershipId(pendingMembership?._id);
    updateCurrentOrganizationId(pendingOrganization?._id);
    navigation.navigate(Routes.home);
  }, [navigation, pendingMembership?._id, pendingOrganization?._id, updateCurrentMembershipId, updateCurrentOrganizationId]);

  // Preventing multiple rerenders as well as ensuring memberships load
  const userMembershipsVariables = useMemo(() => ({
    id: user?.id || ""
  }), [user?.id]);
  return <ScrollView contentContainerStyle={rnStyles.flexSingle} ref={scrollRef} style={style}>
      <CpBackgroundHeader header={headerMessage}>
        <_StyledView $_css={[localStyles.container, isWideScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledPrText $_css2={localStyles.instructions}>
            <FormattedMessage id="96QjrX" defaultMessage="Your IMD experience will change based on the membership you choose." />
          </_StyledPrText>

          {user && <QlSelectOrganization variables={userMembershipsVariables}>
              {orgPickerResponse => orgPickerResponse?.node?.memberships ? <CpSelectMembership currentMembership={pendingMembership} memberships={orgPickerResponse.node.memberships} onSelect={handleSelectMembership} /> : <PrText>
                    <FormattedMessage id="qMfivs" defaultMessage="There was an issue loading your memberships. Please reload and try again. If the issue persists please contact support@imdhealth.com" />
                  </PrText>}
            </QlSelectOrganization>}
          <View onLayout={handleSubOrgViewLayout}>
            {pendingMembership && <>
                {/* Give user option to select default membership organization without having to select a subOrg */}
                <_StyledPrButton onPress={handleConfirmMembership} testID="CpSelectOrganization-confirmMembership" $_css3={styles.marginBottomSingle}>
                  <_StyledPrText2 numberOfLines={2} $_css4={localStyles.buttonText}>
                    {formatMessage(translations.buttons.continueAs, {
                  name: pendingMembership.organization.name
                })}
                  </_StyledPrText2>
                </_StyledPrButton>

                <_StyledPrText3 $_css5={localStyles.header}>
                  <FormattedMessage id="Xq/PBr" defaultMessage="Or Select a Different Clinic" />
                </_StyledPrText3>
                <_StyledPrText4 $_css6={localStyles.bottomMargin}>
                  <FormattedMessage id="2IjsIt" defaultMessage="Selecting a clinic may further change your IMD experience." />
                </_StyledPrText4>
                <_StyledView2 $_css7={localStyles.breadcrumbContainer}>
                  <_StyledPrText5 $_css8={localStyles.breadcrumbTitle}>
                    <FormattedMessage id="Md93Ln" defaultMessage="Membership hierarchy" />
                  </_StyledPrText5>
                  <_StyledView3 $_css9={localStyles.breadcrumbOptions}>
                    <_StyledPrButton2 onPress={handleClearOrganization} type="text" $_css10={[localStyles.breadcrumbButton, localStyles.breadcrumbRoot]}>
                      <_StyledView4 $_css11={localStyles.breadcrumbButtonInnerContainer}>
                        <_StyledPrIcon fill={buttonColors.primary} icon={IconName.organization} size={iconSizes.small} $_css12={localStyles.breadcrumbButtonIcon} />
                        <_StyledPrText6 children={pendingMembership.organization.name} $_css13={localStyles.breadcrumbText} />
                      </_StyledView4>
                    </_StyledPrButton2>
                    {organizationHistory.map((org, index) => <_StyledPrButton3 disabled={index === organizationHistory.length - 1} key={org._id} onPress={() => handleSelectOrganization(org, index)} type="text" $_css14={localStyles.breadcrumbButton}>
                        <_StyledView5 $_css15={localStyles.breadcrumbButtonInnerContainer}>
                          <_StyledPrIcon2 fill={index === organizationHistory.length - 1 ? textColors.default : buttonColors.primary} icon={IconName.arrowRight} size={iconSizes.xsmall} $_css16={localStyles.breadcrumbButtonIcon} />
                          <_StyledPrText7 children={org.name} $_css17={[localStyles.breadcrumbButtonText, index === organizationHistory.length - 1 ? localStyles.breadcrumbDisabled : undefined]} />
                        </_StyledView5>
                      </_StyledPrButton3>)}
                  </_StyledView3>
                </_StyledView2>
                {pendingOrganization && <_StyledView6 $_css18={[styles.marginVerticalSingle, !isNarrowScreen ? styles.row : undefined]}>
                    <_StyledPrButton4 onPress={handleConfirmOrganization} testID="CpSelectOrganization-confirmOrganization" $_css19={isNarrowScreen ? styles.fullWidth : localStyles.buttonLeft}>
                      <_StyledPrText8 numberOfLines={2} $_css20={localStyles.buttonText}>
                        {formatMessage(translations.buttons.continueAs, {
                    name: pendingOrganization.name
                  })}
                      </_StyledPrText8>
                    </_StyledPrButton4>
                    <_StyledPrButton5 onPress={handleClearOrganization} testID="CpSelectOrganization-clearOrganization" type="text" $_css21={isNarrowScreen ? localStyles.resetSearch : localStyles.buttonRight}>
                      <_StyledPrText9 numberOfLines={2} $_css22={localStyles.buttonText}>
                        <FormattedMessage id="FOtc4g" defaultMessage="Clear selection" />
                      </_StyledPrText9>
                    </_StyledPrButton5>
                  </_StyledView6>}

                {/* Give the user the option to select a subOrg */}

                <CpSelectSubOrganization currentOrganizationId={pendingOrganization?.id || pendingMembership.organization.id} onSelect={handleSelectOrganization} organizationSelected={!!pendingOrganization} />
              </>}
          </View>

          {user && organizationId && <_StyledPrButton6 disabled={user && !organizationId} onPress={handleCancel} testID="CpSelectOrganization-back" type={user ? "outline" : "text"} $_css23={styles.marginVerticalSingle}>
              <_StyledPrText10 children={translations.buttons.previous} $_css24={localStyles.buttonText} />
            </_StyledPrButton6>}
        </_StyledView>
      </CpBackgroundHeader>
    </ScrollView>;
};
export default CpSelectOrganization;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledPrButton = _styled3(PrButton)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrText4 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledView2 = _styled7(View)`${p => p.$_css7}`;
var _StyledPrText5 = _styled8(PrText)`${p => p.$_css8}`;
var _StyledView3 = _styled9(View)`${p => p.$_css9}`;
var _StyledPrButton2 = _styled10(PrButton)`${p => p.$_css10}`;
var _StyledView4 = _styled11(View)`${p => p.$_css11}`;
var _StyledPrIcon = _styled12(PrIcon)`${p => p.$_css12}`;
var _StyledPrText6 = _styled13(PrText)`${p => p.$_css13}`;
var _StyledPrButton3 = _styled14(PrButton)`${p => p.$_css14}`;
var _StyledView5 = _styled15(View)`${p => p.$_css15}`;
var _StyledPrIcon2 = _styled16(PrIcon)`${p => p.$_css16}`;
var _StyledPrText7 = _styled17(PrText)`${p => p.$_css17}`;
var _StyledView6 = _styled18(View)`${p => p.$_css18}`;
var _StyledPrButton4 = _styled19(PrButton)`${p => p.$_css19}`;
var _StyledPrText8 = _styled20(PrText)`${p => p.$_css20}`;
var _StyledPrButton5 = _styled21(PrButton)`${p => p.$_css21}`;
var _StyledPrText9 = _styled22(PrText)`${p => p.$_css22}`;
var _StyledPrButton6 = _styled23(PrButton)`${p => p.$_css23}`;
var _StyledPrText10 = _styled24(PrText).withConfig({
  displayName: "CpSelectOrganization___StyledPrText10",
  componentId: "sc-18w9uoz-0"
})(["", ""], p => p.$_css24);