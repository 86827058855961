import _styled, { default as _styled2 } from "styled-components";
import React, { useContext } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpFormSelect from "components/form/CpFormSelect";
import CpFormTextInput from "components/form/CpFormTextInput";
import { FormNecessity } from "components/form/types";
import PrText from "components/primitives/PrText";
import { I18nContext } from "contexts/CxI18n";
import useAppRegion from "hooks/useAppRegion";
import { useMapOptionsTranslations } from "services/Intl";
import { css } from "services/Theme";
import translations from "translations";
const localTranslations = defineMessages({
  clinicName: {
    id: "thkP/U",
    defaultMessage: "Main Street Clinic"
  },
  enterLicenseNumber: {
    id: "KdaiTJ",
    defaultMessage: "Enter License Number"
  },
  licenseNumber: {
    id: "5x9kJd",
    defaultMessage: "License number"
  },
  practicingClinic: {
    id: "c1ANoy",
    defaultMessage: "Practicing clinic"
  },
  practicingTerritory: {
    id: "szkD6s",
    defaultMessage: "Practicing {territory}"
  },
  practitionerType: {
    id: "RmYA+2",
    defaultMessage: "Practitioner type"
  },
  specialties: {
    id: "i7lR2N",
    defaultMessage: "Specialties"
  }
});
const localStyles = {
  specialtiesBottom: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  specialtiesBottomText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  textInputWrapper: css`
    margin-bottom: 0;
  `
};
/**
 * The Practitioner Profile Form
 */
const CpPractitionerProfileForm = ({
  readonly
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    currentRegion
  } = useAppRegion();
  const specialties = useMapOptionsTranslations("specialties");
  const types = useMapOptionsTranslations("practitionerTypes");
  const {
    countryRegions
  } = useContext(I18nContext);
  const translatedRegions = countryRegions?.countryRegions;
  return <>
      <CpFormTextInput accessibilityLabel={formatMessage(localTranslations.practicingClinic)} label={formatMessage(localTranslations.licenseNumber)} name="externalId" placeholder={formatMessage(localTranslations.enterLicenseNumber)} readonly={readonly} testID="CpPractitionerProfileFormExternalId" wrapperStyle={localStyles.textInputWrapper} />
      <CpFormSelect label={localTranslations.practitionerType} name="type" order={1} placeholder={formatMessage(translations.labels.selectOne)} readonly={readonly} searchable selectOptions={types} testID="CpPractitionerProfileFormPractitionerType" />
      <CpFormSelect label={formatMessage(localTranslations.practicingTerritory, {
      territory: currentRegion === "us" ? formatMessage(translations.labels.state).toLowerCase() : formatMessage(translations.labels.province).toLowerCase()
    })} name="licenseSubdivisionCode" order={2} placeholder={formatMessage(translations.labels.selectOne)} readonly={readonly} searchable selectOptions={translatedRegions} testID="CpPractitionerProfileFormPractitionerProvince" />
      <CpFormTextInput accessibilityLabel={formatMessage(localTranslations.practicingClinic)} label={localTranslations.practicingClinic} name="signupOrganizationName" placeholder={formatMessage(translations.labels.examplePlaceholder, {
      name: formatMessage(localTranslations.clinicName)
    })} readonly={readonly} testID="CpPractitionerProfileFormOrganizationName" wrapperStyle={localStyles.textInputWrapper} />
      <CpFormSelect isMulti label={localTranslations.specialties} name="specialties" necessity={FormNecessity.Optional} order={3} placeholder={formatMessage(translations.labels.none)} readonly={readonly} searchable selectOptions={specialties} testID="CpPractitionerProfileFormPractitionerSpecialties" />
      <_StyledView $_css={localStyles.specialtiesBottom}>
        <_StyledPrText $_css2={localStyles.specialtiesBottomText}>
          <FormattedMessage id="J6q5dx" defaultMessage="We can customize your IMD experience based on your specialities." />
        </_StyledPrText>
      </_StyledView>
    </>;
};
export default CpPractitionerProfileForm;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText).withConfig({
  displayName: "CpPractitionerProfileForm___StyledPrText",
  componentId: "sc-59o0hh-0"
})(["", ""], p => p.$_css2);