import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5 } from "styled-components";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import CpSessionReviewSelectItem from "components/patientSession/CpSessionReviewSelectItem";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { getNameVariant } from "services/Graphql";
import Log from "services/Log";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * Renders lists of resources grouped by their parent topic for selection by the user to include for sending as a PatientSession.
 */
const CpPackageSessionReviewSelect = ({
  data
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageResources
    }
  } = usePatientSessionContext();

  // Reduce each section and its children to a single group keyed by the parent label and topicTreeId (for topicNode sections)
  const groupedPackageSessionResources = useMemo(() => {
    const localGroups = [];

    // For any commonTopic nodes, map through and build a GroupedPackageSessionResource
    const localCommonTopicResources = data.commonTopics.map(resource => {
      const {
        icon,
        resourceNodeRevisionId
      } = resource;
      const packageResource = packageResources.find(currentPackageResource => currentPackageResource.type === "commonTopicsSection" && currentPackageResource.revisionId === resourceNodeRevisionId);
      // Guard - unlikely to happen since the results are pulled from the contexts packageResources but it prevents TS from yelling at us
      if (!packageResource) {
        Log.warn("CpPackageSessionReviewSelect - no corresponding packageResource found for commonTopic resource: ", resource);
        return;
      }
      return {
        icon,
        label: getNameVariant(resource, ["short", "default"]) || "",
        parentLabel: packageResource.sectionHeader,
        resourceNodeRevisionId,
        selected: packageResource.selected
      };
    });

    // For any topicNodes we have a bit more work to do to break them up into their respective sections to build a GroupedPackageSessionResource
    const localTopicNodeResources = data.topicNodes.reduce((prevValue, node) => {
      const {
        icon,
        parentId,
        parentNames,
        resourceNodeRevisionId,
        treeId
      } = node;
      const parentLabel = getNameVariant({
        names: parentNames
      }) || "";
      const packageResource = packageResources.find(currentPackageResource => currentPackageResource.type === "topicSection" && currentPackageResource.revisionId === resourceNodeRevisionId && currentPackageResource.sectionHeader === parentLabel);
      // Same as above guard - difference being that section nodes are not leaf nodes, and will not have resourceNodeRevisionIds
      if (!packageResource || !resourceNodeRevisionId) {
        Log.warn("CpPackageSessionReviewSelect - no corresponding packageResource found for topicNode: ", node);
        return prevValue;
      }

      // Find the parent group for the resource if it exists
      const currentGroupIndex = prevValue.findIndex(group => group.treeId === treeId && parentLabel === group.label);
      const groupIsInPrevValue = currentGroupIndex >= 0;
      // Either append to existing group, or create a new one for future population
      const currentGroup = groupIsInPrevValue ? prevValue[currentGroupIndex] : {
        id: parentId,
        label: parentLabel,
        resources: [],
        treeId,
        type: "topicSection"
      };

      // Add the current node to the groups list of resources
      currentGroup.resources.push({
        icon,
        label: getNameVariant(node, ["short", "default"]) || "",
        parentLabel,
        resourceNodeRevisionId,
        selected: packageResource.selected
      });

      // Finally, either overwrite the existing group with the latest data, or add it to the stack
      if (groupIsInPrevValue) {
        prevValue[currentGroupIndex] = currentGroup;
      } else {
        prevValue.push(currentGroup);
      }
      return prevValue;
    }, []);

    // Now to finalize the object by returning the commonTopics and topicNodes, but only if we actually have something to return
    if (localCommonTopicResources.length > 0) {
      localGroups.push({
        label: formatMessage(translations.labels.commonTopics),
        resources: localCommonTopicResources,
        type: "commonTopicsSection"
      });
    }
    if (localTopicNodeResources.length > 0) {
      localGroups.push(...localTopicNodeResources);
    }
    return localGroups;
  }, [data, formatMessage, packageResources]);
  const handleSelectItem = useCallback(value => {
    // Can't modify a frozen object, deconstructing here to bypass
    const localPackageResources = [...packageResources];
    // Find the corresponding resource in context
    const selectedIndex = localPackageResources.findIndex(packageResource => packageResource.revisionId === value.revisionId && packageResource.sectionHeader === value.label);
    // and flip its 'selected' value
    localPackageResources[selectedIndex].selected = !localPackageResources[selectedIndex].selected;
    dispatchPatientSession({
      payload: {
        packageResources: localPackageResources
      },
      type: PatientSessionActions.SetSelectedPackages
    });
  }, [dispatchPatientSession, packageResources]);
  return <>
      {groupedPackageSessionResources.map(packageGroup => {
      if (!packageGroup) return null;
      const {
        id,
        label: groupLabel,
        resources,
        type
      } = packageGroup;
      const selectedResourceCount = resources.reduce((acc, resource) => resource.selected ? acc + 1 : acc, 0);
      return <_StyledView key={id ? `${type}-${id}` : `${type}`} $_css={styles.sessionStyles.reviewSelect.groupContainer}>
            <_StyledPrText children={groupLabel} $_css2={styles.sessionStyles.reviewSelect.headerResource} />
            {resources.map((resource, resourceIndex) => {
          const {
            icon,
            label,
            resourceNodeRevisionId,
            selected
          } = resource;
          return <React.Fragment key={resourceNodeRevisionId}>
                  {resourceIndex !== 0 && <_StyledView2 $_css3={styles.sessionStyles.reviewSelect.separator} />}
                  <CpSessionReviewSelectItem icon={icon} identifier={resourceNodeRevisionId} label={label} onPressCallback={handleSelectItem} onPressValue={{
              revisionId: resource.resourceNodeRevisionId,
              label: resource.parentLabel
            }}
            // TODO: Add publisher? Requires some digging and changes to types which a quick attempt couldn't accomplish
            // publisher={resource?.publisher?.name}
            selected={selected} />
                </React.Fragment>;
        })}

            <_StyledPrText2 children={translations.labels.selectedForSharing} $_css4={[styles.fontSizeSmall, styles.paddingTopSingle]} />
            <_StyledPrText3 children={formatMessage(translations.entities.resourceCount, {
          count: selectedResourceCount
        })} $_css5={styles.fontSizeSmall} />
          </_StyledView>;
    })}
    </>;
};
export default CpPackageSessionReviewSelect;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledPrText3 = _styled5(PrText).withConfig({
  displayName: "CpPackageSessionReviewSelect___StyledPrText3",
  componentId: "sc-1ic3aqk-0"
})(["", ""], p => p.$_css5);