import _styled, { default as _styled2 } from "styled-components";
import React from "react";
import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtForgotPassword from "components/containers/CtForgotPassword";
import { rnStyles, styles } from "services/Theme";
import translations from "translations";
/**
 * The forgot password screen
 */
const ScForgotPassword = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css2={styles.screen}>
        <main>
          <CtForgotPassword />
        </main>
      </_StyledScrollView>
    </_StyledSafeAreaView>;
};
export default ScForgotPassword;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledScrollView = _styled2(ScrollView).withConfig({
  displayName: "ScForgotPassword___StyledScrollView",
  componentId: "sc-wtkhep-0"
})(["", ""], p => p.$_css2);