import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10, default as _styled11, default as _styled12, default as _styled13, default as _styled14 } from "styled-components";
import React, { useCallback, useState } from "react";
import { FormattedDate, FormattedMessage, defineMessages } from "react-intl";
import { Modal, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import CpAppFooter from "components/app/CpAppFooter";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
import EditorialPolicy from "pdfs/EditorialPolicy.pdf";
import PrivacyPolicy from "pdfs/PrivacyPolicy.pdf";
import ProtectingChildrenPolicy from "pdfs/ProtectingChildrenPolicy.pdf";
import SecurityMeasuresPolicy from "pdfs/SecurityMeasuresPolicy.pdf";
import ThirdPartyLinksPolicy from "pdfs/ThirdPartyLinksPolicy.pdf";
const localTranslations = defineMessages({
  editorialPolicy: {
    id: "eO6mBg",
    defaultMessage: "Editorial Policy"
  },
  editorialPolicyBlurb: {
    id: "Mede6L",
    defaultMessage: "The IMD Health platform enables secure, clinically structured communication of educational resources between doctors and patients. IMD Health Global\u2019s aim with the IMD Health service is to ensure that this communication service is reliable, easy to access, read and understand..."
  },
  privacyPolicy: {
    id: "jgEjYd",
    defaultMessage: "Privacy Policy"
  },
  privacyPolicyBlurb: {
    id: "v7k9cy",
    defaultMessage: "Our privacy philosophy is based on the concept of fair information practices. This means we provide our customers with notice of how we manage information so that they can have a more informed understanding of how we operate..."
  },
  protectingChildrenPolicy: {
    id: "S6H3k2",
    defaultMessage: "Protecting Children Policy"
  },
  protectingChildrenPolicyBlurb: {
    id: "5tfQNr",
    defaultMessage: "The IMD Health service is designed and intended for use by adults, and is not intended for, nor designed to be used by children under the age of eighteen..."
  },
  securityMeasuresPolicy: {
    id: "qMV5B/",
    defaultMessage: "Security Measures Policy"
  },
  securityMeasuresPolicyBlurb: {
    id: "ekN6jR",
    defaultMessage: "IMD Health Global Corp acts as a trusted confidential application service provider dedicated to providing a secure Internet service that enables healthcare providers and their patients to communicate..."
  },
  thirdPartyLinksPolicy: {
    id: "fsQida",
    defaultMessage: "Third Party Links Policy"
  },
  thirdPartyLinksPolicyBlurb: {
    id: "riDDAe",
    defaultMessage: "Although the IMD Health platform includes links providing direct access to third party Internet sites as a convenience, the inclusion of a link does not imply endorsement of the linked site by IMD Health Global Corp..."
  }
});
const localStyles = {
  policiesContainer: css`
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  policyButton: css`
    justify-content: center;
  `,
  policyDetailsContainer: css`
    flex-direction: row;
    justify-content: space-between;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  viewText: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
    font-weight: bold;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
    ${styles.uppercase};
  `
};
/**
 * Mapping all policies and their related content
 * NB: If uploading a new policy, ensure the updated date is changed to match the policy, and maybe even the blurb if necessary
 */
const policyData = {
  editorial: {
    blurb: localTranslations.editorialPolicyBlurb,
    label: localTranslations.editorialPolicy,
    pdf: EditorialPolicy,
    updated: new Date("January 1, 2020")
  },
  privacy: {
    blurb: localTranslations.privacyPolicyBlurb,
    label: localTranslations.privacyPolicy,
    pdf: PrivacyPolicy,
    updated: new Date("January 1, 2020")
  },
  protectingChildren: {
    blurb: localTranslations.protectingChildrenPolicyBlurb,
    label: localTranslations.protectingChildrenPolicy,
    pdf: ProtectingChildrenPolicy,
    updated: new Date("January 1, 2020")
  },
  securityMeasures: {
    blurb: localTranslations.securityMeasuresPolicyBlurb,
    label: localTranslations.securityMeasuresPolicy,
    pdf: SecurityMeasuresPolicy,
    updated: new Date("January 1, 2020")
  },
  thirdPartyLinks: {
    blurb: localTranslations.thirdPartyLinksPolicyBlurb,
    label: localTranslations.thirdPartyLinksPolicy,
    pdf: ThirdPartyLinksPolicy,
    updated: new Date("January 1, 2020")
  }
};
const policyDataOrder = ["privacy", "editorial", "protectingChildren", "securityMeasures", "thirdPartyLinks"];

/**
 * Show the app's policies. Selected policies will open in a fullscreen modal.
 */
const ScPolicies = ({
  navigation,
  route
}) => {
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    contentMinHeight,
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const [selectedPolicy, setSelectedPolicy] = useState();
  const handleSetSelectedPolicy = useCallback(policyPdf => setSelectedPolicy(policyPdf), []);
  const handleHideSelectedPolicy = useCallback(() => setSelectedPolicy(undefined), []);
  const navigateToHome = useCallback(() => navigation.navigate(Routes.home), [navigation]);
  return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader header={translations.screens.policies}>
          <_StyledView $_css2={[localStyles.policiesContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined, css`
                min-height: ${contentMinHeight}px;
              `]}>
            {/* <PrText children={translations.screens.policies} css={styles.pageHeading} /> */}
            {policyDataOrder.map(policy => {
            const {
              blurb,
              label,
              pdf,
              updated
            } = policyData[policy];
            return <_StyledPrPressable key={policy} onPress={() => handleSetSelectedPolicy(pdf)} $_css3={localStyles.policyButton}>
                  {({
                hovered
              }) => <_StyledView2 $_css4={localStyles.policyDetailsContainer}>
                      <_StyledView3 $_css5={styles.flexSingle}>
                        <_StyledPrText children={label} $_css6={[styles.textHeader2, hovered && styles.termsAndPoliciesStyles.hoveredText]} />
                        <View>
                          <PrText children={blurb} />
                          <_StyledPrText2 $_css7={styles.termsAndPoliciesStyles.date}>
                            <FormattedDate day="2-digit" month="long" value={updated} year="numeric" />
                          </_StyledPrText2>
                        </View>
                      </_StyledView3>
                      <_StyledView4 $_css8={styles.termsAndPoliciesStyles.iconContainer}>
                        <PrIcon fill={brandColors.primary} icon={IconName.pdf} size={isNarrowScreen ? iconSizes.medium : iconSizes.large} />
                      </_StyledView4>
                    </_StyledView2>}
                </_StyledPrPressable>;
          })}

            <_StyledPrButton onPress={navigateToHome} type="outline" $_css9={[styles.marginVerticalDouble, styles.marginHorizontalDouble]}>
              <_StyledPrText3 children={translations.buttons.home} $_css10={styles.termsAndPoliciesStyles.homeButtonText} />
            </_StyledPrButton>
          </_StyledView>
          <CpAppFooter />
        </CpBackgroundHeader>
      </ScrollView>

      {!!selectedPolicy && <Modal onRequestClose={handleHideSelectedPolicy} transparent={false} visible={!!selectedPolicy}>
          <_StyledView5 $_css11={styles.termsAndPoliciesStyles.pdfModalContainer}>
            <_StyledView6 $_css12={styles.termsAndPoliciesStyles.fullscreenButtonContainer}>
              <_StyledPrPressable2 onPress={handleHideSelectedPolicy} $_css13={styles.termsAndPoliciesStyles.fullscreenCloseButton}>
                <_StyledPrText4 $_css14={styles.termsAndPoliciesStyles.fullscreenCloseText}>
                  <FormattedMessage {...translations.buttons.backTo} values={{
                label: <FormattedMessage {...translations.screens.policies} />
              }} />
                </_StyledPrText4>
                <PrIcon fill={buttonColors.primary} icon={IconName.back} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
              </_StyledPrPressable2>
            </_StyledView6>
            <WebView source={{
          uri: selectedPolicy
        }} />
          </_StyledView5>
        </Modal>}
    </_StyledSafeAreaView>;
};
export default ScPolicies;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrPressable = _styled3(PrPressable)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledView3 = _styled5(View)`${p => p.$_css5}`;
var _StyledPrText = _styled6(PrText)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledView4 = _styled8(View)`${p => p.$_css8}`;
var _StyledPrButton = _styled9(PrButton)`${p => p.$_css9}`;
var _StyledPrText3 = _styled10(PrText)`${p => p.$_css10}`;
var _StyledView5 = _styled11(View)`${p => p.$_css11}`;
var _StyledView6 = _styled12(View)`${p => p.$_css12}`;
var _StyledPrPressable2 = _styled13(PrPressable)`${p => p.$_css13}`;
var _StyledPrText4 = _styled14(PrText).withConfig({
  displayName: "ScPolicies___StyledPrText4",
  componentId: "sc-owgcju-0"
})(["", ""], p => p.$_css14);