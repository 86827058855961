import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7 } from "styled-components";
import Color from "color";
import React from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpContentSearchPicker from "components/content/CpContentSearchPicker";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useRandomSearchPlaceholder from "hooks/useRandomSearchPlaceholder";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
const localStyles = {
  backsplashContainer: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.homeSearchBacksplash};
    border-color: ${({
    theme
  }) => Color(theme.brandColors.background).alpha(0.5).toString()};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-width: 3px;
    padding: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  header: css`
    color: ${({
    theme
  }) => theme.textColors.heading1};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xxlarge")};
    font-weight: bold;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    text-align: center;
  `,
  searchContainerSmall: css`
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  searchInputContainer: css`
    align-items: center;
    width: 100%;
  `,
  searchInputLargeScreen: css`
    width: ${({
    theme
  }) => theme.mediaBreaks.medium}px;
  `,
  searchInputSmallScreen: css`
    width: 100%;
  `,
  searchTextInput: css`
    border-width: 3px;
    height: 60px;
  `,
  tagLine: css`
    color: ${({
    theme
  }) => theme.textColors.heading1};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    text-align: center;
  `
};
/**
 *  Displays a content search input field along with the customized image and tagline.
 */
const CpHomeSearch = () => {
  const {
    isMediumOrSmallerScreen,
    isNarrowScreen,
    isWideScreen
  } = useScreenLayout();
  const {
    appConfig
  } = useImdSession();
  const {
    additionalHomeContainerStyles,
    additionalHomeHeaderStyles,
    additionalHomeSearchBarStyles,
    additionalHomeTaglineStyles
  } = useAppTheme();
  const appHome = appConfig?.appHome;
  const showHeader = !!appHome?.header;
  const showSearch = !appHome?.hideSearch;
  const placeholder = appHome?.search?.placeholder;
  const randomPlaceholder = useRandomSearchPlaceholder(isMediumOrSmallerScreen ? 1 : 2);
  return appHome?.hide ? <_StyledView $_css={styles.paddingSingle} /> : <_StyledView2 $_css2={[isNarrowScreen ? styles.paddingSingle : styles.paddingDouble, additionalHomeContainerStyles]}>
      <_StyledView3 style={rnStyles.menuShadow} $_css3={[appHome?.hideBacksplash ? undefined : localStyles.backsplashContainer, isNarrowScreen ? localStyles.searchContainerSmall : styles.marginDouble]}>
        {showHeader && <_StyledPrText accessibilityRole="header" children={appHome.header} style={rnStyles.homeSearchTagLine} $_css4={[localStyles.header, additionalHomeHeaderStyles]} />}
        {showSearch &&
      // The searchInputContainer is needed because the border on the outer container messes up the layout calculations for the search input
      <_StyledView4 $_css5={localStyles.searchInputContainer}>
            <_StyledCpContentSearchPicker placeholder={placeholder || placeholder === "" ? placeholder : randomPlaceholder} searchConfig={appHome?.search} textInputStyle={localStyles.searchTextInput} $_css6={[isWideScreen ? localStyles.searchInputLargeScreen : localStyles.searchInputSmallScreen, additionalHomeSearchBarStyles]} />
          </_StyledView4>}
        {/* Tagline: blank string indicates the tagline should be hidden */}
        {!(appHome?.tagline === "") && <_StyledPrText2 accessibilityRole="header"
      // If a header is provided, we want to ensure we don't have conflicting aria levels
      aria-level={showHeader ? "2" : "1"} children={appHome?.tagline ? appHome.tagline : <FormattedMessage id="eM0ybc" defaultMessage="Search Over {resourceCount} Health Conditions and Procedures"
      // Extracting resource count here so we don't need to keep submitting this for translating
      // TODO: Once we automate our resource count we can update this automatically from the backend
      values={{
        resourceCount: 6000
      }} />} style={rnStyles.homeSearchTagLine} $_css7={[localStyles.tagLine, additionalHomeTaglineStyles]} />}
      </_StyledView3>
    </_StyledView2>;
};
export default CpHomeSearch;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledView3 = _styled3(View)`${p => p.$_css3}`;
var _StyledPrText = _styled4(PrText)`${p => p.$_css4}`;
var _StyledView4 = _styled5(View)`${p => p.$_css5}`;
var _StyledCpContentSearchPicker = _styled6(CpContentSearchPicker)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText).withConfig({
  displayName: "CpHomeSearch___StyledPrText2",
  componentId: "sc-tvzszg-0"
})(["", ""], p => p.$_css7);