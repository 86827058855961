import _styled, { default as _styled2, default as _styled3, default as _styled4 } from "styled-components";
import { selectOne } from "css-select";
import renderDom from "dom-serializer";
import { parseDocument } from "htmlparser2";
import React, { useMemo } from "react";
import { View } from "react-native";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import PrText from "components/primitives/PrText";
import CpMissingResource from "components/topic/CpMissingResource";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css } from "services/Theme";
const localStyles = {
  cardContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    margin: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  cardLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    justify-content: center;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  video: css`
    position: absolute;
    top: 0;
  `,
  videoWrapper: css`
    height: 0px;
    position: relative;
  `
};
const largeScreenStyles = {
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-top: 0px;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `
};
const smallScreenStyles = {
  cardContainer: css`
    ${localStyles.cardContainer};
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  cardLabel: css`
    ${localStyles.cardLabel};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `
};
/**
 * Renders a Video in a card format.
 */
const CpVideoCard = ({
  aspectRatio,
  embedMarkup,
  label,
  style,
  testID
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    spacing
  } = useAppTheme();
  const componentStyles = isNarrowScreen ? smallScreenStyles : largeScreenStyles;
  const {
    localAspectRatio,
    videoHtml
  } = useMemo(() => {
    // Parse embedMarkup to convert to object which we can easily query
    const parsedHtml = parseDocument(embedMarkup);
    // Isolate the video iframe - any content outside of this iframe will be ignored
    const videoIframe = selectOne(element => element.name === "iframe" && !!element.attribs?.src, parsedHtml);
    if (!videoIframe) {
      return {
        aspectRatio: 75,
        videoHtml: null
      };
    }

    // Convert video Element back to html string so we can manipulate it
    let localVideoHtml = renderDom(videoIframe);
    // Regex to find and capture height and width values applied to iframe
    const widthExp = /width="(.*?)"/;
    const heightExp = /height="(.*?)"/;
    // Extract original height and width and using it to calculate aspect ratio
    const height = Number(localVideoHtml.match(heightExp)?.[1] ?? 0);
    const width = Number(localVideoHtml.match(widthExp)?.[1] ?? 0);
    let calculatedAspectRatio;
    if (aspectRatio) {
      calculatedAspectRatio = aspectRatio * 100;
    } else {
      // Fallback to 4:3 aspect ratio (75) if height/width somehow isn't populated
      calculatedAspectRatio = height > 0 && width > 0 ? height / width * 100 : 75;
    }
    // Replace original h/w with percentages so we can style the parent instead allowing the iframe to expand to its container
    localVideoHtml = localVideoHtml?.replace(heightExp, 'height="100%"');
    localVideoHtml = localVideoHtml?.replace(widthExp, 'width="100%"');
    return {
      localAspectRatio: calculatedAspectRatio,
      videoHtml: localVideoHtml
    };
  }, [embedMarkup, aspectRatio]);
  const videoStyle = css`
    /* Ensuring video iframe renders (with padding) at full height/width */
    height: calc(100% - ${spacing.double}px);
    width: calc(100% - ${spacing.double}px);
    left: ${spacing.single}px;
    ${localStyles.video};
  `;
  const videoWrapperStyle = css`
    /* We can maintain aspect ratio by setting padding as a percentage. Padding is calculated on the width of the parent element which creates a View whose height is in proportion to its width. */
    padding-top: ${localAspectRatio}%;
    ${localStyles.videoWrapper}
  `;
  return <_StyledView style={style} $_css={componentStyles.cardContainer}>
      <_StyledPrText children={label} $_css2={componentStyles.cardLabel} />
      <_StyledView2 testID={testID} $_css3={videoWrapperStyle}>
        {videoHtml ? <_StyledCpDivHtmlWeb content={videoHtml} $_css4={videoStyle} /> : <CpMissingResource />}
      </_StyledView2>
    </_StyledView>;
};
export default CpVideoCard;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledView2 = _styled3(View)`${p => p.$_css3}`;
var _StyledCpDivHtmlWeb = _styled4(CpDivHtmlWeb).withConfig({
  displayName: "CpVideoCard___StyledCpDivHtmlWeb",
  componentId: "sc-dv5el1-0"
})(["", ""], p => p.$_css4);