import _styled, { default as _styled2, default as _styled3, default as _styled4 } from "styled-components";
import React from "react";
import { View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css } from "services/Theme";
import CpSectionHeader from "./CpSectionHeader";
const localStyles = {
  borderTop: css`
    border-top-width: 1px;
  `,
  buttonInnerContainer: css`
    align-items: center;
    height: 100%;
    flex: 1;
    flex-direction: row;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  headerLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `,
  iconContainer: css`
    width: ${({
    theme
  }) => theme.iconSizes.medium}px;
    height: ${({
    theme
  }) => theme.iconSizes.medium}px;
  `,
  primaryAccordion: css`
    align-items: center;
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  primaryAccordionBottomBorder: css`
    border-bottom-width: 1px;
  `,
  primaryAccordionLabel: css`
    color: ${({
    theme
  }) => theme.brandColors.primary};
    flex: 1;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
  `
};
/**
 * Renders a top level header for the subtree navigator. Header will be displayed as an accordion button for expanding content on small and medium screens.
 */
const CpTopicSubtreeHeader = ({
  expanded,
  isFirstChild,
  isHeading = false,
  isLastChild,
  label,
  onSelect
}) => {
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const itemIcon = expanded ? IconName.keyboardArrowUp : IconName.keyboardArrowDown;
  return isHeading ? <CpSectionHeader label={label} /> : <_StyledPrPressable accessibilityState={{
    expanded: expanded
  }} onPress={onSelect} $_css={[localStyles.primaryAccordion, !isFirstChild && localStyles.borderTop, isLastChild && !expanded && localStyles.primaryAccordionBottomBorder]}>
      <_StyledView $_css2={localStyles.buttonInnerContainer}>
        <_StyledPrText accessibilityRole="header" aria-level="3" children={label} numberOfLines={2} $_css3={localStyles.primaryAccordionLabel} />
        <_StyledView2 $_css4={localStyles.iconContainer}>
          <PrIcon fill={buttonColors.secondary} icon={itemIcon} size={iconSizes.medium} />
        </_StyledView2>
      </_StyledView>
    </_StyledPrPressable>;
};
export default CpTopicSubtreeHeader;
var _StyledPrPressable = _styled(PrPressable)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View).withConfig({
  displayName: "CpTopicSubtreeHeader___StyledView2",
  componentId: "sc-orpt0v-0"
})(["", ""], p => p.$_css4);