import _styled, { default as _styled2 } from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpTopic from "components/topic/CpTopic";
import CxTopic from "contexts/CxTopic";
import { styles } from "services/Theme";
/**
 * Screen for exploring a topic (ResourceNode tree)
 */
const ScTopic = ({
  route
}) => {
  const {
    params: {
      resourceId,
      rootRevisionId,
      selectedRevisionId
    }
  } = route;
  return <_StyledSafeAreaView testID={`ScTopic-${selectedRevisionId ?? rootRevisionId}`} $_css={styles.flexSingle}>
      <CxTopic resourceId={resourceId} rootRevisionId={rootRevisionId} selectedRevisionId={selectedRevisionId}>
        <_StyledCpTopic resourceId={resourceId} rootRevisionId={rootRevisionId} selectedRevisionId={selectedRevisionId} $_css2={styles.flexSingle} />
      </CxTopic>
    </_StyledSafeAreaView>;
};
export default ScTopic;
var _StyledSafeAreaView = _styled(SafeAreaView)`${p => p.$_css}`;
var _StyledCpTopic = _styled2(CpTopic).withConfig({
  displayName: "ScTopic___StyledCpTopic",
  componentId: "sc-oeg300-0"
})(["", ""], p => p.$_css2);