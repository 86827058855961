import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6 } from "styled-components";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import CpGridCard from "components/home/tabs/CpGridCard";
import CpGridItem from "components/home/tabs/CpGridItem";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useBackendSupportedLocale from "hooks/useBackendSupportedLocale";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { css } from "services/Theme";
import translations from "translations";
const localStyles = {
  container: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  linksContainer: css`
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  packageSectionButton: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `,
  packageSectionButtonText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  sectionTitle: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  titleContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `
};
/**
 * Renders a group of content links as a grid of tiles
 */
const CpContentLinksSection = ({
  addToPackage,
  linkStyle,
  renderWithoutHeaders = false,
  sectionTitle,
  shortcuts,
  style,
  topicTreeId
}) => {
  const locale = useBackendSupportedLocale();
  const {
    patientSessionData: {
      isPackageSession,
      packageResources
    }
  } = usePatientSessionContext();
  const localizedSectionTitle = useMemo(() => {
    if (typeof sectionTitle === "string") {
      return sectionTitle;
    } else {
      return sectionTitle[locale] || sectionTitle.en;
    }
  }, [locale, sectionTitle]);
  const onPressItem = addToPackage?.onPressItem;
  const onPressSection = addToPackage?.onPressSection;
  const handleAddSectionToPackage = useCallback(() => {
    onPressSection && onPressSection(localizedSectionTitle, shortcuts.map(shortcut => shortcut.link.value));
  }, [localizedSectionTitle, onPressSection, shortcuts]);
  return <_StyledView key={localizedSectionTitle} style={style} $_css={localStyles.container}>
      {!renderWithoutHeaders && <_StyledView2 $_css2={localStyles.titleContainer}>
          <_StyledPrText accessibilityRole="header" aria-level="3" key={`text-${localizedSectionTitle}`} $_css3={localStyles.sectionTitle}>
            {localizedSectionTitle}
          </_StyledPrText>
          {isPackageSession && !!onPressSection && <_StyledPrPressable onPress={handleAddSectionToPackage}
      // testID's don't like whitespace, removing it from the section name here
      testID={`CpContentLinksSection-sectionSelect-${localizedSectionTitle.split(" ").join("")}`} $_css4={localStyles.packageSectionButton}>
              <_StyledPrText2 children={translations.buttons.selectSection} $_css5={localStyles.packageSectionButtonText} />
            </_StyledPrPressable>}
        </_StyledView2>}

      <_StyledView3 $_css6={localStyles.linksContainer}>
        {shortcuts.map((shortcut, index) => {
        const localizedShortcutTitle = shortcut.title[locale] || shortcut.title.en;
        const isInPackage = packageResources.some(resource => resource.revisionId === shortcut.link.value && (
        // "topicSection" content will be grouped by topicTreeIds, whereas "commonTopicsSection" content will all be grouped by the "Common Topics" header
        resource.type === "topicSection" && resource.topicTreeId === topicTreeId || resource.sectionHeader === localizedSectionTitle));
        const ShortcutComponent = linkStyle === "Card" ? CpGridCard : CpGridItem;
        // Split out and add any additional props specific to card vs item components
        const componentProps = linkStyle === "Card" ? {
          authors: shortcut?.authors,
          snippet: shortcut?.snippet
        } : {};
        return <ShortcutComponent accessibilityLabel={localizedShortcutTitle} accessibilityRole={isPackageSession ? "button" : "link"} color={shortcut.color ?? "primary"} completed={shortcut.completed}
        // We don't allow for custom content to be added to packages
        disabled={isPackageSession && shortcut.link.link_type === "external"} icon={shortcut.icon} image={shortcut.image} isNew={shortcut.is_new} key={`${JSON.stringify(shortcut)}-${index}`} // eslint-disable-line react/no-array-index-key
        link={shortcut.link} onPress={isPackageSession && !!onPressItem ? () => onPressItem(localizedSectionTitle, shortcut.link.value) : undefined} selected={isInPackage} testID={`CpContentLinksSection-${shortcut.link.value}`} title={localizedShortcutTitle} total={shortcut.total} {...componentProps} />;
      })}
      </_StyledView3>
    </_StyledView>;
};
export default CpContentLinksSection;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledPrText = _styled3(PrText)`${p => p.$_css3}`;
var _StyledPrPressable = _styled4(PrPressable)`${p => p.$_css4}`;
var _StyledPrText2 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledView3 = _styled6(View).withConfig({
  displayName: "CpContentLinksSection___StyledView3",
  componentId: "sc-c7baf5-0"
})(["", ""], p => p.$_css6);