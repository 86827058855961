import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8, default as _styled9, default as _styled10 } from "styled-components";
import { useNavigation } from "@react-navigation/core";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpAdminMobileNavButton from "components/app/header/CpAdminMobileNavButton";
import CpPopup from "components/common/CpPopup";
import PrActionButton from "components/primitives/PrActionButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import Config from "services/Config";
import { AppName } from "services/Constants";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  accountMenuButtonBase: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border: 2px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-right-width: 0;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    justify-content: center;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
    margin-right: -${({
    theme
  }) => theme.spacing.single}px;
  `,
  accountMenuButtonLarge: css`
    flex-direction: row;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  accountMenuButtonSmall: css`
    padding: 0 16px 0 16px;
  `,
  accountMenuButtonTextBase: css`
    ${styles.uppercase};
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
  `,
  accountMenuButtonTextLarge: css`
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  mediumButtonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  popupBorder: css`
    border: 2px solid ${({
    theme
  }) => theme.buttonColors.primary};
  `,
  userEmail: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  userName: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  userOrg: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    text-align: center;
  `
};
const leftStyles = {
  accountMenuButtonBase: css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-left-width: 0;
    border-right-width: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-left: -${({
    theme
  }) => theme.spacing.single}px;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
/**
 * Renders the account menu for an authenticatied session
 */
const CpAccountMenu = ({
  isAdmin = false,
  menuActions,
  position
}) => {
  const {
    user
  } = useImdSession();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    isWideScreen,
    width
  } = useScreenLayout();
  const {
    spacing
  } = useAppTheme();
  const {
    navigate
  } = useNavigation();
  const userName = user?.name;
  const userEmail = user?.email;
  const orgName = user?.currentOrganization?.name;
  const [menuPopupLayout, setMenuPopupLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleMenuLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    const padding = spacing.single;
    setMenuPopupLayout({
      width: 250,
      // This should align the menu to the right of the screen (plus padding)
      x: position === "LEFT" ? padding : width - 250 - padding,
      y: layout.top + layout.height
    });
  }, [position, spacing.single, width]);

  // Main menu state and callback
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const handleHideMenuPopup = useCallback(() => setShowMenuPopup(false), [setShowMenuPopup]);
  const handleShowMenuPopup = useCallback(() => setShowMenuPopup(true), [setShowMenuPopup]);
  const handleSigninPress = useCallback(() => {
    handleHideMenuPopup();
    navigate(Routes.signin);
  }, [handleHideMenuPopup, navigate]);

  // Doesnt render the menu if we're on the public app and it has no actions
  if (Config.APP_NAME === AppName.ImdPublic && menuActions.length === 0) {
    return null;
  }

  // Returns sign in button if user is not signed in and we're in provider
  if (!user && Config.APP_NAME === AppName.ImdProvider) {
    return <_StyledPrPressable onPress={handleSigninPress} style={rnStyles.buttonShadow} $_css={localStyles.accountMenuButtonBase}>
        <_StyledPrText children={translations.buttons.signin} $_css2={[localStyles.accountMenuButtonTextBase, localStyles.mediumButtonText]} />
      </_StyledPrPressable>;
  }
  return user || !user && Config.APP_NAME === AppName.ImdPublic ? <View onLayout={handleMenuLayout}>
      <_StyledPrPressable2 onPress={handleShowMenuPopup} style={rnStyles.buttonShadow} testID="CpAccountMenuPressable" $_css3={[localStyles.accountMenuButtonBase, isWideScreen ? localStyles.accountMenuButtonLarge : localStyles.accountMenuButtonSmall, position === "LEFT" ? leftStyles.accountMenuButtonBase : undefined]}>
        <PrIcon fill={buttonColors.primary} icon={IconName.menu} size={iconSizes.small} />
        <_StyledPrText2 $_css4={[localStyles.accountMenuButtonTextBase, isWideScreen ? localStyles.accountMenuButtonTextLarge : undefined]}>
          <FormattedMessage id="Xb533U" defaultMessage="Menu" />
        </_StyledPrText2>
      </_StyledPrPressable2>

      <CpPopup layout={menuPopupLayout} onBackgroundPress={handleHideMenuPopup} popupStyle={styles.popupBorder} visible={showMenuPopup}>
        {/* Render different options on the menu for the admin app */}
        {isAdmin ? <>
            <_StyledView $_css5={[styles.paddingHorizontalSingle, styles.paddingVerticalDouble, styles.center]}>
              {userName && <_StyledPrText3 children={userName} $_css6={localStyles.userName} />}
              <_StyledPrText4 children={userEmail} $_css7={localStyles.userEmail} />
            </_StyledView>
            {!isWideScreen ? <CpAdminMobileNavButton handleHideMenuPopup={handleHideMenuPopup} /> : null}
            <PrActionButton actionButton="CHANGE_CLINIC" type={ActionButtonUserType.AccountMenu} />
            {user ? <PrActionButton actionButton="SIGN_OUT" type={ActionButtonUserType.AccountMenu} /> : null}
            <PrActionButton actionButton="MODE" type={ActionButtonUserType.AccountMenu} />
          </> : <>
            {/* User Name & Current Org */}
            <_StyledView2 $_css8={[styles.paddingHorizontalSingle, styles.paddingVerticalDouble, styles.center]}>
              {userName ? <_StyledPrText5 children={userName} $_css9={localStyles.userName} /> : null}
              <_StyledPrText6 children={orgName} $_css10={localStyles.userOrg} />
            </_StyledView2>
            {menuActions.map(action => <PrActionButton actionButton={action} key={action} type={ActionButtonUserType.AccountMenu} />)}
          </>}
      </CpPopup>
    </View> : null;
};
export default CpAccountMenu;
var _StyledPrPressable = _styled(PrPressable)`${p => p.$_css}`;
var _StyledPrText = _styled2(PrText)`${p => p.$_css2}`;
var _StyledPrPressable2 = _styled3(PrPressable)`${p => p.$_css3}`;
var _StyledPrText2 = _styled4(PrText)`${p => p.$_css4}`;
var _StyledView = _styled5(View)`${p => p.$_css5}`;
var _StyledPrText3 = _styled6(PrText)`${p => p.$_css6}`;
var _StyledPrText4 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledView2 = _styled8(View)`${p => p.$_css8}`;
var _StyledPrText5 = _styled9(PrText)`${p => p.$_css9}`;
var _StyledPrText6 = _styled10(PrText).withConfig({
  displayName: "CpAccountMenu___StyledPrText6",
  componentId: "sc-5oe4pe-0"
})(["", ""], p => p.$_css10);