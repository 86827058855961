import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7, default as _styled8 } from "styled-components";
import camelcase from "camelcase";
import React, { useCallback, useMemo } from "react";
import { Image, View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import isUrlString from "helpers/isUrlString";
import useAppTheme from "hooks/useAppTheme";
import { css, styles } from "services/Theme";
const localStyles = {
  itemButton: css`
    ${styles.rowAndCenter};
    align-items: space-between;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  itemButtonDisabled: css`
    background-color: ${({
    theme
  }) => theme.brandColors.separator};
    cursor: not-allowed;
  `,
  itemContentContainer: css`
    align-items: center;
    flex: 1;
    flex-direction: row;
  `,
  itemIcon: css`
    align-items: center;
    width: ${({
    theme
  }) => theme.iconSizes.medium + theme.spacing.single}px;
  `,
  itemImage: css`
    height: ${({
    theme
  }) => theme.buttonHeights.large - theme.spacing.double}px;
    width: ${({
    theme
  }) => theme.iconSizes.medium + theme.spacing.single}px;
  `,
  label: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `,
  publisher: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
/**
 * Renders a generic selectable item for Patient and Package session reviewing
 */
const CpSessionReviewSelectItem = ({
  disabled = false,
  icon,
  identifier,
  isAnnotation,
  label,
  onPressCallback,
  onPressValue,
  publisher,
  selected
}) => {
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  const handlePress = useCallback(() => onPressCallback(onPressValue), [onPressCallback, onPressValue]);
  const itemIcon = useMemo(() => {
    if (icon && isUrlString(icon)) {
      return <_StyledImage resizeMode="contain" source={{
        uri: icon
      }} $_css={localStyles.itemImage} />;
    } else {
      return <_StyledView $_css2={localStyles.itemIcon}>
          {isAnnotation ? <PrIcon fill={brandColors.primary} icon={IconName.brush} size={iconSizes.medium} /> : <PrIcon fill={brandColors.primary} icon={icon ? camelcase(icon) : IconName.iconImd} size={iconSizes.medium} />}
        </_StyledView>;
    }
  }, [brandColors.primary, icon, iconSizes.medium, isAnnotation]);
  return <_StyledPrPressable accessibilityRole="checkbox" accessibilityState={{
    checked: selected
  }} disabled={disabled} onPress={handlePress} testID={`CpSessionReviewSelectItem-${identifier}`} $_css3={[localStyles.itemButton, disabled ? localStyles.itemButtonDisabled : undefined]}>
      <_StyledView2 $_css4={localStyles.itemContentContainer}>
        {itemIcon}
        <_StyledView3 $_css5={[styles.paddingHorizontalSingle, styles.flexSingle]}>
          <_StyledPrText children={label} numberOfLines={1} $_css6={localStyles.label} />
          {publisher && <_StyledPrText2 children={publisher} numberOfLines={1} $_css7={localStyles.publisher} />}
        </_StyledView3>
      </_StyledView2>
      <_StyledPrIcon fill={buttonColors.primary} icon={selected ? IconName.checkboxChecked : IconName.checkboxUnchecked} size={iconSizes.small} $_css8={styles.alignSelf} />
    </_StyledPrPressable>;
};
export default CpSessionReviewSelectItem;
var _StyledImage = _styled(Image)`${p => p.$_css}`;
var _StyledView = _styled2(View)`${p => p.$_css2}`;
var _StyledPrPressable = _styled3(PrPressable)`${p => p.$_css3}`;
var _StyledView2 = _styled4(View)`${p => p.$_css4}`;
var _StyledView3 = _styled5(View)`${p => p.$_css5}`;
var _StyledPrText = _styled6(PrText)`${p => p.$_css6}`;
var _StyledPrText2 = _styled7(PrText)`${p => p.$_css7}`;
var _StyledPrIcon = _styled8(PrIcon).withConfig({
  displayName: "CpSessionReviewSelectItem___StyledPrIcon",
  componentId: "sc-6l21rv-0"
})(["", ""], p => p.$_css8);